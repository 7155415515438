import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon, LatLngExpression } from 'leaflet'

function LocationMap({property}: any) {
  const position = [property.coordinateY, property.coordinateX] as LatLngExpression;

  return (
    <div className="lg:w-1/2 w-full z-0">
      <h2 className="text-3xl font-bold font-epilogue pb-8">Location</h2>
      <div className="lg:h-5/6 lg:w-full h-96 w-full">
        <MapContainer center={ position } zoom={13}>

          {/* OPEN STREEN MAPS TILES */}
          {/* <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          /> */}

          {/* WATERCOLOR CUSTOM TILES */}
          {/* <TileLayer
            attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
          /> */}

          {/* GOOGLE MAPS TILES */}
          <TileLayer
            attribution="Google Maps"
            url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" // regular
            // url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" // satellite
            // url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" // terrain
            maxZoom={20}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          <Marker 
            position={ position } 
            icon={ new Icon({iconUrl: 
              markerIconPng, 
              iconSize: [25, 41], 
              iconAnchor: [12, 41]})
            }
          >
            {/* <Popup>
              I am a pop-up!
            </Popup> */}
          </Marker>
        </MapContainer>
      </div>
    </div>
  )
}

export default LocationMap