

function TermsOfUse() {
  return (
    <div className="bg-id-new-purple1 shadow-md rounded px-40 pt-40 pb-8 mb-4">
      <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
      <p className="mb-4">
        Welcome to InvestDoor.com.au, operated by InvestDoor Pty Ltd.
      </p>
      <p className="mb-4">
        By accessing and using this website, you agree to be bound by these Terms of Use. If you do not agree with any part of these terms, please refrain from using the website.
      </p>
      <p className="mb-4">
        Acceptance of Terms <br/>
        1.1. These Terms of Use (the "Terms") govern your use of InvestDoor.com.au (the "Website"). 
        By using the Website, you agree to be bound by these Terms, including any additional terms and conditions 
        and policies referenced herein or available by hyperlink.
      </p>

      <p className="mb-4">
        1.2. InvestDoor Pty Ltd reserves the right to modify or update these Terms at any time without prior notice. 
        Continued use of the Website after any changes to the Terms constitutes your acceptance of the revised Terms.
      </p>

      <p className="mb-4">
        Use of the Website <br />
        2.1. Eligibility: The Website is intended for users who are at least 18 years old. By using the Website, 
        you represent and warrant that you are of legal age to form a binding contract and comply with these Terms.
      </p>

      <p className="mb-4">
        2.2. License: InvestDoor Pty Ltd grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Website solely for personal and non-commercial purposes. You may not use the Website for any illegal or unauthorized purpose.
      </p>

      <p className="mb-4">
        2.3. Account Registration: To access certain features or services on the Website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and agree to accept responsibility for all activities that occur under your account.
      </p>

      <p className="mb-4">
        User Obligations <br />
        3.1. Compliance: You agree to comply with all applicable laws, regulations, and these Terms while using the Website.
      </p>

      <p className="mb-4">
        3.2. Prohibited Activities: When using the Website, you must not:
      </p>

      <p className="mb-4">
        Copy code <br />
        (a) Use any automated device, software, process, or means to access, retrieve, scrape, or index the Website or any content on it. <br />
        (b) Interfere or attempt to interfere with the proper working of the Website. <br />
        (c) Undertake any action that will impose a burden or make excessive traffic demands on the Website's infrastructure. <br />
        (d) Use or index any content or data on the Website for purposes of constructing a searchable database of properties, building a database of property information, or competing with InvestDoor Pty Ltd in any unauthorised manner. <br />
        (e) Transmit spam, chain letters, contests, junk email, surveys, or other mass messaging, whether commercial in nature or not. <br />
        (f) Use the Website or its content in any manner that is not reasonable and/or not for the purpose for which it is made available. <br />
        (g) Violate the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right. <br />
        (h) Pose as any person or entity or attempt to solicit money, passwords, or personal information from any person. <br />
        (i) Act in violation of any Term of Use or other condition posed by InvestDoor Pty Ltd or any applicable law. <br />
        (j) Reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Website or its content without express written authorization from InvestDoor Pty Ltd. <br />
        (k) Transmit or attempt to transmit any computer viruses, worms, defects, Trojan horses, or other items of a destructive nature. <br />

      </p>

      <p className="mb-4">
        4. Intellectual Property <br /> <br />

        4.1. Ownership: The content, design, and materials on the Website are owned or licensed by InvestDoor Pty Ltd and are protected by intellectual property laws. <br /> <br />

        4.2. Limited Licence: InvestDoor Pty Ltd grants you a limited, non-exclusive, non-transferable, and revocable licence to access and use the content on the Website solely for personal and non-commercial purposes. <br /> <br />

        4.3. Trademarks: The "InvestDoor.com.au" name and logo, and any related names or symbols, are trademarks of InvestDoor Pty Ltd. You may not use these trademarks without InvestDoor Pty Ltd's prior written consent. <br /> <br />
      </p>

      <p className="mb-4">
        Disclaimer of Warranties <br />
        5.1. The Website and its content are provided "as is" without any warranty of any kind, either express or implied. InvestDoor Pty Ltd disclaims all warranties, including but not limited to the accuracy, completeness, reliability, or availability of the Website.
      </p>
      
      <p className="mb-4">
        Limitation of Liability <br />
        6.1. To the fullest extent permitted by law, InvestDoor Pty Ltd shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way related to your use or inability to use the Website.
      </p>

      <p className="mb-4">
        Indemnification <br />
        7.1. You agree to indemnify and hold InvestDoor Pty Ltd and its officers, directors, employees, and agents harmless from any claims, damages, losses, liabilities, costs, or expenses (including reasonable attorneys' fees) arising from your use of the Website or any violation of these Terms.
      </p>

      <p className="mb-4">
        Termination <br />
        8.1. InvestDoor Pty Ltd reserves the right to terminate your access to the Website at any time for any reason without notice.
      </p>

      <p className="mb-4"> 
        Governing Law <br />
        9.1. These Terms of Use shall be governed by and construed in accordance with the laws of Victoria, Australia, without regard to its conflict of law provisions.
      </p>

      <p className="mb-4">
        Contact Us <br />
        10.1. If you have any questions or concerns about these Terms of Use or the Website, please contact us at info@investdoor.com.au.
      </p>




    </div>

  )
}

export default TermsOfUse;