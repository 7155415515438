import investdoor_logoname_new_white from '../../static/investdoor_logoname_new_white.png';
import MediaLogoNew from '../components/Footer/MediaLogoNew';
import PolicyList from '../components/Footer/Policylist'
import CopyRight from '../components/Footer/CopyRight';
import Translation from '../components/Footer/Translation';
import Subscribe from '../components/Footer/Subscribe';
import { useState } from 'react';


function FooterNew() {

  
  return (
    <div className="bg-id-new-blue1 pt-10">
      <div className="md:container mx-auto w-full">
          {/* Footer row 1*/}
          {
          <div className="flex-col md:justify-items-center  content-center space-y-8"> 
            <img src={investdoor_logoname_new_white} alt="investdoor logo white" className="h-8 mx-auto" ></img>
            <h1 className="font-robotoslablight text-4xl text-white text-center">Providing security for <span className="font-robotoslabbold">your</span> future</h1>
          </div> 
          }
          {/* footer row 2*/}
 
          
        
      </div>
      <div className="flex justify-between mt-8 mb-2 mx-5">
            {
              <div><CopyRight/></div>
            } 
            {
              <div><PolicyList/></div>
            }
            {
              <div><MediaLogoNew/></div>
            }

          </div>
    </div>
  )
}

export default FooterNew;