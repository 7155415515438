import fam2 from '../../../static/fam2.jpg';
import IDLogo from '../../../static/NewAssets/InvestDoor Logo.png'
import SlidingToggleButton from './SlidingToggleButton';
import VisLangBullets from './VisLangBullets';

function CardThree({scrollPercentage, scrollToBooking}: any) {
  return (
    <div className={"w-full h-[104vh] overflow-hidden my-0 bg-cover opacity-1 rounded-t-3xl z-20 sticky -top-5"}>
      <div className="relative h-[104vh] flex flex-col sm:py-52 py-10 sm:px-20 px-5 bg-cover bg-no-repeat bg-top bg-id-new-purple3">
        <div className="absolute opacity-50">
          <img src={IDLogo} alt="tick" className={`${scrollPercentage > 0.45 ? "animate-fade-down animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"}`} />
        </div>
        <h3 className="relative sm:text-4xl text-xl font-robotoslabthin text-white">Smooth & efficient processes</h3>
        <h1 className="relative sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">High Quality Properties</h1>
        <p className="relative text-white text-2xl font-museosans md:w-1/3 mt-8">
          We believe that investing in real estate is one of the best ways to
          secure a successful future, and we strive to make that process as smooth 
          and efficient as possible. 
        </p>
        <p className="relative text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-20">
          Our mission is to provide access to a wide range of high-quality properties 
          and investment opportunities.
        </p>
        <SlidingToggleButton buttonText={'Book a meeting'} func={scrollToBooking}/>
        <VisLangBullets side="right" bottomOffset={"bottom-80"} width={"w-1/5"} animVisible={scrollPercentage > 0.46} />
        <VisLangBullets side="right" bottomOffset={"bottom-56"} width={"w-1/6"} animVisible={scrollPercentage > 0.48} />
      </div>
    </div>
  )
}

export default CardThree;