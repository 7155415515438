
import { useState } from "react";

import { API, graphqlOperation } from "aws-amplify";

import * as mutations from '../../graphql/mutations';

import PropertyTypeForm from '../components/PropertyTypeForm';

import { toast } from "react-toastify";
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { propertyTypeActions } from "../../redux/property_type";
import { RootState, AppDispatch } from '../../store';


function PropertyTypeEdit({propertyTypeToUpdate}: any) {
  const dispatch = useDispatch();

  const { ptypeId } = useParams();

  const propertyTypeToEdit = useSelector((state: RootState) => state.propertyType.propertyTypeList.find((p:any) => p.id === ptypeId));

  const [propertyType, setPropertyType] = useState<any>(propertyTypeToEdit);

  const onChangeFormField = (key: string, val: any) => {
    setPropertyType({
      ...propertyType,
      [key]: val
    });
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    updatePropertyType();
    
  }
  
  const updatePropertyType = async () => {
    try {
      if(propertyType.name === '') throw "NoNameError";
      dispatch(propertyTypeActions.updatePropertyTypeById(propertyType));
      await API.graphql(graphqlOperation(mutations.updatePropertyType, {input: (({id, name, abbr, description}): any => ({id, name, abbr, description}))(propertyType)}));
      
      toast.success("Updated Property Type");
      setPropertyType(propertyType);
    } catch (e) {
      console.log(e);
      toast.error("Error Updating Property Type. Please check fields.");
    }
  }
  

  return (
    <div className=" min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/property_types'} className="block italic font-light">
            {"<< back to list"}
          </Link>
          Edit Property Type
        </div>
        <PropertyTypeForm 
          propertyType={propertyType}
          onChangeFormField={onChangeFormField}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default PropertyTypeEdit;