import {useState, useEffect} from 'react'
import PropertyCarousel from '../components/PropertyDetails/PropertyCarousel'
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import Details from '../components/PropertyDetails/Details'
import PropertyContactForm from '../components/PropertyDetails/PropertyContactForm'
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from '../../store';
import CircularLoader from '../../common/components/CircularLoader';
import { toast } from "react-toastify";
import { propertyActions } from '../../redux/property';
import ConfirmModal from '../../adminpanel/components/ConfirmModal';
import { set, unionBy } from 'lodash-es';
import NotFound from '../../common/components/NotFound';
import { ThunkDispatch } from '@reduxjs/toolkit';


const TAKEN_SAME_USER = 1;
const TAKEN_DIFF_USER = 2;
const NOT_TAKEN = 3;

function PropertyDetails() {
  const { pId } = useParams();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { currentUser } = useSelector((state: RootState) => state.user);
  const property = useSelector((state: RootState) => 
    state.property.searchedPropertyList.find((p:any) => p.id === pId));

  const [formMessage, setFormMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchPropertyById = async () => {
    setLoading(true);
    await dispatch(propertyActions.fetchPropertyById({id: pId}));
    setLoading(false);
  } 

  useEffect(() => {
    if (!property) {
      fetchPropertyById();
    }
  }, [property]);

  

  if (loading) {
    return (
      <div className="flex justify-center">
        <CircularLoader />
      </div>
    )
  }

  if (!property) return (
    <NotFound />
  );

  if (!!currentUser && currentUser.accountTier === "NEW_ACCOUNT") {
    return (
      <div className="w-full text-center p-10">
        You are not authorized to view this page. You need to have a verified account. 
        Please <Link to="/contact" className="underline">contact us</Link> for more information.
      </div>
    );
  }

  const onChangeFormMessage = (val: string) => {
    setFormMessage(val);
  }
  
  const submitMessage = async (sysGen = false) => {
    setIsSending(true);
    try {
      let payload = {
        propertyMessagesId: pId,
        userMessagesId: currentUser!.id,
        propertyName: property.name,
        propertyAddress: property.address,
        userFirstName: currentUser!.firstName,
        userLastName: currentUser!.lastName,
        userEmail: currentUser!.email,
        userPhoneNumber: currentUser!.phoneNumber,
        message: formMessage,
        sysGen: sysGen
      };
      await API.graphql(graphqlOperation(mutations.createPropertyMessage, {input: payload}));
      toast.success('Inquiry for this property successfully sent.')
      setFormMessage('');
    } catch (e) {
      console.log(e);
      toast.error("Error sending your message. Please try again.")
    }
    setIsSending(false);
  }

  const eoiConfirm = () => {
    setModalVisible(true);
  }

  const toggleEoi = async() => {
    setModalVisible(false);
    try {
      let payload = {
        id: property.id,
        propertyInterestedUserId: eoiFlag() === NOT_TAKEN ? currentUser!.id : null
      }
      //@ts-ignore
      let {data} = await API.graphql(graphqlOperation(mutations.updateProperty, {input: payload}));
      console.log(data);
      dispatch(propertyActions.updateSearchedPropertyById(data.updateProperty));
      
      const toastMsg = eoiFlag() === NOT_TAKEN ? 
        "Successfully sent EOI" : "EOI withdrawn."
      const autoMsg = eoiFlag() === NOT_TAKEN ? 
        "[RESERVE] This user has expressed interest in this property, and is exclusively reserved to them." :
        "[WITHDRAW] This user has withdrawn their interest, and is open to other clients";
      let msgPayload = {
        propertyMessagesId: pId,
        userMessagesId: currentUser!.id,
        propertyName: property.name,
        propertyAddress: property.address,
        userFirstName: currentUser!.firstName,
        userLastName: currentUser!.lastName,
        userEmail: currentUser!.email,
        userPhoneNumber: currentUser!.phoneNumber,
        message: `[INVESTDOOR AUTOGEN]${autoMsg}`,
        sysGen: true
      };
      await API.graphql(graphqlOperation(mutations.createPropertyMessage, {input: msgPayload}));
      toast.success(toastMsg);
      //send message
    } catch (e) {
      console.log(e);
      toast.error("Error updating EOI, please try again later.")
    }
  }


  const eoiFlag = () => {  
    if (!!property.interestedUser) {
       //@ts-ignore
      if(property.interestedUser.id === currentUser!.id) {
        return TAKEN_SAME_USER;
      } else {
        return TAKEN_DIFF_USER;
      }
    } else {
      return NOT_TAKEN;
    }
  };

  const modalMessage = () => {
    if (eoiFlag() === TAKEN_SAME_USER) {
      return "Do you want to cancel your EOI?"
    } else {
      return "Are you sure you want to express interest for this property?"
    }
  }


  return (
    <>
      <PropertyCarousel images={property ? (property.primaryImageUrl ? [property.primaryImageUrl, ...property.imageUrl] : property.imageUrl) : []}/>
      <Details property={property} eoi={eoiConfirm} eoiFlag={eoiFlag()}/>
      <PropertyContactForm {...{formMessage, onChangeFormMessage, submitMessage, isSending, property}}/>
      <ConfirmModal 
        modalVisible={modalVisible}
        onOk={() => toggleEoi()}
        onCancel={()=>setModalVisible(false)}
        message={`${modalMessage()}`}
      />
    </>
  )
}

export default PropertyDetails