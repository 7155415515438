import NDIS_1 from '../../../static/NDIS_img/NDIS_1.png';
import NDIS_2 from '../../../static/NDIS_img/NDIS_2.png';
import NDIS_3 from '../../../static/NDIS_img/NDIS_3.png';
import NDIS_4 from '../../../static/NDIS_img/NDIS_4.png';



const NDIS_Services = () => {

    return  (
      <div className="bg-id-grey6 w-full">
        <div className="h-fit md:py-14 p-5 container mx-auto flex flex-col justify-center items-center md:pr-8 md:pl-8 gap-2 md:gap-10">
          {/* Service 1 */}
          <div className="relative  font-intern bg-white  flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
            <div className="hidden md:flex min-w-[20%]  justify-center items-center object-contain">
              <img src={NDIS_1} alt="SMSF_1" />
            </div>
            <div className=" flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                WHY INVEST IN NDIS AND SDA PROPERTIES
              </div>
              <div className="md:text-lg text-sm">
                Investing in NDIS and SDA properties offers a unique opportunity to make a difference in people's lives while securing your financial future. The NDIS aims to support Australians living with disabilities by providing access to quality accommodation. By becoming an SDA property investor, you contribute to this mission while benefiting from the stability and growth potential of the property market.            
              </div>
            </div>
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>        
          </div>
          {/* Service 2 */}
          <div className="relative  font-intern bg-white  flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
            <div className="min-w-[20%] hidden md:flex justify-center items-center object-contain">
              <img src={NDIS_2} alt="service1"/>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                OUR EXPERTISE
              </div>
              <div className="md:text-lg text-sm">
                Our team of experts is well-versed in the intricate requirements of these sectors. We stay up-to-date with NDIS regulations, property standards, and market trends to provide you with the most informed guidance.            
              </div>
            </div>
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>          
          </div>
          {/* Service 3 */}
          <div className="relative  font-intern bg-white  flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
            <div className="min-w-[20%] hidden md:flex justify-center items-center object-contain">
              <img src={NDIS_3} alt="service1"/>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                TAILORED INVESTMENT SOLUTIONS
              </div>
              <div className="md:text-lg text-sm">
                We recognize that each investor's needs and goals are unique. That's why we offer tailored investment solutions to suit your preferences. Whether you're interested in purchasing an existing SDA property or developing new SDA-compliant dwellings, we have the expertise and resources to guide you through the process.
              </div>
            </div>
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>           
          </div>
          {/* Service 4 */}
          <div className="relative  font-intern bg-white  flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
            <div className="hidden md:flex min-w-[20%] justify-center items-center object-contain">
              <img src={NDIS_4} alt="service1"/>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                SUPPORT AND COMPLIANCE
              </div>
              <div className="md:text-lg text-sm">
                Navigating the NDIS and SDA landscape can be complex. We're here to help you understand and navigate the regulatory framework, ensuring that your investments comply with all relevant standards. With our support, you can confidently invest in properties that meet the needs of NDIS participants.              
              </div>
            </div> 
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>        
          </div>

        </div>
      </div>    
    );
  }   

  export default NDIS_Services;