
import SMSF_5 from '../../../static/SMSF_img/SMSF_5.png';

const SMSF_statement = () => {
    return  (
      <div className="w-full    text-white  ">
        <div className="bg-id-blueShade flex flex-row justify-between mx-auto p-6 md:p-0">
          <div className="md:m-14 md:pl-24 flex flex-col justify-center items-center md:items-start md:w-3/4">
            <div className="text-lg font-bold mb-5">
            SECURE YOUR FINANCIAL FUTURE
            </div>
            <div className="text-md md:text-xl text-center md:text-left md:pr-24">
            Investing in SMSF properties is not just about building wealth; it's about securing your financial future with a tangible and appreciating asset. 
            <br/> <br/>  
            At InvestDoor, we empower you to take control of your SMSF and make informed property investment decisions and make the most of your investments while enjoying the benefits of property ownership.
            </div>
          </div>
          <div className='hidden md:flex justify-end m-0 p-0 items-center  w-1/4'>
            <img src={SMSF_5} alt="" className=' h-full object-cover object-center'/>
          </div>
        </div>
      </div>    
    );
  }   
  export default SMSF_statement;