import React from "react";

interface Props {
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  value?: string;
}
 
const CheckBox = (props: Props) => {
  return (
    <div className="flex flex-row gap-1 items-center">
        <input type="checkbox" value={props.value ?? ''} id={props.label} checked={props.isChecked} onChange={props.handleChange}/>
        <label className="text-lg" htmlFor={props.label}>{props.label}</label>
    </div>
  );
};

export default CheckBox;