import Cta from '../components/Cta';
import HeroText from '../components/HeroText';
import About_Us from '../../static/HeroText/About_Us.png';
import Statement from '../components/HomePage/Statement';

function About() {
  const AboutUs = () => {
    return (
      <>
        <div className="text-neutral-600">
          At Investdoor,we are committed to providing exceptional service to our partners.
          <br/><br/>
          We believe that investing in real estate is one of the best ways to secure a successful future, and we strive to make that process as smooth and efficient as possible.
          <br/><br/>
          Our mission is to provide access to a wide range of high-quality properties and investment opportunities, while offering personalized advice and support to ensure our clients make the best decisions for their unique needs. Let us help you turn your real estate dreams into a reality.          
        </div> 
      </>
    );
  }

  return (
    <>
        <HeroText text={'About Us'} description={AboutUs()} image={About_Us}/>
          
        <Cta />
    </>
  );
}

export default About;