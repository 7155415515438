// @ts-nocheck

import React, {useMemo} from 'react';

import {useTable, useFilters, useSortBy, usePagination} from 'react-table';
import { format } from 'date-fns';
import {FontAwesome} from 'react-web-vector-icons';
import { sortBy } from 'lodash-es';
import { idText } from 'typescript';

function Table(props: any) {
  let {data, columns} = props;

  const memoData = useMemo(() => data, [data]);
  const memoCols = useMemo(() => columns, [columns]);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: memoData,
      columns: memoCols,
      initialState: { 
        pageIndex: 0, 
        pageSize: 10,
        sortBy: [
          {
          id: 'createdAt',
          desc: true
          }
        ] 
      },
    },
    useFilters,
    useSortBy,
    usePagination
  )
 

  const filterCaseInsensitive = (filter: any, row: any) => {
    const id = filter.pivotId || filter.id;
    return (
      row[id] !== undefined ?
        String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      :
        true
    );
  }

  //@ts-ignore
  const customTextBox = ({filter, onChange}) => {
    return (
      <div>
        <FontAwesome name='search' size={15} /> &nbsp;
        <input
          type="text"
          className="w-10/12"
          onChange={(e) => onChange(e.target.value)}
          value={filter ? filter.value : ''}
        />
      </div>
    )
  }

  return (
    <div>
      <table {...getTableProps()} style={{ border: 'solid 1px blue' }} className="table-auto w-full">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th className="border-2 border-gray-800 bg-blue-200 text-bold"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      :  '' }
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      
                      className="p-4 border border-black bg-white text-left"
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="pagination my-5">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )





}

export default Table;


