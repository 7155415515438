

const SMSF_CTA = () => {

  return (
    <div className="w-full mx-auto text-center   ">
      <div className=" container mx-auto flex flex-col justify-center items-center pt-10 pb-8 px-6 gap-14 md:pt-20 md:pb-20 md:px-14">
        <div className="text-2xl md:text-5xl text-id-blue font-bold  ">
        GET STARTED TODAY
        </div>
        <div className="text-md md:text-2xl md:w-[60rem] text-neutral-900">
        Ready to explore the world of SMSF property investments in Australia? Contact InvestDoor today to discuss your SMSF goals and learn how we can help you make sound property investment decisions within your self-managed superannuation fund.
        </div>
      </div>
    </div> 
  );
}
export default SMSF_CTA;