
const SMSF_statement = () => {
    return  (
      <div className="w-full mx-auto text-center bg-id-blueShade opacity-1 text-white  ">
        <div className=" container mx-auto flex flex-col justify-center items-center pt-10 pb-14 px-6 gap-14 md:pt-20 md:pb-20 md:px-14">
          <div className="text-md opacity-70">
          SECURE YOUR FUTURE
          </div>
          <div className="text-md md:text-2xl opacity-80 md:w-[60rem]">
            We understand the importance of securing your financial future and SMSF property investments provide an excellent avenue for wealth creation and portfolio diversification.
            <br/><br/>
            With our expertise and commitment to helping you make the most of your SMSF, we're here to guide you through the world of property investments within your SMSF.
          </div>
        </div>
      </div>    
    );
  }   
  export default SMSF_statement;