import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql-custom/queries-custom';
import {reject, unionBy} from 'lodash-es';

const PAGE_LIMIT = 100;

//TODO put add/update functionality in reducers instead
const fetchPropertyMessageList = createAsyncThunk(
  'messages/properties',
  async(_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(graphqlOperation(customQueries.listPropertyMessagesCustom, {limit: PAGE_LIMIT}));
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
)

const fetchInquiryMessageList = createAsyncThunk(
  'messages/inquiries',
  async(_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(graphqlOperation(queries.listInquiryMessages, {limit: PAGE_LIMIT}));
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
)




export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    propertyMessageList: [] as any,
    inquiryMessageList: [] as any,
    propertyMessageNextToken: null,
    inquiryMessageNextToken: null
  }, reducers: {
    setPropertyMessageList: (state, action) => {
      state.propertyMessageList = action.payload;
    },
    setInquiryMessageList: (state, action) => {
      state.inquiryMessageList = action.payload;
    },
    updateInquiryMessageById: (state, action) => {
      let idx = state.inquiryMessageList.findIndex((m: any) => m.id === action.payload.id);
      state.inquiryMessageList[idx] = {...state.inquiryMessageList[idx], ...action.payload};
    },
    updatePropertyMessageById: (state, action) => {
      let idx = state.propertyMessageList.findIndex((m: any) => m.id === action.payload.id);
      state.propertyMessageList[idx] = {...state.propertyMessageList[idx], ...action.payload};
    }
    
  }, extraReducers: (builder) => {
    builder.addCase(fetchPropertyMessageList. fulfilled, (state, action) => {
      state.propertyMessageList = unionBy(state.propertyMessageList, action.payload.listPropertyMessages.items, 'id');
      state.propertyMessageNextToken = action.payload.listPropertyMessages.nextToken;
    });
    builder.addCase(fetchInquiryMessageList.fulfilled, (state, action) => {
      state.inquiryMessageList = unionBy(state.inquiryMessageList, action.payload.listInquiryMessages.items, 'id');
      state.inquiryMessageNextToken = action.payload.listInquiryMessages.nextToken;
    });
  }
});

export const messageActions = {
  fetchPropertyMessageList,
  fetchInquiryMessageList,
  ...messageSlice.actions
};

export default messageSlice.reducer;