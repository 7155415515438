
import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from "aws-amplify";

import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";

import PropertyTypeForm from '../components/PropertyTypeForm';
import {Link} from 'react-router-dom';


const initialPropertyType = {
  'name': '',
  'abbr': '',
  'description': ''
};

function PropertyTypeAdd() {

  const [newPropertyType, setNewPropertyType] = useState(initialPropertyType);


  const onChangeFormField = (key: string, val: any) => {
    setNewPropertyType({
      ...newPropertyType,
      [key]: val
    });
  }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPropertyType();
    
  }
  
  const createPropertyType = async () => {
    try {
      if(newPropertyType.name === '') throw "NoNameError";
      await API.graphql(graphqlOperation(mutations.createPropertyType, {input: newPropertyType}));
      toast.success("Added Property Type");
      setNewPropertyType(initialPropertyType);
    } catch (e) {
      console.log(e);
      toast.error("Error adding Property Type. Please check fields.");
    }
  }
  

  return (
    <div className=" min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="text-left font-bold">
        <Link to={'/admin/property_types'} className="block italic font-light">
          {"<< back to list"}
        </Link>
        Add Property Type
      </div>
      <PropertyTypeForm 
        propertyType={newPropertyType}
        onChangeFormField={onChangeFormField}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default PropertyTypeAdd;
