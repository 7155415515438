/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    cognitoId
    email
    phoneNumber
    address
    birthdate
    lastIp
    ausBusinessNumber
    accountTier
    messages {
      nextToken
      __typename
    }
    propertyInterests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    cognitoId
    email
    phoneNumber
    address
    birthdate
    lastIp
    ausBusinessNumber
    accountTier
    messages {
      nextToken
      __typename
    }
    propertyInterests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    cognitoId
    email
    phoneNumber
    address
    birthdate
    lastIp
    ausBusinessNumber
    accountTier
    messages {
      nextToken
      __typename
    }
    propertyInterests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createProperty = /* GraphQL */ `mutation CreateProperty(
  $input: CreatePropertyInput!
  $condition: ModelPropertyConditionInput
) {
  createProperty(input: $input, condition: $condition) {
    id
    pid
    name
    price
    address
    state
    primaryImageUrl
    imageUrl
    brochureUrl
    description
    addedBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    interestedUser {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    propertyStatus
    propertyType {
      id
      name
      abbr
      description
      createdAt
      updatedAt
      __typename
    }
    listAvailability
    bedrooms
    bathrooms
    carspaces
    rentalIncome
    minimumRentalYield
    contractType
    coordinateX
    coordinateY
    archived
    createdAt
    updatedAt
    userPropertyInterestsId
    propertyAddedById
    propertyInterestedUserId
    propertyPropertyTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePropertyMutationVariables,
  APITypes.CreatePropertyMutation
>;
export const updateProperty = /* GraphQL */ `mutation UpdateProperty(
  $input: UpdatePropertyInput!
  $condition: ModelPropertyConditionInput
) {
  updateProperty(input: $input, condition: $condition) {
    id
    pid
    name
    price
    address
    state
    primaryImageUrl
    imageUrl
    brochureUrl
    description
    addedBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    interestedUser {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    propertyStatus
    propertyType {
      id
      name
      abbr
      description
      createdAt
      updatedAt
      __typename
    }
    listAvailability
    bedrooms
    bathrooms
    carspaces
    rentalIncome
    minimumRentalYield
    contractType
    coordinateX
    coordinateY
    archived
    createdAt
    updatedAt
    userPropertyInterestsId
    propertyAddedById
    propertyInterestedUserId
    propertyPropertyTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePropertyMutationVariables,
  APITypes.UpdatePropertyMutation
>;
export const deleteProperty = /* GraphQL */ `mutation DeleteProperty(
  $input: DeletePropertyInput!
  $condition: ModelPropertyConditionInput
) {
  deleteProperty(input: $input, condition: $condition) {
    id
    pid
    name
    price
    address
    state
    primaryImageUrl
    imageUrl
    brochureUrl
    description
    addedBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    interestedUser {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    propertyStatus
    propertyType {
      id
      name
      abbr
      description
      createdAt
      updatedAt
      __typename
    }
    listAvailability
    bedrooms
    bathrooms
    carspaces
    rentalIncome
    minimumRentalYield
    contractType
    coordinateX
    coordinateY
    archived
    createdAt
    updatedAt
    userPropertyInterestsId
    propertyAddedById
    propertyInterestedUserId
    propertyPropertyTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePropertyMutationVariables,
  APITypes.DeletePropertyMutation
>;
export const createPropertyType = /* GraphQL */ `mutation CreatePropertyType(
  $input: CreatePropertyTypeInput!
  $condition: ModelPropertyTypeConditionInput
) {
  createPropertyType(input: $input, condition: $condition) {
    id
    name
    abbr
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePropertyTypeMutationVariables,
  APITypes.CreatePropertyTypeMutation
>;
export const updatePropertyType = /* GraphQL */ `mutation UpdatePropertyType(
  $input: UpdatePropertyTypeInput!
  $condition: ModelPropertyTypeConditionInput
) {
  updatePropertyType(input: $input, condition: $condition) {
    id
    name
    abbr
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePropertyTypeMutationVariables,
  APITypes.UpdatePropertyTypeMutation
>;
export const deletePropertyType = /* GraphQL */ `mutation DeletePropertyType(
  $input: DeletePropertyTypeInput!
  $condition: ModelPropertyTypeConditionInput
) {
  deletePropertyType(input: $input, condition: $condition) {
    id
    name
    abbr
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePropertyTypeMutationVariables,
  APITypes.DeletePropertyTypeMutation
>;
export const createPropertyMessage = /* GraphQL */ `mutation CreatePropertyMessage(
  $input: CreatePropertyMessageInput!
  $condition: ModelPropertyMessageConditionInput
) {
  createPropertyMessage(input: $input, condition: $condition) {
    id
    user {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    property {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      propertyStatus
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    propertyName
    propertyAddress
    userFirstName
    userLastName
    userEmail
    userPhoneNumber
    message
    readByUserIds
    sysGen
    archived
    createdAt
    updatedAt
    userMessagesId
    propertyMessagesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePropertyMessageMutationVariables,
  APITypes.CreatePropertyMessageMutation
>;
export const updatePropertyMessage = /* GraphQL */ `mutation UpdatePropertyMessage(
  $input: UpdatePropertyMessageInput!
  $condition: ModelPropertyMessageConditionInput
) {
  updatePropertyMessage(input: $input, condition: $condition) {
    id
    user {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    property {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      propertyStatus
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    propertyName
    propertyAddress
    userFirstName
    userLastName
    userEmail
    userPhoneNumber
    message
    readByUserIds
    sysGen
    archived
    createdAt
    updatedAt
    userMessagesId
    propertyMessagesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePropertyMessageMutationVariables,
  APITypes.UpdatePropertyMessageMutation
>;
export const deletePropertyMessage = /* GraphQL */ `mutation DeletePropertyMessage(
  $input: DeletePropertyMessageInput!
  $condition: ModelPropertyMessageConditionInput
) {
  deletePropertyMessage(input: $input, condition: $condition) {
    id
    user {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    property {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      propertyStatus
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    propertyName
    propertyAddress
    userFirstName
    userLastName
    userEmail
    userPhoneNumber
    message
    readByUserIds
    sysGen
    archived
    createdAt
    updatedAt
    userMessagesId
    propertyMessagesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePropertyMessageMutationVariables,
  APITypes.DeletePropertyMessageMutation
>;
export const createArticle = /* GraphQL */ `mutation CreateArticle(
  $input: CreateArticleInput!
  $condition: ModelArticleConditionInput
) {
  createArticle(input: $input, condition: $condition) {
    id
    urlSlug
    title
    content
    excerpt
    tags {
      nextToken
      __typename
    }
    createdBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    customAuthorName
    coverPhotoUrl
    listPhotoUrl
    displayDate
    archived
    createdAt
    updatedAt
    articleCreatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArticleMutationVariables,
  APITypes.CreateArticleMutation
>;
export const updateArticle = /* GraphQL */ `mutation UpdateArticle(
  $input: UpdateArticleInput!
  $condition: ModelArticleConditionInput
) {
  updateArticle(input: $input, condition: $condition) {
    id
    urlSlug
    title
    content
    excerpt
    tags {
      nextToken
      __typename
    }
    createdBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    customAuthorName
    coverPhotoUrl
    listPhotoUrl
    displayDate
    archived
    createdAt
    updatedAt
    articleCreatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArticleMutationVariables,
  APITypes.UpdateArticleMutation
>;
export const deleteArticle = /* GraphQL */ `mutation DeleteArticle(
  $input: DeleteArticleInput!
  $condition: ModelArticleConditionInput
) {
  deleteArticle(input: $input, condition: $condition) {
    id
    urlSlug
    title
    content
    excerpt
    tags {
      nextToken
      __typename
    }
    createdBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    customAuthorName
    coverPhotoUrl
    listPhotoUrl
    displayDate
    archived
    createdAt
    updatedAt
    articleCreatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArticleMutationVariables,
  APITypes.DeleteArticleMutation
>;
export const createArticleTag = /* GraphQL */ `mutation CreateArticleTag(
  $input: CreateArticleTagInput!
  $condition: ModelArticleTagConditionInput
) {
  createArticleTag(input: $input, condition: $condition) {
    id
    urlSlug
    name
    articles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArticleTagMutationVariables,
  APITypes.CreateArticleTagMutation
>;
export const updateArticleTag = /* GraphQL */ `mutation UpdateArticleTag(
  $input: UpdateArticleTagInput!
  $condition: ModelArticleTagConditionInput
) {
  updateArticleTag(input: $input, condition: $condition) {
    id
    urlSlug
    name
    articles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArticleTagMutationVariables,
  APITypes.UpdateArticleTagMutation
>;
export const deleteArticleTag = /* GraphQL */ `mutation DeleteArticleTag(
  $input: DeleteArticleTagInput!
  $condition: ModelArticleTagConditionInput
) {
  deleteArticleTag(input: $input, condition: $condition) {
    id
    urlSlug
    name
    articles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArticleTagMutationVariables,
  APITypes.DeleteArticleTagMutation
>;
export const createFaq = /* GraphQL */ `mutation CreateFaq(
  $input: CreateFaqInput!
  $condition: ModelFaqConditionInput
) {
  createFaq(input: $input, condition: $condition) {
    id
    title
    content
    excerpt
    externalUrl
    weight
    page
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFaqMutationVariables,
  APITypes.CreateFaqMutation
>;
export const updateFaq = /* GraphQL */ `mutation UpdateFaq(
  $input: UpdateFaqInput!
  $condition: ModelFaqConditionInput
) {
  updateFaq(input: $input, condition: $condition) {
    id
    title
    content
    excerpt
    externalUrl
    weight
    page
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFaqMutationVariables,
  APITypes.UpdateFaqMutation
>;
export const deleteFaq = /* GraphQL */ `mutation DeleteFaq(
  $input: DeleteFaqInput!
  $condition: ModelFaqConditionInput
) {
  deleteFaq(input: $input, condition: $condition) {
    id
    title
    content
    excerpt
    externalUrl
    weight
    page
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFaqMutationVariables,
  APITypes.DeleteFaqMutation
>;
export const createArticleArticleTags = /* GraphQL */ `mutation CreateArticleArticleTags(
  $input: CreateArticleArticleTagsInput!
  $condition: ModelArticleArticleTagsConditionInput
) {
  createArticleArticleTags(input: $input, condition: $condition) {
    id
    articleId
    articleTagId
    article {
      id
      urlSlug
      title
      content
      excerpt
      customAuthorName
      coverPhotoUrl
      listPhotoUrl
      displayDate
      archived
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    articleTag {
      id
      urlSlug
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateArticleArticleTagsMutationVariables,
  APITypes.CreateArticleArticleTagsMutation
>;
export const updateArticleArticleTags = /* GraphQL */ `mutation UpdateArticleArticleTags(
  $input: UpdateArticleArticleTagsInput!
  $condition: ModelArticleArticleTagsConditionInput
) {
  updateArticleArticleTags(input: $input, condition: $condition) {
    id
    articleId
    articleTagId
    article {
      id
      urlSlug
      title
      content
      excerpt
      customAuthorName
      coverPhotoUrl
      listPhotoUrl
      displayDate
      archived
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    articleTag {
      id
      urlSlug
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateArticleArticleTagsMutationVariables,
  APITypes.UpdateArticleArticleTagsMutation
>;
export const deleteArticleArticleTags = /* GraphQL */ `mutation DeleteArticleArticleTags(
  $input: DeleteArticleArticleTagsInput!
  $condition: ModelArticleArticleTagsConditionInput
) {
  deleteArticleArticleTags(input: $input, condition: $condition) {
    id
    articleId
    articleTagId
    article {
      id
      urlSlug
      title
      content
      excerpt
      customAuthorName
      coverPhotoUrl
      listPhotoUrl
      displayDate
      archived
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    articleTag {
      id
      urlSlug
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArticleArticleTagsMutationVariables,
  APITypes.DeleteArticleArticleTagsMutation
>;
export const createInquiryMessage = /* GraphQL */ `mutation CreateInquiryMessage(
  $input: CreateInquiryMessageInput!
  $condition: ModelInquiryMessageConditionInput
) {
  createInquiryMessage(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    phoneNumber
    message
    readByUserIds
    archived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInquiryMessageMutationVariables,
  APITypes.CreateInquiryMessageMutation
>;
export const updateInquiryMessage = /* GraphQL */ `mutation UpdateInquiryMessage(
  $input: UpdateInquiryMessageInput!
  $condition: ModelInquiryMessageConditionInput
) {
  updateInquiryMessage(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    phoneNumber
    message
    readByUserIds
    archived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInquiryMessageMutationVariables,
  APITypes.UpdateInquiryMessageMutation
>;
export const deleteInquiryMessage = /* GraphQL */ `mutation DeleteInquiryMessage(
  $input: DeleteInquiryMessageInput!
  $condition: ModelInquiryMessageConditionInput
) {
  deleteInquiryMessage(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    phoneNumber
    message
    readByUserIds
    archived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInquiryMessageMutationVariables,
  APITypes.DeleteInquiryMessageMutation
>;
