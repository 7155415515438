import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="bg-id-new-purple1 shadow-md rounded px-40 pt-40 pb-8 mb-4">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        <span className="font-bold">InvestDoor Pty Ltd</span> is committed to providing quality services to you and this 
        policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.
        We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). 
        The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.
        A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information
        Commissioner at <a href="https://www.oaic.gov.au" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">https://www.oaic.gov.au/</a>.

      </p>
      <h2 className="text-xl font-bold mb-4">What is Personal Information and why do we collect it?</h2>

      <p className="mb-4">
        Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we 
        collect includes names, addresses, email addresses, phone and facsimile numbers. 
        This Personal Information is obtained in many ways including 
          <span className="font-bold"> correspondence, by telephone and facsimile, by email, 
            via our website www.investdoor.com.au, media and publications, from other publicly available
            sources, from cookies </span>
        and from third parties. We don’t guarantee website links or policy of authorised third parties.
        We collect your Personal Information for the primary purpose of providing our services to you, providing information to 
        our clients and marketing. We may also use your Personal Information for secondary purposes closely related to
        the primary purpose, in circumstances where you would reasonably expect such use or disclosure. 
        You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing.
        When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting
        the information and how we plan to use it.
      </p>

      <h2 className="text-xl font-bold mb-4">Sensitive Information</h2>

      <p className="mb-4">
        Sensitive information is defined in the Privacy Act to include information or opinion about such things as an 
        individual's racial or ethnic origin, political opinions, membership of a political association, religious or 
        philosophical beliefs, membership of a trade union or other professional body, criminal record or health information. 
        <br/>
        Sensitive information will be used by us only:
        <ul>
          <li>For the primary purpose for which it was obtained</li>
          <li>For a secondary purpose that is directly related to the primary purpose</li>
          <li>With your consent; or where required or authorised by law.</li>
        </ul>

      </p>

      <h2 className="text-xl font-bold mb-4">Third Parties</h2>

      <p className="mb-4">
        Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, 
        in some circumstances we may be provided with information by third parties. In such a case we will take 
        reasonable steps to ensure that you are made aware of the information provided to us by the third party.
      </p>

      <h2 className="text-xl font-bold mb-4">Disclosure of Personal Information</h2>

      <p className="mb-4">
        Your Personal Information may be disclosed in a number of circumstances including the following:
        <ul>
          <li>Third parties where you consent to the use or disclosure; and</li>
          <li>Where required or authorised by law.</li>
        </ul>
      </p>

      <h2 className="text-xl font-bold mb-4">Security of Personal Information</h2>

      <p className="mb-4">
        Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from 
        unauthorized access, modification or disclosure.

        When your Personal Information is no longer needed for the purpose for which it was obtained, we will take 
        reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal
        Information is or will be stored in client files which will be kept by us for a minimum of 7 years.
      </p>

      <h2 className="text-xl font-bold mb-4">Access to your Personal Information</h2>
      
      <p className="mb-4">
        You may access the Personal Information we hold about you and to update and/or correct it, 
        subject to certain exceptions. If you wish to access your Personal Information, please contact us in writing.
        
        <span className="font-bold">InvestDoor Pty Ltd </span> will not charge any fee for your access request, 
        but may charge an administrative fee for providing a copy of your Personal Information.
        In order to protect your Personal Information we may require identification from you before releasing 
        the requested information.

      </p>

      <h2 className="text-xl font-bold mb-4">Maintaining the Quality of your Personal Information</h2>

      <p className="mb-4">
        It is important to us that your Personal Information is up to date. We will take reasonable steps to ensure your 
        Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date 
        or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue 
        to provide quality services to you.
      </p>

      <h2 className="text-xl font-bold mb-4">Policy Updates</h2>
      
      <p className="mb-4">
        This Policy may change from time to time and is available on our website.
      </p>
      
      <h2 className="text-xl font-bold mb-4">Privacy Policy Complaints and Enquiries</h2>

      <p className="mb-4">
        If you have any queries or complaints about our Privacy Policy please contact us at:
        <span className="font-bold">
          info@investdoor.com.au
        </span>
      </p>

    </div>
  );
}

export default PrivacyPolicy;