import {useState, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import { toast } from "react-toastify";
import CircularLoader from '../../common/components/CircularLoader';
import TagAdd from './TagAdd';
import {useDispatch, useSelector} from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../../store';
import { articleActions } from '../../redux/article';
import {Link} from 'react-router-dom';
import TagEdit from './TagEdit';
import { set } from 'lodash-es';

function Tags() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [tagIdForDeletion, setTagIdForDeletion] = useState('');
  const [loading, setLoading] = useState(false);
  const [tagConnectionsLoading, setTagConnectionsLoading] = useState(false);

  const tagList = useSelector((state: RootState) => 
    state.article.tagList
  );

  const tagConnectionsForDeletion = useSelector((state: RootState) =>
    state.article.tagConnectionsForDeletion
  );

  useEffect(() => {
    getTagList();
  }, []);

  const deleteTag = async(tagId: any) => {
    try {
      setLoading(true);
      setModalVisible(false);
      //batch delete
      const tagConnectionMutation: any = tagConnectionsForDeletion.map((tc: any, i: number) => {
        return `mutation${i}: deleteArticleArticleTags(input: {id: "${tc.id}"}) {id}`;
      });

      if(tagConnectionMutation.length > 0) {
        await API.graphql(graphqlOperation(`
          mutation batchMutation {
            ${tagConnectionMutation}
          }
        `));
      }

      //then delete tag
      await API.graphql(graphqlOperation(mutations.deleteArticleTag, {input: {id: tagId}}));
      setTagIdForDeletion('');
      await getTagList();
      toast.success('Tag deleted successfully.');
    } catch (e) {
      toast.error('Error deleting tag.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const getTagConnectionsForDeletion = async (tagId: any) => {
    setTagConnectionsLoading(true);
    dispatch(articleActions.clearTagConnectionsForDeletion());
    await dispatch(articleActions.fetchTagConnectionsForDeletion({articleTagId: tagId}));
    setTagConnectionsLoading(false);
  }

  const getTagList = async () => {
    setLoading(true);
    try {
      await dispatch(articleActions.fetchArticleTagList());
    } catch (e) {
      toast.error('Error fetching articles. Please try again.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const onClickAdd = () => {
    navigate('/admin/tags/add');
  }

  const confirmDeleteTag = async(tagId: any) => {
    setModalVisible(true);
    setTagIdForDeletion(tagId);
    getTagConnectionsForDeletion(tagId);
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      defaultCanSort: true,
      Cell: (item : any) => {
        return <Link to={`/admin/tags/${item.row.original.id}`}>{item.value.trim().length === 0 ? '(no name)' : item.value}</Link>
      }
    },
    {
      Header: 'Slug',
      accessor: 'urlSlug',
      defaultCanSort: true
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button onClick={() => confirmDeleteTag(item.value)}>delete</button>
      }
    }
  ]

  const TagList = (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">
            Tags
          </h1>

          <p className="self-start py-4">
            Check out Article Tags
          </p>
          <div className="row-auto self-start">
            {/* <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={propertySearchByNameAndState}>search</button>
            <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={clearProperties}>clear</button> */}
          </div>
          <div className="row-auto self-start">
          {/* article tag checkbox */}

          </div>
          <div className="row-auto self-start">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getTagList}>load list</button>
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={onClickAdd}>add tag</button>

          </div>

          <div className=" py-2">
            {!loading ? (
              <Table columns={columns} data={tagList} />
            ) : (
              <CircularLoader />
            )}
          </div>

        </div>
      </div>

      <ConfirmModal modalVisible={modalVisible} 
        onOk={() => deleteTag(tagIdForDeletion)} 
        onCancel={() => setModalVisible(false)}
        message={"Are you sure you want to delete this row? This is irreversible."}
        disabled={tagConnectionsLoading}
      />
    </div>
  );

  return (
    <div>
      <Routes>
        <Route path="" element={TagList} />
        <Route path="add" element={<TagAdd/>} />
        <Route path=":tId" element={<TagEdit/>} />
      </Routes>
    </div>
  );
}

export default Tags;