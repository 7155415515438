import {useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import {set, unionBy} from 'lodash-es';
import {propertyActions} from '../../redux/property';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../../store';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import PropertyAdd from './PropertyAdd';
import PropertyEdit from './PropertyEdit';
import { toast } from "react-toastify";
import CheckBox from '../../common/components/CheckBox';
import CircularLoader from '../../common/components/CircularLoader';
import { State } from '../../API';
import { STATES } from '../../constants/fields';

const PAGE_LIMIT = 10;


function Properties() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [propertyIdForDeletion, setPropertyIdForDeletion] = useState('');
  const [propertyIdForArchive, setPropertyIdForArchive] = useState('');
  const [propertyArchiveFlag, setPropertyArchiveFlag] = useState(false);
  const [nameSearchString, setNameSearchString] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statesSelection, setStatesSelection] = useState(["WA", "QLD", "SA", "NSW", "TAS", "VIC", "NT", "ACT"]);

  const {
    propertyList,
    adminSearchedPropertyList,
  } = useSelector((state: RootState) => {
    const {propertyList, adminSearchedPropertyList} = state.property;
    return {
      propertyList,
      adminSearchedPropertyList
    };
  });

  const deleteProperty = async(propertyId: any) => {
    try {
      await API.graphql(graphqlOperation(mutations.deleteProperty, {input: {id: propertyId}}));
      setDeleteModalVisible(false);
      setPropertyIdForDeletion('');
      toast.success('Property deleted successfully');
      //TODO instead of getting property list, just delete from local store
      //getPropertyList();
    } catch (e) {
      toast.error('Error deleting property');
      console.log(e);
    }
  }

  const archiveProperty = async(propertyId: any) => {
    try {
      setLoading(true);
      setArchiveModalVisible(false);
      await API.graphql(graphqlOperation(mutations.updateProperty, 
        {input: {id: propertyId, archived: propertyArchiveFlag}}));
      dispatch(propertyActions.updatePropertyById({id: propertyId, archived: propertyArchiveFlag}));
      setPropertyIdForArchive('');
      setPropertyArchiveFlag(false);
      toast.success('Property archived successfully');
    } catch (e) {
      toast.error('Error archiving property');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const confirmDeleteProperty = async (propertyId: any) => {
    setDeleteModalVisible(true);
    setPropertyIdForDeletion(propertyId);
  }

  const confirmArchiveProperty = async (propertyId: any, archiveFlag: boolean) => {
    setArchiveModalVisible(true);
    setPropertyIdForArchive(propertyId);
    setPropertyArchiveFlag(archiveFlag);
  }

  const onClickAdd = () => {
    navigate('/admin/properties/add');
  }

  //WIP TEMPORARY, later use search function instead of listing outright
  const getPropertyList = async () => {
    setLoading(true);
    setIsSearch(false);
    dispatch(propertyActions.setProperties([]));
    await dispatch(propertyActions.fetchPropertyList());
    setLoading(false);
  }

  const propertySearchByNameAndState = async () => {
    setLoading(true);
    setIsSearch(true);

    await dispatch(propertyActions.adminSearchProperties({states: statesSelection, name: nameSearchString}));
    setLoading(false);
  }
  
  const handleStateCheckboxChange = (state: string) => {
    if(statesSelection.includes(state)) {
      setStatesSelection(statesSelection.filter((s) => s !== state));
    } else {
      setStatesSelection([...statesSelection, state])
    }
  }

  const clearProperties = () => {
    //clear both searchable and flatlist
    dispatch(propertyActions.setProperties([]));
    dispatch(propertyActions.setSearchedProperties([]));
  }

  const columns = [
    {
      Header: 'Property ID',
      accessor: 'pid',
      defaultCanSort: false,
      Cell: (item : any) => {
        return <Link to={`/admin/properties/${item.row.original.id}`}>{item.value.trim().length === 0 ? '(no id)' : item.value}</Link>
      }
    },
    {
      Header: 'Name',
      accessor: 'name',
      defaultCanSort: true
    },
    {
      Header: 'State',
      accessor: 'state',
      defaultCanSort: true
    },
    {
      Header: 'Status',
      accessor: 'propertyStatus',
      defaultCanSort: true
    },
    {
      Header: 'Availability',
      accessor: 'listAvailability',
      defaultCanSort: true,
    },
    {
      Header: 'Archived',
      accessor: 'archived',
      Cell: (item: any) => {
        return (
          <div>
            {item.value ? 'Yes' : 'No'} 
            <button className="ml-2 p-2 bg-slate-200 rounded-md" onClick={() => confirmArchiveProperty(item.row.original.id, !!!item.value)}>
              {item.value ? 'unarchive' : 'archive'}
            </button>
          </div>
        )
      }
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button onClick={() => confirmDeleteProperty(item.value)} >delete</button>
      }
    }
  ]




  //WIP MAJOR WIP: make property list searchable

  const PropertyList = (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col ">
          <h1 className="text-2xl font-bold">
            Properties
          </h1>

          <p className="">
            You can add, delete, filter, and search properties here. 
          </p>
          <div className="row-auto">
            <input type="text" className="rounded-l-lg border-none" value={nameSearchString} onChange={(e) => setNameSearchString(e.target.value)}/>
            <button className="shadow font-bold py-2 px-4 rounded-r-sm duration-300 hover:rounded-r-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" onClick={propertySearchByNameAndState}>search</button>
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" onClick={clearProperties}>clear</button>
          </div>
          <div className="row-auto self-start">
          { 
            STATES.map((state: any, i: number) => {
              return (
                <CheckBox 
                  label={state.label} 
                  handleChange={() => handleStateCheckboxChange(state.value)} 
                  isChecked={statesSelection.includes(state.value)} 
                  key={i}
                />
              )
            }) 
          }

          </div>
          <div className="row-auto self-start">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getPropertyList}>load list</button>
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={onClickAdd}>add property</button>

          </div>

          <div className=" overflow-auto">
            {!loading ? (
              <Table columns={columns} data={isSearch ? adminSearchedPropertyList : propertyList} />
            ) : (
              <CircularLoader />
            )}
          </div>

        </div>
      </div>

      <ConfirmModal modalVisible={deleteModalVisible} 
        onOk={() => deleteProperty(propertyIdForDeletion)} 
        onCancel={() => setDeleteModalVisible(false)}
        message={"Are you sure you want to delete this row? This is irreversible."}
      />
      <ConfirmModal modalVisible={archiveModalVisible} 
        onOk={() => archiveProperty(propertyIdForArchive)} 
        onCancel={() => setArchiveModalVisible(false)}
        message={`Are you sure you want to ${propertyArchiveFlag ? 'archive' : 'unarchive'} this property?`}
      />
    </div>
  );

  console.log(propertyList);
  return (
    <div>
      <Routes>
        <Route path="" element={PropertyList} />
        <Route path="add" element={<PropertyAdd />} />
        <Route path=":pId" element={<PropertyEdit/>} />
      </Routes>
    </div>
  );
}

export default Properties;