
import { useState, useEffect } from "react";

import { API, graphqlOperation, Storage } from "aws-amplify";

import * as mutations from '../../graphql/mutations';

import { toast } from "react-toastify";
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { faqActions } from "../../redux/faq";
import { RootState, AppDispatch } from '../../store';
import { unionBy, update } from "lodash-es";
import CircularLoader from "../../common/components/CircularLoader";
import { ThunkDispatch } from '@reduxjs/toolkit';
import { createFileLabel } from '../../helpers/parsers';
import FaqForm from "../components/FaqForm";


//TODO Factor this out later
interface PayloadFaq {
  title: string;
  content: string;
  excerpt: string;
  externalUrl: string;
  weight: number;
  page: string;
  [x: string | number | symbol]: unknown;
}

function FaqEdit() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { fId } = useParams();

  const faqToEdit = useSelector((state: RootState) =>
    state.faq.faqs.find((f:any) => f.id === fId)
  );

  const [faq, setFaq] = useState<any>({
    ...faqToEdit,
    weight: faqToEdit.weight.toString()
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFormField = (key: string, val: any) => {
    setFaq({
      ...faq,
      [key]: val
    });
  }

  const validateFaq = (payload: PayloadFaq) => {
    let flag = true;
    Object.keys(payload).forEach((k, i) => {
      if(flag = true) {
        if(k === 'excerpt' || k === 'externalUrl') return;
        if ((payload[k] instanceof String || typeof payload[k] === 'string') && !payload[k]) {
          flag = false;
        } else if (Number.isNaN(payload[k])) {
          flag = false;
        } else {
          flag = true;
        }
      }
    });
    return flag;
  }

  const updateFaq = async () => {
    try {
      setLoading(true);
      let payload: PayloadFaq = {
        id: faq.id,
        title: faq.title,
        content: faq.content,
        excerpt: faq.excerpt,
        externalUrl: faq.externalUrl,
        weight: parseInt(faq.weight.toString()),
        page: faq.page,   
      };

      if(!validateFaq(payload)) throw new Error('Invalid faq');

      await API.graphql(graphqlOperation(mutations.updateFaq, {input: payload}));
      toast.success('Faq updated successfully.');
      dispatch(faqActions.updateFaqById({...faq, ...payload}));
    } catch (e) {
      toast.error('Error updating faq.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
       <div className="flex flex-col">
       <div className="text-left font-bold">
        <Link to={'/admin/faqs'} className="block italic font-light">
          {"<< back to list"}
        </Link>
      </div>
      {loading ? 
        <CircularLoader/> : 
        <FaqForm 
          faq={faq}
          onChangeFormField={onChangeFormField}
        />
      }
      <button onClick={updateFaq} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4" disabled={loading}>
        SAVE FAQ
      </button>
      </div>

    </div>
  )
}

export default FaqEdit;