import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const course1Url = "https://investdoor.thinkific.com/courses/Investdoor";

function Courses() {
  const currentUser = useSelector((state: RootState) => state.user.currentUser);    

  const NotAuthorized = (
    <div className="w-full text-center p-10">
      To access our courses, you need to be logged in with a verified account. 
      Please <Link to="/contact" className="underline">contact us</Link> for more information.
    </div>
  );

  const CourseBody = (
    <div className="container mx-auto my-20">
      <h1 className="text-2xl font-bold pb-10">Courses</h1>
      <ul>
        <li className="text-xl font-bold pb-5">
          <Link to={course1Url} target="_blank" className="underline">InvestDoor Real Estate Investing Course</Link>
        </li>
      </ul>
      
    </div>
  );
  
  return !!currentUser && currentUser.accountTier !== "NEW_ACCOUNT" ? CourseBody : NotAuthorized;
};

export default Courses;
