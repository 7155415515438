
import React, { useState, useEffect } from 'react';
import { Button, Label, TextInput, Textarea } from "flowbite-react"


function InquiryContactForm({contactForm, setFormVals, isSending, submitMessage, errmsg, captchadone,captcha}: any) {
  //TODO apply captcha


  return (
    <section className="bg-[#25366EFF] relative">
      
      <div className="flex flex-col lg:flex-row gap-8 py-8 px-6 mx-auto max-w-screen-xl">
        <div className="w-full bg-white p-10 rounded-2xl">
          <h2 className="text-3xl text-[#235E8BFF] font-bold font-epilogue mb-8">Have a question?</h2>
          <form onSubmit={(e) => submitMessage(e)} className="grid md:grid-cols-2 grid-cols-1 grid-flow-row gap-6">
            <div className="md:col-span-1 col-span-2">
              <Label htmlFor="firstName" value="First Name *" className="mb-2 block" />
              <TextInput 
                id="firstName" 
                type="text" 
                required 
                value={contactForm.firstName} 
                onChange={(e) => setFormVals('firstName', e.target.value)} 
              />
            </div>
            <div className="md:col-span-1 col-span-2">
              <Label htmlFor="lastName" value="Last Name *" className="mb-2 block" />
              <TextInput 
                id="lastName" 
                type="text" 
                required 
                value={contactForm.lastName}
                onChange={(e) => setFormVals('lastName', e.target.value)} 
              />
            </div>
            <div className="col-span-2">
              <Label htmlFor="email" value="Email *" className="mb-2 block" />
              <TextInput 
                id="email" 
                type="email" 
                required 
                value={contactForm.email}
                onChange={(e) => setFormVals('email', e.target.value)}  
              />
            </div>
            <div className="col-span-2">
              <Label htmlFor="phoneNumber" value="Phone Number *" className="mb-2 block" />
              <TextInput 
                id="phoneNumber" 
                type="text" 
                inputMode="tel" 
                required 
                // pattern="[0-9]{4,6}" // need help translating regex from validPhoneRegex
                value={contactForm.phoneNumber}
                onChange={(e) => setFormVals('phoneNumber', e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <Label htmlFor="message" value="How can we help? *" className="mb-2 block" />
              <Textarea 
                id="message" 
                rows={4}
                required 
                value={contactForm.message}
                onChange={(e) => setFormVals('message', e.target.value)} 
              />
            </div>
            <div className="my-4 block col-span-2">
            <div className=" md:mb-7 mb-7 flex justify-center md:justify-start">
              {contactForm.message.length > 0 ? captcha('normal') : ''}
            </div>
              <Button 
                type="submit" 
                className="text-white bg-id-blue3 hover:bg-id-blue hover:transition-opacity focus:ring-4 
                focus:outline-none focus:ring-id-blue font-medium rounded-md px-20 py-1 mb-2 disabled:opacity-25 md:w-fit w-full"  
                disabled={!captchadone}
                // onClick={(e: any) => submitMessage(e)} // transfer to <form onSubmit=...>
              >
                { isSending ? "Loading..." : "Submit" }
              </Button>
              <p className="text-sm italic pt-2">* required fields</p>
              <p className="text-red-500 italic mt-2">{errmsg}</p>
            </div>


          </form>
        </div>
      </div>
    </section>
  )
}

export default InquiryContactForm