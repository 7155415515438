import fam3 from '../../../static/fam3.jpg';
import SlidingToggleButton from './SlidingToggleButton';
import React, { useState, useEffect } from 'react';
import HouesRGB from "../../../static/NewAssets/Houes RGB.png" 
import { Button, Label, TextInput, Textarea } from "flowbite-react"
import { Email } from '@mui/icons-material';

function ContactFormHome({scrollPercentage, contactForm, setFormVals, isSending, submitMessage, errmsg}: any) {
  return (
    <>
      <div className="z-50 relative bg-id-new-purple1 pb-20" id="contact-us">
        <div className="container justify-center items-center flex flex-col py-8 md:py-28 space-y-5 mx-auto bg-[length:250px] md:bg-[length:650px] bg-no-repeat bg-right-bottom " style={{backgroundImage: `url("${HouesRGB}")`}}>
          <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white text-center">Contact us</h3>
          <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white text-center">Have a question?</h1>
          <p className="text-gray-100 sm:text-xl text-md font-museosanssemilight w-[45%] mt-4 text-center">
            We'd love to hear from you! Whether you have a question, feedback, or just want to say hello,
            feel free to reach out. Our team is here to assist you.
          </p>  
          <form onSubmit={() => {}} className="grid md:grid-cols-2 grid-cols-1 grid-flow-row gap-4 w-[45%] mt-10">
              <div className="md:col-span-1 col-span-2">
                <Label htmlFor="firstName" value="First Name *" className="mb-2 hidden " />
                <TextInput className="rounded-[20px] overflow-hidden h-8 items-center shadow-md placeholder-red-700"
                  id="firstName" 
                  type="text" 
                  required 
                  value={contactForm.firstName}
                  placeholder="First Name*"
                  onChange={(e) => setFormVals('firstName', e.target.value)} 
                />
              </div>
              <div className="md:col-span-1 col-span-2">
                <Label 
                  htmlFor="lastName" 
                  value="Last Name *" 
                  className="mb-2 hidden" />
                <TextInput 
                  className="rounded-[20px] overflow-hidden h-8 items-center"
                  id="lastName" 
                  type="text" 
                  required 
                  value={contactForm.lastName}
                  placeholder="Last Name*"
                  onChange={(e) => setFormVals('lastName', e.target.value)} 
                />
              </div>
              <div className="col-span-2">
                <Label htmlFor="email" value="Email *" className="mb-2 hidden" />
                <TextInput className="rounded-[20px] overflow-hidden h-8 items-center placeholder-red-900"
                  id="email" 
                  type="email" 
                  required 
                  value={contactForm.email}
                  placeholder='Email*'
                  onChange={(e) => setFormVals('email', e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <Label htmlFor="phoneNumber" value="Phone Number *" className="mb-2 hidden" />
                <TextInput className="rounded-[20px] overflow-hidden h-8 items-center"
                  id="phoneNumber" 
                  type="text" 
                  inputMode="tel" 
                  required 
                  // pattern="[0-9]{4,6}" // need help translating regex from validPhoneRegex
                  value={contactForm.phoneNumber}
                  placeholder="Phone Number*"
                  onChange={(e) => setFormVals('phoneNumber', e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <Label htmlFor="message" value="How can we help? *" className="mb-2 hidden" />
                <Textarea className="rounded-[20px] overflow-hidden items-center"
                  id="message" 
                  rows={6}
                  required 
                  value={contactForm.message}
                  placeholder='How can we help?*'
                  onChange={(e) => setFormVals('message', e.target.value)} 
                />
              </div>
              <div className="my-4 block col-span-2">

              </div>


          </form>
          <SlidingToggleButton buttonText={'Submit'} func={submitMessage} disabled={isSending}/>
        </div>  
      </div>
      
    </>
  )
}

export default ContactFormHome;