import { useState } from 'react';

import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { propertyTypeActions } from "../../redux/property_type";
import { propertyActions } from "../../redux/property";
import { RootState, AppDispatch } from '../../store';


function PropertyMessageDetail() {

  const { pmId } = useParams();

  const propertyMessageData:any = useSelector((state: RootState) => state.message.propertyMessageList.find((m: any) => m.id === pmId));

  return (
    <div className="h-full min-h-screen w-full">
      <div className="md:w-3/4 w-[80%] mx-auto mt-10 mb-0 text-left">
        <Link to={'/admin/messages/properties'} className="block italic font-light">
          {"<< back to list"}
        </Link>
        <h4 className="font-bold text-2xl">Property Inquiry </h4>
        <p className="text-sm">Message Details </p>
      </div>
      <div className="md:w-3/4 w-[80%]  mx-auto my-5 text-left ">
        <div className="border border-slate-200 rounded-md overflow-hidden">
          <div className="block text-gray-700 font-bold p-1 bg-slate-200 ">
            User details
          </div>
          <div className="py-1 pr-1 pl-2">
            <div>
              <span className="mr-2 font-bold">Full name:</span>
              <span>{`${propertyMessageData.user.firstName} ${propertyMessageData.user.lastName}`}</span>
            </div>
            <div>
              <span className="mr-2 font-bold">Email</span>
              <span>{propertyMessageData.user.email}</span>
            </div>
            <div>
              <span className="mr-2 font-bold">Phone number</span>
              <span>{propertyMessageData.user.phoneNumber}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-3/4 w-[80%] mx-auto my-5 text-left ">
        <div className="border border-slate-200 rounded-md overflow-hidden">
          <div className="block text-gray-700 font-bold p-1 bg-slate-200 ">
            Property details
          </div>
          <div className="py-1 pr-1 pl-2">
            <div>
              <span className="mr-2 font-bold">Property name</span>
              <span>{propertyMessageData.property.name}</span>
            </div>
            <div>
              <span className="mr-2 font-bold">State</span>
              <span>{propertyMessageData.property.state}</span>
            </div>
            <div>
              <span className="mr-2 font-bold">Address</span>
              <span>{propertyMessageData.property.address}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-3/4 w-[80%]  mx-auto  my-5 text-left ">
        <div className="mb-4">
          <div className="block text-gray-700 font-bold bg-slate-200 p-1 rounded-t-md border border-slate-300">
            Message body
          </div>
          <div>
            <div className="w-full h-fit p-2 min-h-[10rem] border border-slate-300 rounded-b-md">{propertyMessageData.message}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyMessageDetail;