import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql-custom/queries-custom';
import {reject, unionBy} from 'lodash-es';

const PAGE_LIMIT = 100;
const SEARCH_PAGE_LIMIT = 200;

const fetchFaqListByPage = createAsyncThunk(
  'faqs/list/bypage',
  async (_: any, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql({
        query: queries.listFaqs,
        variables: {limit: PAGE_LIMIT,
          filter: 
          {
            //@ts-ignore
            page: { eq: _.page }
          }
        },
        authMode: 'API_KEY'
      });
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  },
);

const fetchFaqList = createAsyncThunk(
  'faqs/list',
  async (_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(graphqlOperation(queries.listFaqs, {limit: PAGE_LIMIT}));
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  },
);

interface FaqState {
  faqsByPage: {
    'NDIS': any[],
    'SMSF': any[],
    'HOME': any[],
    'OTHER': any[],
  };
  faqs: any[];
}

export const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    faqsByPage: {
      'NDIS': [],
      'SMSF': [],
      'HOME': [],
      'OTHER': [],
    },
    faqs: [],
  } as FaqState,
  reducers: { 
    setFaqListByPage: (state, action) => {
      //@ts-ignore
      state.faqsByPage[action.payload.page] = action.payload.faqs;
    },
    updateFaqById: (state, action) => {
      let index = state.faqs.findIndex((faq) => faq.id === action.payload.id);
      console.log(action.payload);
      console.log(index);
      
      if (index !== -1) {
        state.faqs[index] = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFaqListByPage.fulfilled, (state, action) => {
      //@ts-ignore
      state.faqsByPage[action.payload.listFaqs.items[0].page] = action.payload.listFaqs.items.sort((b, a) => a.weight - b.weight);
      state.faqs = unionBy(state.faqs, action.payload.listFaqs.items, 'id');
    });
    builder.addCase(fetchFaqList.fulfilled, (state, action) => {
      state.faqs = unionBy(state.faqs, action.payload.listFaqs.items, 'id');
    });
  },
});

export const faqActions = {
  fetchFaqListByPage,
  fetchFaqList,
  ...faqSlice.actions,
};

export default faqSlice.reducer;