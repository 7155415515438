import { useState,useRef,useLayoutEffect } from "react";
import { render } from "react-dom";
import PropertySearchCard from "./PropertySearchCard";
import CircularLoader from '../../../common/components/CircularLoader'


const PropertySearchList = ({propertyList}: any) => {
  const [moreCard,setMoreCard] = useState(6);
  const [inView, setInView] = useState(false);
  const maxLength=propertyList.length;
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(()=>{
    const observer= new IntersectionObserver((entries) => {
      const entry=entries[0];
      setInView(entry.isIntersecting) 
    })
    observer.observe(scrollRef.current!)
  },[])

  console.log(propertyList);
  return ( 
    <div className="grid grid-cols-1 md:grid md:grid-cols-3 gap-y-6 md:gap-x-10 md:gap-y-10  overflow-hidden ">
      {propertyList.slice(0,moreCard).map((data: any) => (
        <PropertySearchCard
          key={data.id}
          title={data.name}
          image={data.primaryImageUrl ?? (data.imageUrl.length > 0 ? data.imageUrl[0] : '')}
          price={data.price}
          propertyType={data.propertyType?.name ?? ''}
          fundsRequirement={0}
          rentalYield={data.minimumRentalYield}
          bedroom={data.bedrooms}
          bathroom={data.bathrooms}
          carSpace={data.carspaces}
          status={data.propertyStatus}
          availability={data.listAvailability}
          state={data.state}
          id={data.id}
        />
      ))}
      {inView && Math.ceil(maxLength)>=moreCard ? setMoreCard(moreCard+3) : 
        <p className="col-span-1 md:col-span-3 text-center font-epilogue text-lg text-id-blue">
          End of Search
        </p>}
      <div ref={scrollRef} className="col-span-1 md:col-span-3 text-center"></div>
    </div>
        
  );
}
export default PropertySearchList;