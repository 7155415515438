import React from 'react'
import { createFileLink } from '../../../helpers/api'
import { Link } from 'react-router-dom'
import { dollarParse } from '../../../helpers/parsers'
import { parseStatus, parseAvailability } from '../../../helpers/parsers'
import MDEditor from '@uiw/react-md-editor';


function Details({property, eoi, eoiFlag}: any) {
  const eoiText = () => {
    if (property.listAvailability != "SOLD") {
      if (eoiFlag === 3) {
        return "Submit Expression of Interest";
      } else if (eoiFlag === 2) {
        return "Inquire Below";
      } else if (eoiFlag === 1) {
        return "Withdraw Interest";
      }
    } else {
      return "Property sold"
    }
  }
  return (
    <>
      {/* Property Details section */}
      <section className="bg-neutral-50">
        <div className="flex flex-col lg:flex-row py-8 px-6 mx-auto max-w-screen-xl">
          <div className="grid basis-2/3 gap-12 h-fit">
            <div className="font-epilogue max-h-fit">
              <h1 className="text-4xl font-extrabold text-id-blue2 my-4">
                {property.name}
              </h1>
              <h2 className="text-3xl font-bold text-id-blue2">{`Price: ${dollarParse.format(property.price)}`}</h2>
            </div>
            <div className="flex flex-col lg:flex-row font-inter mb-10 gap-8">

              {/* brochure */}
              { (property.brochureUrl.length > 0) 
              ? <Link to={createFileLink(property.brochureUrl[0])} target="_blank"  className="rounded-md bg-id-blue3 text-white text-xl lg:w-80 py-7 text-center">
                <button>
                  Download Brochure
                </button>
              </Link> 
              : <button className="bg-slate-300 rounded-md text-white text-xl lg:w-80 py-7" disabled>No Brochure Available</button> }

              <button className="rounded-md bg-none border border-id-blue3 text-id-blue3 text-xl lg:w-80 py-7 disabled:opacity-50"
                onClick={() => eoi()}
                disabled={eoiFlag === 2 || property.listAvailability === "SOLD"}
              >
                {`${eoiText()}`}
              </button>
            </div>
          </div>
          <div className="grid basis-1/3 bg-white rounded-md border shadow-sm p-8 ">
            <div className="">
              <ul>
                <li className="font-epilogue">
                  <h3 className="text-2xl font-bold">Property Details:</h3>
                </li>
                <li className="font-inter text-neutral-500">Address: {property.address}</li>
                <li className="font-inter text-neutral-500">Type: {property.propertyType.name}</li>
                <li className="font-inter text-neutral-500">{`Layout: ${property.bedrooms} Bed, ${property.bathrooms} Bath, ${property.carspaces} Car`}</li>
                <li className="font-inter text-neutral-500">Registration: {parseStatus(property.propertyStatus)}</li>
                <li className="font-inter text-neutral-500">Availability: {parseAvailability(property.listAvailability)}</li>
              </ul>
            </div>
            <div className="pt-4">
              <ul>
                <li className="font-epilogue">
                  <h3 className="text-2xl font-bold">Property Financials:</h3>
                </li>
                {/* to be calculated later */}
                {/* <li className="font-inter text-neutral-500">Funds Required: TBD</li> */}
                <li className="font-inter text-neutral-500">Weekly Rental Income: {`${dollarParse.format(property.rentalIncome)}`}</li>
                <li className="font-inter text-neutral-500">Minimum Rental Yield: {`${property.minimumRentalYield}%`}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Description section */}
      <section className="bg-white py-4">
        <div className="grid gap-4 py-8 px-6 mx-auto max-w-screen-xl content-start items-start">
          <h2 className="text-3xl font-bold font-epilogue">Description</h2>
          <div className="gap-12 md:py-12 py-8 px-6">
            <article className="w-full">
              <MDEditor.Markdown source={property.description} 
                style={{ 
                  backgroundColor: 'white', 
                  color: 'black', 
                  whiteSpace: 'pre-wrap', 
                  display: 'flex', 
                  flexDirection: 'column'
                }}
              />
            </article>

          </div>
        </div>
      </section>
    </>
  )
}

export default Details