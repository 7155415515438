import fam3 from '../../../static/fam3.jpg';
import BookBG from '../../../static/NewAssets/Discovery Call Pattern.png';
import Tick from '../../../static/NewAssets/Tick.png';
import Arrow from '../../../static/NewAssets/Arrow RGB.png';
import Calendar from '../../../static/NewAssets/caliconbig.png';
import { useNavigate } from 'react-router-dom';

function BookACall({scrollPercentage}: any) {
  const scrollLower = 0.60;
  const scrollUpper = 0.68;
  const scaleScroll = (scrollPercentage - scrollLower) / (scrollUpper - scrollLower);
  return (
    <>
      <div className="z-40 -mt-12 sticky -top-12 h-[105vh] flex justify-center items-center bg-cover bg-no-repeat bg-top overflow-hidden" style={{backgroundImage: `url("${BookBG}")`}}>
        <div className="absolute">
          <img src={BookBG} className={`w-full h-full object-fill`} style={{transform: `scale(${scaleScroll > 0 ? 1.25 + (parseFloat(scaleScroll.toFixed(2)) * 2) : 1.25 })`}}/>
        </div>
        <h1 className="md:text-9xl sm:text-6xl text-2xl font-bold text-white text-center font-robotoslabbold xl:w-2/5 z-40">Book a discovery call</h1>
      </div>
      <div className="z-40 h-[105vh] flex flex-col md:flex-row py-20 md:py-52 px-8 sm:px-20 bg-cover bg-no-repeat bg-top bg-id-new-darkbluealt sticky top-0" id="book-a-call">
        <div className="flex flex-col">
          <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white">Book a discovery call</h3>
          <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5 z-10">Not sure where to start?</h1>
          <p className="text-white sm:text-2xl text-lg font-museosans md:w-1/3 sm:mt-8 mt-4 z-10">
            Book a session now for personalized advice, access to premium properties, 
            and start achieving your investment goals with ease.
          </p>
          {/* <div className="flex items-center justify-center mt-8 rounded-2xl bg-id-new-purple2 w-60 h-20 text-white font-robotoslab text-2xl z-20">
            Book A Meeting
          </div> */}
          <div className="absolute bottom-60 left-48 scale-150 z-0 hidden md:flex flex-col">
            <img src={Arrow} alt="arrow" className={scrollPercentage > 0.78 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"}/>
          </div>
        </div>
        <div className="flex flex-col items-center -mt-5 cursor-pointer" onClick={() => window.open('https://investdoor.youcanbook.me/','_blank', 'rel=noopener noreferrer')}>
          <img src={Calendar} className="h-5/6 pb-10 object-cover overflow-visible" />
          <div className="sm:text-6xl text-2xl font-robotoslabbold text-white -mt-5 z-10 text-center mb-10 sm:mb-0">Click to schedule meeting</div>
        </div>
      </div>
      <div className="z-40 bg-id-new-purple2 h-[32rem] flex flex-col justify-center items-center rounded-t-3xl sticky -top-2 -mt-5 overflow-hidden">
        <div className="absolute opacity-60">
          <img src={Tick} alt="tick" className={scrollPercentage > 0.82 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <p className="text-2xl italic text-white z-30 font-museosanssemilight w-1/2 text-center">
          "Time is more valuable than money. You can get more money, but you cannot get more time."
        </p>
        <h3 className="text-2xl text-white z-30 font-museosanssemibold mt-6">Jim Rohn</h3>
      </div>
    </>
  )
}

export default BookACall;