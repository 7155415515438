import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify'
import { createFileLink } from '../../helpers/api';
import { createFileLabel } from '../../helpers/parsers';

//devbucket: dvzh2qmpskvye.cloudfront.net/public/KEY
//prodbucket: d2in3gbkovys0e.cloudfront.net/public/KEY

//WIP make this more decoupled later on. for now make everything work
function SingleImagePicker({obj, objKey, onChangeFormField}: any) {
  
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'text/html': ['.html', '.htm'],
    },
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      onChangeFormField(objKey, acceptedFiles[0]);
    },
  });

  const handleUpload = async () => {
    setUploading(true);
    try {
      console.log(images[0]);
      await Promise.all(images.map((file: any) => Storage.put(file.name, file).then(async (result) => {
        console.log(result); //{key: 'myfilename.png'} <-- save this to the db!
      })));
      alert('Images uploaded successfully!');
      setImages([]);
    } catch (error) {
      console.error('Error uploading images', error);
      alert('Error uploading images');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <h2>Upload Image</h2>
      <div {...getRootProps({
        className:
        'mt-1 flex justify-center items-center w-full h-32 border-2 border-dashed rounded-lg'
		})}>
        <input {...getInputProps()} />
        <p className="text-gray-400">Drag and drop image here, or click to select files.</p>
      </div>
      <div className="flex flex-wrap flex-col text-left mt-4">

        <div
          className="w-1/4 p-2 flex"
        >
          <a
            href={createFileLink(obj[objKey]) as string}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {createFileLabel(obj[objKey])}
          </a>
          {obj[objKey] !== '' ? <div className="inline-block mx-2">
            <button className="bg-gray-200 w-6 h-6" onClick={(e) => {
              e.preventDefault();
              onChangeFormField(objKey, '');
            }}>
              x
            </button>
            <img src={createFileLink(obj[objKey])} />
          </div> : null}
          
        </div>

      </div>
    </div>
  );
}

export default SingleImagePicker;
