import { Spinner } from "flowbite-react";


function CircularLoader() {
  return (
    <div className="h-44 w-full flex">
      <div className="m-auto">
        <Spinner size="xl" color="gray"/>
      </div>
    </div>
  );
}

export default CircularLoader;