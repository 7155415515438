import hero1 from '../../static/hero1.png';
import Secondary from '../components/Secondary';
import Cta from '../components/Cta';
import { useState } from 'react';
import PropertySearchFilter from '../components/PropertySearch/PropertySearchFilter'
import PropertySearchList from '../components/PropertySearch/PropertySearchList';
import Herotext from '../components/HeroText';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../../store';
import { propertyActions } from '../../redux/property';
import { propertyTypeActions } from '../../redux/property_type';
import { useEffect } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { STATES, PROPERTY_STATUSES, CONTRACT_TYPES } from '../../constants/fields';
import CircularLoader from '../../common/components/CircularLoader';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from 'react-router-dom';

const initialFormVals = {
  states: STATES.map((s) => s.value),
  propertyTypeIds: [],
  statuses: PROPERTY_STATUSES.map((p) => p.value),
  contractTypes: CONTRACT_TYPES.map((c) => c.value),
  minPrice: '0',
  maxPrice: '1000000',
  minRentalIncome: '0',
  maxRentalIncome: '10000',
  minRentalYield: '0',
  minBedrooms: '1',
  maxBedrooms: '10',
  minBathrooms: '1',
  maxBathrooms: '5',
  minCarspaces: '1',
  maxCarspaces: '5',
}

const constantFields = {
  states: STATES,
  propertyStatuses: PROPERTY_STATUSES,
  contractTypes: CONTRACT_TYPES
}

function PropertySearchHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [formVals, setFormVals] = useState(initialFormVals);
  const [loading, setLoading] = useState(false);
  const [filter,setFilter] = useState(false);
  const [pointAccordionOpen, setPointAccordionOpen] = useState(true);
  const [purchaserAccordionOpen, setPurchaserAccordionOpen] = useState(true);


  const openFilter = () => {
    setFilter(!filter);
  }
  // const {
  //   propertyList,
  //   searchedPropertyList,
  //   propertyTypeList,
  //   currentUser
  // } = useSelector((state: RootState) => {
  //   const { propertyList, searchedPropertyList } = state.property;
  //   const { propertyTypeList } = state.propertyType;
  //   const {currentUser} = state.user;
  //   return {
  //     propertyList,
  //     searchedPropertyList,
  //     propertyTypeList,
  //     currentUser
  //   };
  // });

  const searchedPropertyList = useSelector((state: RootState) => state.property.searchedPropertyList);

  const propertyTypeList = useSelector((state: RootState) => state.propertyType.propertyTypeList);

  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  useEffect(() => {
    //getPropertyList();
    getPropertyTypeList();
    //clear search on load
    dispatch(propertyActions.setSearchedProperties([]));
  }, []);

  useEffect(() => {
    enableAllPropertyTypes();
  }, [propertyTypeList]);

  const enableAllPropertyTypes = () => {
    if (propertyTypeList.length > 0) {
      setFormVals({
        ...formVals,
        ['propertyTypeIds' as keyof typeof initialFormVals]: propertyTypeList.map(p => p.id)
      });
    }
  }

  const onChangeVal = (key: string, val: string, isArrField = false) => {
    let newVal = null;
    if(!isArrField) {
      newVal = val;
    } else {
      let arr = formVals[key as keyof typeof initialFormVals] as string[];
      if(arr.includes(val)) {
        newVal = arr.filter((i) => i !== val);
      } else {
        newVal = [...arr, val];
      }
    }
    setFormVals({
      ...formVals,
      [key]: newVal
    });
  }

  const getPropertyTypeList = async () => {
    if (propertyTypeList.length === 0) {
      setLoading(true);
      await dispatch(propertyTypeActions.fetchPropertyTypeList());
      setLoading(false);
    }
  }

  const searchProperties = async () => {
    setLoading(true);
    let payloadVals: any = formVals;
    const dontParse = ['states', 'propertyTypeIds', 'statuses', 'contractTypes'];
    //convert strings to floats
    Object.keys(payloadVals).forEach((k: string) => {
      if (!dontParse.includes(k)) {
        payloadVals[k] = parseFloat(payloadVals[k]);
      }
    });
    await dispatch(propertyActions.userSearchProperties(payloadVals));
    setLoading(false);
    setFilter(false);
    console.log(searchedPropertyList);
  }

  const resetFilters = () => {
    setFormVals(initialFormVals);
    enableAllPropertyTypes();
    setFilter(false);
  }

  const PropertyHeroDesc = () => {
    return (
      <div className="flex flex-col gap-10">
        <div className="text-neutral-600">
        As an advisor, we understand that finding the perfect property for your clients can be a challenging task, especially when trying to align it with their investment strategy. 
        <br/><br/>
        Our Property Search Portal is designed to help you simplify the process. With our portal, you can search for properties based on your client's preferred location, budget, investment strategy, and other specific criteria. 
        </div>
      </div>
    );
  }

  const togglePointAcc = () => {
    setPointAccordionOpen(!pointAccordionOpen);
  }

  const togglePurchaserAcc = () => {
    setPurchaserAccordionOpen(!purchaserAccordionOpen);
  }

  const NoMatches = () => {
    return (
      <div className="text-left md:text-center font-inter text-lg text-id-blue2 my-10">
        Use our filters to find properties that match your criteria.
      </div>
    );
  }

  const NotAuthorized = (
      <div className="w-full text-center p-10">
        You are not authorized to view this page. You need to have a verified account. 
        Please <Link to="/contact" className="underline">contact us</Link> for more information.
      </div>
  );

  const PropertySearchBody = (
      <> 
        <div className="w-full bg-home-hero-blue">
          <div className="py-10 px-2 md:container md:mx-auto">   
            <div className="md:flex md:flex-row">
              <div className="flex flex-col justify-center  ">
                {/*Header */}
                <div className="text-3xl font-bold text-id-blue text-center px-auto md:text-5xl md:text-left">
                  Property Search Portal
                </div>
                {/*Article */}
                <div className="font-inter mt-3 text-neutral-600 text-center text-sm md:text-left  md:mt-10 md:text-lg md:whitespace-pre-line">
                  <PropertyHeroDesc />
                </div>
              </div>
            </div>
          </div>
        </div>  
        {/* Filter and Search Results starts here */}
        <div className=" w-full md:container  mx-auto">
            <div className="md:hidden w-full  bg-white ">
              <button className="mx-3 border border-id-blue2 px-2 rounded-sm my-2" onClick={openFilter}>{filter? <FilterListOffIcon/>:<FilterListIcon/>} Filter</button>
            </div>
            <div className={`flex ${filter ? 'flex-col ' : 'flex-row'} md:flex-row mt-10 md:my-10 md:mx-auto md:gap-5`}>
              {/* Filter Section */}
              <div className={`${filter ? 'w-full absolute duration-100' : 'w-0 duration-100'} md:relative md:flex flex-col border shadow-lg md:w-80 md:min-w-[18rem] h-fit`}>
                      <PropertySearchFilter {...{formVals, 
                        propertyTypeList, 
                        constantFields, 
                        onChangeVal, 
                        searchProperties, 
                        resetFilters,
                        pointAccordionOpen,
                        purchaserAccordionOpen,
                        togglePointAcc,
                        togglePurchaserAcc
                      }}/>
              </div>
              {/* Search Results Section*/}
              <div className={`${filter ? 'w-0 md:w-92 ' : 'w-full delay-100'}  border shadow-lg  md:min-w-[65rem] md:w-full bg-id-grey3 items-center`}>
                  {/* input property data cards */}
                  <div className=" m-10 p-0  overflow-hidden hover:overflow-y-auto">
                    <span className="block text-2xl mb-5 md:mb-10 self-start font-bold text-id-blue2 md:text-4xl md:text-left ">
                      Search Results
                    </span>
                    { loading ? <CircularLoader /> : 
                      (searchedPropertyList.length > 0 ? <PropertySearchList propertyList={searchedPropertyList}/> : <NoMatches/>)} 
                  </div>
                
              </div>
            </div>
          
        </div>
      </>
    );
  

  return !!currentUser && currentUser.accountTier !== "NEW_ACCOUNT" ? PropertySearchBody : NotAuthorized;
}

export default PropertySearchHome;