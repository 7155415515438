import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './redux/user';
import propertyTypeReducer from './redux/property_type';
import propertyReducer from './redux/property';
import messageReducer from './redux/message';
import articleReducer from './redux/article';
import faqReducer from './redux/faq';

import localforage from 'localforage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['user', 'propertyType', 'property', 'message', 'article', 'faq']
}

const rootReducer = combineReducers({
  user: userReducer,
  propertyType: propertyTypeReducer,
  property: propertyReducer,
  message: messageReducer,
  article: articleReducer,
  faq: faqReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;