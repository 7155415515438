import { CheckboxProps } from "@mui/material";
import { useState } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterSection1 from "./FilterSection1";
import FilterSection2 from "./FilterSection2"
import Accordion from '../../../common/components/Accordion/Accordion';

const PropertySearchFilter = ({
    formVals, 
    propertyTypeList,
    constantFields,
    onChangeVal,
    searchProperties, 
    resetFilters,
    pointAccordionOpen,
    purchaserAccordionOpen,
    togglePointAcc,
    togglePurchaserAcc
  } : any) => {
  
  const accordionItems1 = [
    {
      title: 'Purchaser',
      content: (
        <div className="px-4">
          <FilterSection1 {...{formVals, onChangeVal, constantFields}}/>
        </div>),
    }
  ];
  
  const accordionItems2 = [
    {
      title: 'Point',
      content: ( 
        <div className="px-4">
          <FilterSection2 {...{formVals, propertyTypeList, constantFields, onChangeVal}}/>
        </div>),
    }
  ];
  
  return (

    <div className='flex flex-col bg-white'>
      {/* Main */}
      {/* <div className='flex flex-col gap-2 border border-neutral-300 p-3'>
        <div className="font-bold  text-id-blue3">Select a client or filter manually:</div>
        <div className="font-bold">Client</div>
        <select className="w-full rounded-md" name="client" id="client">
          <option value="initial"></option>
          <option value="Client1">Client 1</option>
          <option value="Client2">Client 2</option>
        </select>
        <div className="text-lg font-bold"><FilterListIcon/> Filter</div>
      </div> */}
      {/* Section 1 */}
      <div className="border">
      <Accordion items={accordionItems1} openState={purchaserAccordionOpen} toggler={togglePurchaserAcc}/>
      </div>
      {/* Section 2 */}
      <div className="border">
      <Accordion items={accordionItems2} openState={pointAccordionOpen} toggler={togglePointAcc}/>
      </div>

      {/* Apply & Clear ALl */}
      <div className=' flex flex-col gap-2 p-3'>
        <button className="w-full border border-id-blue3 bg-white h-9 rounded-md flex flex-row justify-center 
          items-center text-center text-sm text-id-blue3 hover:bg-id-blue3 hover:text-white"
          onClick={()=>searchProperties()}
          >Search</button>
        <button className="w-full border border-id-blue3 bg-white h-9 rounded-md flex flex-row justify-center
          items-center text-center text-sm text-id-blue3 hover:bg-id-blue3 hover:text-white"
          onClick={()=>resetFilters()}
          >Reset fields</button>
      </div>
    </div>
  );
}

export default PropertySearchFilter;