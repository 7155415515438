import LocationMap from "./LocationMap"

function PropertyContactForm({formMessage, onChangeFormMessage, submitMessage, isSending, property}: any) {
  return (
    <section className="bg-neutral-50">
      <div className="flex flex-col lg:flex-row gap-8 py-8 px-6 mx-auto max-w-screen-xl">
        <LocationMap {...{property}}/>
        <div className="lg:w-1/2 w-full">
          <h2 className="text-3xl font-bold font-epilogue mb-8">Have a question?</h2>
          <form>
            {/* <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                <input type="text" id="firstName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-id-blue3 focus:border-id-blue3 block w-full p-2.5" placeholder="Input text" required />
              </div>
              <div className="relative z-0 w-full mb-6 group">
              <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                <input type="text" id="lastName" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-id-blue3 focus:border-id-blue3 block w-full p-2.5" placeholder="Input text" required />
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-id-blue3 focus:border-id-blue3 block w-full p-2.5" placeholder="Input text" required />
            </div>
            
            <div className="mb-6">
              <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label>
              <input type="tel" id="phoneNumber" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-id-blue3 focus:border-id-blue3 block w-full p-2.5" 
              pattern="^(\+?61|0)[2-478](\s?\d{4}){2}$" required />
            </div> */}

            <div className="mb-6">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">How can we help?</label>
              <textarea 
                id="message" 
                className="block p-2.5 w-full h-32 text-sm text-gray-900 bg-gray-50 
                  rounded-md border border-gray-300 focus:ring-id-blue3 focus:border-id-blue3" 
                placeholder="What questions do you have on this property?" 
                value={formMessage}
                onChange={(e) => onChangeFormMessage(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" 
              className="text-white bg-id-blue3 hover:bg-id-blue hover:transition-opacity focus:ring-4 
                focus:outline-none focus:ring-id-blue font-medium rounded-md text-md w-full px-5 py-3 text-center"  
              onClick={() => submitMessage()}
              disabled={isSending}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default PropertyContactForm