import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function PageFaqs({ faqs }: any) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderedFaqs = faqs.map((faq: any, index: number) => {
    const isActive = index === activeIndex;
    const icon = isActive ? <RemoveIcon /> : <AddIcon />;
    const contentClass = isActive ? 
      "transition-all duration-300 ease-in-out bg-gray-100 rounded-b-lg max-h-96 overflow-auto" : 
      "max-h-0 overflow-hidden transition-all duration-300 bg-gray-100 ease-in-out";

    return (
      <div key={faq.title} className="border-b border-gray-200">
        <div
          className="flex flex-row items-start justify-between py-4 cursor-pointer"
          onClick={() => onAccordionClick(index)}
        >
          <div className="flex flex-col">
            <h3 className="text-2xl font-medium">{faq.title}</h3>
            <h3 className="text-gray-500 text-xl">{faq.excerpt}</h3>
          </div>
          <div className="text-gray-500 text-sm">{icon}</div>
        </div>
        <div className={contentClass}>
          <div className="pb-4">
            <p className="p-4 text-lg">{faq.content}</p>
          </div>
        </div>
      </div>
    );
  });

  return <div className="w-full mx-auto justify-center container flex flex-col">{renderedFaqs}</div>;
}

export default PageFaqs