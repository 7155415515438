import React from 'react';

function NotFound() {
  return (
    <div className="text-center">
      <h1>404 Not Found</h1>
      <p>The page you're looking for could not be found, or you are not allowed access to it.</p>
    </div>
  );
}

export default NotFound;