import NewHero from '../../../static/NewAssets/Stock Image 1.png';
import Tick from '../../../static/NewAssets/Tick.png';
import SlidingToggleButton from './SlidingToggleButton';
import { useNavigate } from 'react-router-dom';

function HeroNew({scrollPercentage, scrollToBooking}: any) {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative snap-start snap-always h-[102vh] bottom-0 flex flex-col justify-center items-center bg-cover bg-no-repeat bg-top px-4 sm:px-8" style={{backgroundImage: `linear-gradient(to right top, #786FB044, #332B7866), url("${NewHero}")`}}>
        <div className="text-center block tracking-tighter">
          <h1 className="xl:text-9xl sm:text-7xl text-5xl font-robotoslab text-white xl:mb-6 mb-2">
            Empowering <span className="font-robotoslabbold">YOU</span>
          </h1>
          <h2 className="xl:text-5xl md:text-3xl text-xl font-robotoslab text-white mb-6">to achieve financial advancement.</h2>
        </div>
        <SlidingToggleButton buttonText={'Book a meeting'} func={scrollToBooking} />
      </div>
      <div className="bg-id-new-purple1 flex flex-col gap-12 py-12 justify-center items-center text-center rounded-t-3xl relative -mt-5 overflow-hidden">
        <div className="absolute opacity-50">
          <img src={Tick} alt="tick" className={scrollPercentage > 0.14 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <p className="text-white text-2xl font-museosanssemilight z-10 w-5/6 sm:w-1/2">
          We believe that investing in real estate is one of the best ways to secure a successful future, and we strive 
          to make that process as smooth and efficient as possible.
        </p>
        <p className="text-white text-2xl font-museosanssemilight z-10 w-5/6 sm:w-1/2">
          Our mission is to provide personalised advice and support to ensure our clients make the best 
          decisions for their unique needs. Let us help you turn your real estate dreams into a reality.
        </p>
        <h1 className="text-4xl font-bold text-white z-10 font-museosanssemilight">
          A <span className="font-museosanssemibold">simple</span> and <span className="font-museosanssemibold">trusted</span> investment process.</h1>
        <SlidingToggleButton buttonText={'Book a meeting'} func={scrollToBooking}/>
      </div>
      <div className="h-[104vh] -mt-5 my-5 bg-id-new-purple3 rounded-t-3xl flex flex-col justify-center items-center sticky -top-6">
        <div className={`2xl:text-[12rem] lg:text-[8rem] text-3xl font-museosans text-id-new-purple1 leading-none py-2 
          ${scrollPercentage > 0.188 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"}`}>AUTHENTICITY</div>
        <div className={`2xl:text-[12rem] lg:text-[8rem] text-3xl font-museosans text-id-new-purple1 leading-none py-2 
          ${scrollPercentage > 0.192 ? "animate-fade-left animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"}`}>TRANSPARENCY</div>
        <div className={`2xl:text-[12rem] lg:text-[8rem] text-3xl font-museosans text-id-new-purple1 leading-none py-2 
          ${scrollPercentage > 0.196 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"}`}>COMPASSION</div>
        <div className={`2xl:text-[12rem] lg:text-[8rem] text-3xl font-museosans text-id-new-purple1 leading-none py-2 
          ${scrollPercentage > 0.200 ? "animate-fade-left animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"}`}>KNOWLEDGE</div>
      </div>
    </>
  )
}

export default HeroNew;