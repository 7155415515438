import HeroNew from '../components/HomePage/HeroNew';
import CardOne from '../components/HomePage/CardOne';
import CardTwo from '../components/HomePage/CardTwo';
import CardThree from '../components/HomePage/CardThree';
import CardFour from '../components/HomePage/CardFour';
import BookACall from '../components/HomePage/BookACall';
import ContactFormHome from '../components/HomePage/ContactFormHome';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { validateEmail, validatePhone } from '../../helpers/validators';
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';

const initialFormVals = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: ""
}

function HomeNew({isLoggedIn, scrollPercentage}: any) {
  
  const navigate = useNavigate();

  const [toggleBooking, setToggleBooking] = useState(false);
  const [contactForm, setContactForm] = useState(initialFormVals);
  const [isSending, setIsSending] = useState(false);
  const [errmsg, setErrmsg] = useState('');
  const [captchadone,setCaptchaDone]= useState(false);
  
  const captchaDone = () => {
    setCaptchaDone(true);
  }

  const captcha = (size:any) => {
    return (
    <div>
      <ReCAPTCHA
      onChange={captchaDone}
      size={size}
      theme="light"
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ?? ''} 
      />
    </div>
    )
  }

  const formValidate = () => {
    let flag = true;
    if (!validateEmail(contactForm.email)) {
      setErrmsg('Please enter a valid email address. (e.g. your@email.com)');
      toast('Please enter a valid email address. (e.g. your@email.com)', {type: 'error'});
      flag = false;
    } else if (!validatePhone(contactForm.phoneNumber)) {
      setErrmsg('Please enter a valid phone number with a minimum of 10 digits.');
      toast('Please enter a valid phone number with a minimum of 10 digits.', {type: 'error'});
      flag = false;
    } 
    return flag;
  }  

  const submitMessage = async () => {
    console.log('here');
    setErrmsg('');
    if(formValidate()) {
      setIsSending(true);
      try {
        let payload = contactForm;
        //await API.graphql(graphqlOperation(mutations.createInquiryMessage, {input: payload, authMode:'AWS_IAM'}));
        await API.graphql({
          query: mutations.createInquiryMessage,
          variables: {input: payload},
          authMode: 'API_KEY'
        });
        toast.success('Inquiry successfully sent.')
        console.log('success');
        setContactForm(initialFormVals);
      } catch (e) {
        console.log(e);
        console.log('error');
        toast.error("Error sending your message. Please try again.")
      }
      setIsSending(false);
    }
  }

  const setFormVals = (key: string, val: string) => {
    setContactForm({
      ...contactForm,
      [key]: val
    });
  }

  const scrollToBooking = () => {
    document.getElementById('book-a-call')?.scrollIntoView({behavior: 'smooth'});
  }

  const scrollToContact = () => {
    document.getElementById('contact-us')?.scrollIntoView({behavior: 'smooth'});
  }

  const onPortalClick = () => {
    navigate("/property/search");
  }

  return (
    <div className={"w-full my-0 opacity-1 relative snap-proximity snap-y scroll-smooth"}>
      {/*
      <HeroText text={'Give property advice without giving the advice.'} 
                image={Home_Page} 
                description={HomeText()} />
      <Secondary />
           
      */}
      <HeroNew scrollPercentage={scrollPercentage} scrollToBooking={scrollToBooking}/>
      <CardOne scrollPercentage={scrollPercentage} scrollToContact={scrollToContact}/>
      <CardTwo scrollPercentage={scrollPercentage}/>
      <CardThree scrollPercentage={scrollPercentage} scrollToBooking={scrollToBooking}/>
      <CardFour scrollPercentage={scrollPercentage}/>
      <BookACall scrollPercentage={scrollPercentage}/>
      <ContactFormHome scrollPercentage={scrollPercentage} {...{contactForm, setFormVals, isSending, submitMessage, errmsg}}/>
    
    </div>

  );
}

export default HomeNew;