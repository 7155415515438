import {useState, useEffect} from 'react';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import {useDispatch, useSelector} from 'react-redux';

import { Link, Route, Routes } from 'react-router-dom';

import FormInput from '../components/FormInput';
import { RootState, AppDispatch } from '../../store';
import UserEdit from './UserEdit';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import {unionBy} from 'lodash-es';
import { ACCOUNT_TIERS } from '../../constants/fields';
import {userActions} from '../../redux/user';
import { ThunkDispatch } from '@reduxjs/toolkit';

const PAGE_LIMIT = 10;

function Users () {

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  //const [users, setUsers] = useState([]);
  const [nextToken, setNextToken] = useState<String | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [userIdForDeletion, setUserIdForDeletion] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const {
    users
  } = useSelector((state: RootState) => {
    const {users} = state.user;
    return {
      users
    };
  });

  // const getUserListPaginated = async () => {
  //   try {
  //     if(!nextToken) {
  //         //@ts-ignore
  //       let payload = await API.graphql(graphqlOperation(queries.listUsers, {limit: PAGE_LIMIT}));
  //       console.log(payload);
  //       //@ts-ignore
  //       let {data} = payload;
  //       setUsers(data.listUsers.items);
  //       setNextToken(data.listUsers.nextToken);
  //     }
  //     else {
  //       //@ts-ignore
  //       let {data} = await API.graphql(graphqlOperation(queries.listUsers, {nextToken: nextToken, limit: PAGE_LIMIT}));
  //       setUsers(unionBy(users, data.listUsers.items, 'id'));
  //       setNextToken(data.listUsers.nextToken);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  const getUserList = async () => {
    setLoading(true);
    setIsSearch(false);
    dispatch(userActions.setUsers([]));
    await dispatch(userActions.fetchUserList());
    setLoading(false);
  }

  const deleteUser = async (userId: any) => {
    try {
      await API.graphql(graphqlOperation(mutations.deleteUser, {input: {id: userId}}));
      setModalVisible(false);
      setUserIdForDeletion('');
      setNextToken(null);
      getUserList();
    } catch (e) {
      console.log(e);
    }
  }

  const confirmDeleteUser = async (userId: any) => {
    console.log(userId);
    setModalVisible(true);
    setUserIdForDeletion(userId);
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'firstName',
      defaultCanSort: true,
      Cell: (item : any) => {
        return <div>{`${item.value} ${item.row.original.lastName}`}</div>
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      defaultCanSort: false
    },
    {
      Header: 'ABN',
      accessor: 'ausBusinessNumber',
      defaultCanSort: false
    },
    {
      Header: 'Tier',
      accessor: 'accountTier',
      defaultCanSort: true,
      Cell: (item: any) => {
        return <div>{ ACCOUNT_TIERS.find(a => a.value === item.value)!.label }</div>
      }
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return (
          <>
            <Link to={`/admin/users/${item.value}`}>[update] </Link>
            <button onClick={() => confirmDeleteUser(item.value)} > [delete]</button>
          </>
        )
      }
    }
  ];

  const UserList =  (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
          <div className="flex flex-col">
            
            <h1 className="text-2xl font-bold">
              Users
            </h1>

            <p className="self-start py-4">
              Search, update, and delete user accounts.
            </p>
            <div className="row-auto self-start">
              <input className="rounded-l-md" type="text"/>
              <button className="shadow font-bold py-2 px-4 rounded-r-sm duration-300 hover:rounded-r-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4">search</button>
            </div>

            <div className="row-auto self-start">
              <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
              onClick={getUserList}>load list</button>
            </div>

            <div className=" overflow-auto">
              <Table columns={columns} data={users} />
            </div>
          </div>

            {/* <div className="flex flex-col basis-1/4 py-20 px-10">
              <div className="flex flex-row">
                <h3>
                  add new user
                </h3>
              </div>
              <div className="flex items-center py-4">
                <FormInput value={newState.name} label="Name" onChangeVal={onChangeFormField} paramkey="name"/>
              </div>
              <div className="flex items-center py-4">
                <FormInput value={newState.abbr} label="Abbr" onChangeVal={onChangeFormField} paramkey="abbr"/>
              </div>
              <div className="flex items-center py-4">
                <FormInput value={newState.stateCountryId} label="Country ID" onChangeVal={onChangeFormField} paramkey="stateCountryId"/>
              </div>

              <div className="flex items-center py-4">
                <button className="shadow bg-blue-200 hover:bg-purple-400 focus:shadow-outline 
                  focus:outline-none font-bold py-2 px-4 rounded" 
                  onClick={createState}
                  type="button">
                  Add User
                </button>

              </div>
              
            </div> */}
    </div>

    <ConfirmModal
      modalVisible={modalVisible} 
      onOk={() => deleteUser(userIdForDeletion)}
      onCancel={() => setModalVisible(false)}
      message={"Are you sure you want to delete this row? This is irreversible."}
    />

  </div>
  );

  return (
    <div>
      <Routes>
        <Route path="" element={UserList} />
        <Route path=":uId" element={<UserEdit/>} />
      </Routes>
    </div>
  );
}

export default Users;