
import CheckBox from "../../../common/components/CheckBox";
import InputValue from "../../../common/InputValue";
import { useState } from "react";

const FilterSection1 = ({formVals, onChangeVal, constantFields} : any) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  
    setIsChecked(e.target.checked);
    console.log(e.target.value);
    };

  return (
    <div className=" flex flex-col text-base">
      {/** hide some fields for now */}
      {/* <CheckBox label="Individual" handleChange={handleChange} isChecked={isChecked} />
      <CheckBox label="Trust" handleChange={handleChange} isChecked={isChecked} />
      <CheckBox label="SMSF" handleChange={handleChange} isChecked={isChecked} />
      <InputValue className1="text-lg" className2="w-full" label="Total Funds available" type="number" placeholder="%" id="TotalFundsavailable" handleChange={()=>{}} setState=""/>
      <InputValue className1="text-lg" className2="w-full" label="Max. Lending" type="number" placeholder="%" id="MaxLending" handleChange={()=>{}} setState=""/>
      <InputValue className1="text-lg" className2="w-full" label="Max. LVR" type="number" placeholder="%" id="MaxLVR" handleChange={()=>{}} setState=""/> */}
      <div className="text-lg font-bold">Price ($)</div>
      <div className="flex flex-row flex-auto w-fit " >
        <InputValue className1="text-sm" className2="w-[90%]" label="Min" type="number" 
          placeholder="$" id="minPrice" handleChange={(e) => onChangeVal('minPrice', e.target.value)} setState="" value={formVals.minPrice}/>
        <InputValue className1="text-sm" className2="w-[90%]" label="Max" type="number"
          placeholder="$" id="maxPrice" handleChange={(e) => onChangeVal('maxPrice', e.target.value)} setState="" value={formVals.maxPrice}/>
      </div>
      <div className="text-lg font-bold">Weekly Rental Income ($)</div>
        <div className="flex flex-row flex-auto w-fit " >
          <InputValue className1="text-sm text-neutral-800" className2="w-[90%]" label="Min" type="number" 
            placeholder="$" id="minRentalIncome" handleChange={(e) => onChangeVal('minRentalIncome', e.target.value)} setState="" value={formVals.minRentalIncome}/>
          <InputValue className1="text-sm" className2="w-[90%]" label="Max" type="number" 
            placeholder="$" id="maxRentalIncome" handleChange={(e) => onChangeVal('maxRentalIncome', e.target.value)} setState="" value={formVals.maxRentalIncome}/>
        </div>
      <InputValue className1="text-lg" className2="w-full" label="Minimum Rental Yield (%)" type="number" 
        placeholder="%" id="minRentalYield" handleChange={(e) => onChangeVal('minRentalYield', e.target.value)} setState="" value={formVals.minRentalYield}/>
      <div className="text-lg font-bold">Contract Type</div>
      <div className="flex flex-col">
        {
          constantFields.contractTypes.map((f: any, i: number) =>
            <CheckBox label={f.label} handleChange={() => onChangeVal('contractTypes', f.value, true)} isChecked={formVals.contractTypes.includes(f.value)} key={i}/>
          )
        }
      </div>
    </div>
  )
}

export default FilterSection1; 