import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { grey } from '@mui/material/colors';
import CityScape from '../../../static/CityScape.jpg';

const ArticleBanner = () => {
  return (
    <div className="relative w-full md:h-min overflow-hidden my-0 bg-cover  opacity-1 ">
      <div className="relative  md:h-[30rem] h-72 bottom-0 flex justify-center items-center opacity-40 md:opacity-60 bg-cover bg-no-repeat bg-top" style={{backgroundImage: `linear-gradient(to right top, #8681BD44, #2E3692EE), url("${CityScape}")`}} >
      </div>

      <div className="absolute container left-0 right-0 top-[0%]  flex flex-col h-full   w-full md:items-start justify-center items-center mx-auto md:px-28 px-4">
        <div className="font-epilogue text-lg md:text-4xl md:p-0 px-4 pt-14 md:pt-2   font-bold text-neutral-700 text-center">
        News and Articles
        </div>
        <section className=" md:h-fit px-2 md:px-0 h-64 relative text-center md:text-left  ">
            <div className="md:flex md:flex-row justify-start md:w-[45rem] md: md:mt-2">
              
              <span className="font-intern md:text-xl test-xs mt-2 mx-3 text-neutral-700 text-bold">
              We understand the significance of well-informed decisions when it comes to investing in property. That's why we've curated a collection of informative articles and thought-provoking blog posts to serve as your guide through the real estate investment landscape.
              </span>
              
            </div>

        </section>
      </div>
    </div>
  )
}

export default ArticleBanner;