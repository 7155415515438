import React, { useState, useEffect } from 'react';
import { API, graphqlOperation, Storage } from "aws-amplify";
import MDEditor from '@uiw/react-md-editor';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { propertyTypeActions } from '../../redux/property_type';
import { ThunkDispatch } from '@reduxjs/toolkit';

import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";
import TagForm from '../components/TagForm';

import CircularLoader from '../../common/components/CircularLoader';

import { Link, useNavigate } from 'react-router-dom';

const initialTag = {
  name: '',
  urlSlug: ''
}


function TagAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const [newTag, setNewTag] = useState(initialTag);
  const [loading, setLoading] = useState(false);

  const onChangeFormField = (key: string, val: any) => {
    setNewTag({
      ...newTag,
      [key]: val
    });
  }

  const createTag = async () => {
    setLoading(true);
    try {
      let payload = newTag;
      await API.graphql(graphqlOperation(mutations.createArticleTag, {input: payload}));
      toast.success("Added new Tag");
      setNewTag(initialTag);
    } catch (e) {
      console.log(e);
      toast.error("Error adding Tag. Please try again.")
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createTag();
  }

  return(
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/tags'} className="block italic font-light">
            {"<< back to list"}
          </Link>
        </div>
        <TagForm tag={newTag} onChangeFormField={onChangeFormField} />

        <button onClick={handleSubmit} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-45 my-2 mr-4">
          SAVE TAG
        </button>
      </div>
    </div>
  );
}

export default TagAdd;