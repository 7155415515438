export const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validPhoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const validateEmail = (input: string) => {
  return input.toLowerCase().match(validEmailRegex);
}

export const validatePhone = (input: string) => {
  return input.match(validPhoneRegex);
}

// Path: src/helpers/validators.ts
export const validABNRegex = /^[0-9]{11}$/;

export const validateABN = (input: string) => {
  return input.match(validABNRegex);
}
