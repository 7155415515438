import {useState} from 'react';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import hero3 from '../../static/hero3-contact.png';
import Cta from '../components/Cta';
import HeroText from '../components/HeroText';
import Contact_Us from '../../static/HeroText/Contact_Us.png';
import Statement from '../components/HomePage/Statement';
import InquiryContactForm from '../components/InquiryContactForm';
import { toast } from "react-toastify";
import { validateEmail, validatePhone } from '../../helpers/validators';
import ReCAPTCHA from 'react-google-recaptcha';

const initialFormVals = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: ""
}



function ContactUs() {
  const [contactForm, setContactForm] = useState(initialFormVals);
  const [isSending, setIsSending] = useState(false);
  const [errmsg, setErrmsg] = useState('');
  const [captchadone,setCaptchaDone]= useState(false);
  
  const captchaDone = () => {
    setCaptchaDone(true)
    
  }
  
  const captcha = (size:any) => {
    return (
    <div>
      <ReCAPTCHA
      onChange={captchaDone}
      size={size}
      theme="light"
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ?? ''} 
      />
    </div>
    )
  }

  const setFormVals = (key: string, val: string) => {
    setContactForm({
      ...contactForm,
      [key]: val
    });
  }

  const formValidate = () => {
    let flag = true;
    if (!validateEmail(contactForm.email)) {
      setErrmsg('Please enter a valid email address. (e.g. your@email.com)');
      flag = false;
    } else if (!validatePhone(contactForm.phoneNumber)) {
      setErrmsg('Please enter a valid phone number with a minimum of 10 digits.');
      flag = false;
    } 
    return flag;
  }  

  const submitMessage = async (e: any) => {
    setErrmsg('');
    e.preventDefault();
    if(formValidate()) {
      setIsSending(true);
      try {
        let payload = contactForm;
        //await API.graphql(graphqlOperation(mutations.createInquiryMessage, {input: payload, authMode:'AWS_IAM'}));
        await API.graphql({
          query: mutations.createInquiryMessage,
          variables: {input: payload},
          authMode: 'API_KEY'
        });
        toast.success('Inquiry successfully sent.')
        setContactForm(initialFormVals);
      } catch (e) {
        console.log(e);
        toast.error("Error sending your message. Please try again.")
      }
      setIsSending(false);
    }
  }

  const ContactUs = () => {
    return (
      <>
        <div className="pt-5 md:text-left md:pt-10 md:pl-10">
          <PlaceOutlinedIcon className="text-id-blue2 mr-2" />
          South Melbourne, Victoria
        </div>
        <div className="pt-5 md:text-left md:pt-10 md:pl-10">
          <InfoOutlinedIcon className="text-id-blue2 mr-2" />
          info@investdoor.com.au
        </div>
        <div className="pt-5 md:text-left md:pt-10 md:pl-10">
          <LocalPhoneOutlinedIcon className="text-id-blue2 mr-2" />
          0413 331 145
        </div>
      </>
    );
  }

  return (
    <>
      <HeroText text={'Contact Us'} image={Contact_Us} description={ContactUs()} />
      <InquiryContactForm {...{contactForm, setFormVals, isSending, submitMessage, errmsg, captchadone,captcha}} />

    </>
  );
}

export default ContactUs;