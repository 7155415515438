import React, {useState, useEffect} from 'react';
import { Auth } from 'aws-amplify';
import {createDBUser, signIn} from '../../api/amp-auth';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux'
import { userActions } from '../../redux/user';
import { ThunkDispatch } from '@reduxjs/toolkit';

function Login () {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [ausBusinessNumber, setAusBusinessNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errmsg, setErrMsg] = useState('');
  const [askInfo, setAskInfo] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [mounted, setMount] = useState(false);
  const [tempUser, setTempUser] = useState<any>(null);
  const [captchadone,setCaptchaDone]= useState(false);

  const captchaDone = () => {
    setCaptchaDone(true)
    
  }
  const captcha = (size:any) => {
    return (
    <div>
      <ReCAPTCHA
      onChange={captchaDone}
      size={size}
      theme="light"
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ?? ''} 
      />
    </div>
    )
  }

  console.log(captchadone)

  const handleLogin = async (e: any) => {
    
    e.preventDefault();
    
    setErrMsg('');
    if(!isLoading) {
      setLoading(true);
      try {
        let user = await signIn(username, password);
        user = await Auth.currentAuthenticatedUser();
        setTempUser(user);

        setUsername('');
        setPassword('');     
        //if user is not confirmed, navigate to confirmation page
        //@ts-ignore 
        if(!user.attributes.email_verified) {
          navigate('/verify');
        //if user is confirmed but has missing info, open info subcomponent
          //@ts-ignore
        } else if(typeof user.attributes['custom:db_id'] === 'undefined' || user.attributes['custom:db_id'] == null){
          setAskInfo(true);    
          setLoading(false);
        //if user is already registered before, then go straight to login    
        } else {
          await dispatch(userActions.fetchCurrentUser());
          navigate('/');
          setLoading(false);
          setTempUser(null);
        }    
      } catch (e: any) {
        console.log(e);
        setLoading(false);
        if (e.code === "UserNotConfirmedException") {
          dispatch(userActions.setCurrentUserName(username));
          navigate('/verify');
          setLoading(false);
        } else {
          setErrMsg('Invalid username and/or password');
        }
        
      }
    }
  }

  const initNewUser = async () => {
    setLoading(true);
    try {
      if(phoneNumber !== '' && ausBusinessNumber !== '') {
        let user = tempUser;
        user.ausBusinessNumber = ausBusinessNumber;
        user.phoneNumber = phoneNumber;
        await createDBUser(user);
        await dispatch(userActions.fetchCurrentUser());
        navigate('/');
      } else {
        setErrMsg("These fields are required.");
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setErrMsg("There was an error processing your data. Please try again.");
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut();
      console.log("sign out success");
    } catch (err) {
      console.log('error signing out...: ', err);
    }
  }

  useEffect(() => {
    if(!mounted) logout()
    setMount(true)
  });

  const LoginSection = (
    <div className="m-auto p-4 md:p-0 ">
      <h1 className="text-center text-lg">
      InvestDoor Login
      </h1>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 md:mb-4 md:w-2/3 mx-auto" onSubmit={handleLogin}>
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
            Email
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="username" 
            type="text" 
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}

          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" 
            id="password" 
            type="password" 
            placeholder="******************"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="text-red-500 text-xs italic">{errmsg}</p>
        </div>

        <div className="flex items-center justify-center mb-6">
          <div className="text-sm">
            Don't have an account?
            <a href="/signup" className="text-blue-500 hover:text-blue-700 ml-1">Sign up</a>
          </div>
          
        </div>

        <div className="flex items-center justify-center mb-6">
          <div className="text-sm">
            Forgot your password?
            <a href="/forgot-password" className="text-blue-500 hover:text-blue-700 ml-1">Reset</a>
          </div>
        </div>

        <div className="flex items-center justify-center mb-4">
          <div className="text-sm text-center">
            Upon using our services, you agree to our
            <a href="/terms-of-use" className="text-blue-500 hover:text-blue-700 ml-1" target="_blank">Terms of Use</a> and 
            <a href="/privacy-policy" className="text-blue-500 hover:text-blue-700 ml-1" target="_blank">Privacy Policy</a>.
          </div>
        </div>

        <div className="flex item-center justify-center  mb-6">
          {password.length > 0 ? captcha('normal') : ''}
        </div>


        <div className="flex items-center justify-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
            type="submit"
            disabled={!captchadone}
            onClick={handleLogin}
            >
            {isLoading ? 'Logging you in...' : 'Sign in'}
          </button>
        </div>

      </form>
    </div>
  );

  const ExtraInfoSection = (
    <div className="m-auto">
      <h1>
      Let us know more about you
      </h1>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ausBusinessNumber">
            Australian Business Number (required)
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="ausBusinessNumber" 
            type="text" 
            placeholder="ABN"
            value={ausBusinessNumber}
            onChange={(e) => setAusBusinessNumber(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
            Phone Number
          </label>
          <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            id="phoneNumber" 
            type="text" 
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <p className="text-red-500 text-xs italic">{errmsg}</p>
        </div>

        <div className="flex items-center justify-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
            type="button"
            onClick={initNewUser}
            disabled={true}
            >
            {isLoading ? 'Loading...' : 'Proceed'}
          </button>
        </div>

      </form>
    </div>
  );

  return (

    <div className="flex h-screen">
      {askInfo ? ExtraInfoSection : LoginSection}
    </div>
  )
}

export default Login;
