import { useState, useEffect } from "react";

import { API, graphqlOperation, Storage } from "aws-amplify";

import * as mutations from '../../graphql/mutations';

import ArticleForm from '../components/ArticleForm';

import { toast } from "react-toastify";
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { articleActions } from "../../redux/article";
import { RootState, AppDispatch } from '../../store';
import { unionBy, update } from "lodash-es";
import CircularLoader from "../../common/components/CircularLoader";
import { ThunkDispatch } from '@reduxjs/toolkit';
import { createFileLabel } from '../../helpers/parsers';


function ArticleEdit() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { aId } = useParams();

  //WIP clean this code later, use getById instead of straight assignment from selector
  const articleToEdit = useSelector((state: RootState) => 
    unionBy(state.article.articleList, state.article.adminSearchedArticleList, 'id').find((a:any) => a.id === aId)
  );

  const tagList = useSelector((state: RootState) => 
    state.article.tagList
  );

  const currentArticleTags = useSelector((state: RootState) => 
    state.article.currentArticleTags
  );

  const currentArticleTagConnections = useSelector((state: RootState) => 
    state.article.currentArticleTagConnections
  );

  const [article, setArticle] = useState<any>({
    ...articleToEdit,
    displayDate: new Date(articleToEdit.displayDate),
    tags: []
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFormField = (key: string, val: any) => {
    setArticle({
      ...article,
      [key]: val
    });
  }

  useEffect(() => {
    fetchConnectedTags();
  }, []);

  useEffect(() => {
    setArticle({
      ...article,
      tags: currentArticleTags.map((tag) => {
        return {
          value: tag.id,
          label: tag.name
        }
      })
    })
  }, [currentArticleTags]);

  const fetchConnectedTags = async () => {
    setLoading(true);
    await dispatch(articleActions.fetchTagsLinkedToArticle({articleId: aId}));
    setLoading(false);
  }

  const uploadNewPhotos = async () => {
    try {
      const newCoverPhoto = article.coverPhotoUrl instanceof File ? article.coverPhotoUrl : null;
      const newListPhoto = article.listPhotoUrl instanceof File ? article.listPhotoUrl: null;
      if(newCoverPhoto) {
        await Storage.put(newCoverPhoto.name, newCoverPhoto);
      }
      if(newListPhoto) {
        await Storage.put(newListPhoto.name, newListPhoto);
      }
    } catch (e) {
      console.log(e);
      toast.error("Error uploading new files.");
      throw Error("FileUploadError");
    }
  }

  const updateArticle = async () => {
    setLoading(true);
    try {
      let payload = {
        id: article.id,
        title: article.title,
        urlSlug: article.urlSlug,
        content: article.content,
        customAuthorName: article.customAuthorName,
        coverPhotoUrl: createFileLabel(article.coverPhotoUrl),
        listPhotoUrl: createFileLabel(article.listPhotoUrl),
        excerpt: article.excerpt,
        displayDate: article.displayDate.toISOString(),
      }

      setLoading(true);
      await uploadNewPhotos();
      await API.graphql(graphqlOperation(mutations.updateArticle, {input: payload}));
      dispatch(articleActions.updateArticleById({...article, ...payload}));
      //tags here
      //add new
      const existingTagIds = currentArticleTags.map((tag: any) => tag.id);

      for await (const tag of article.tags) {
        if(!existingTagIds.includes(tag.value)) {
          console.log(tag.value);

          await API.graphql(graphqlOperation(mutations.createArticleArticleTags, {
            input: {
              articleId: article.id,
              articleTagId: tag.value
            },
          }))
          console.log('waiting add tag');
        }
      }

      //delete
      const newTagIds = article.tags.map((tag: any) => tag.value);
      console.log(newTagIds);
      console.log(currentArticleTagConnections);
      for (const tagConnection of currentArticleTagConnections) {
        if(!newTagIds.includes(tagConnection.articleTagId)) {
          console.log('delete?');
          await API.graphql(graphqlOperation(mutations.deleteArticleArticleTags, {input: {id: tagConnection.id}}));
          console.log('waiting delete tag');
        }
      }

      toast.success("Successfully edited article.");


    } catch (e) {
      console.log(e);
      toast.error("Error updating article. Please check fields.");
    } finally {
      await fetchConnectedTags();
      setLoading(false);
    }
  }

  //WIP factor!
  const createFileLabel = (file: any) => {
    if(file instanceof File) {
      return file.name;
    } else if (file instanceof String || typeof file === "string") {
      return file;
    } else {
      return '';
    }
  }

  return (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
       <div className="flex flex-col">
       <div className="text-left font-bold">
        <Link to={'/admin/articles'} className="block italic font-light">
          {"<< back to list"}
        </Link>
      </div>
      {loading ? 
        <CircularLoader/> : 
        <ArticleForm article={article} tagList={tagList} onChangeFormField={onChangeFormField}/>
      }
      <button onClick={updateArticle} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4" disabled={loading}>
        SAVE ARTICLE
      </button>
      </div>

    </div>
  );
}

export default ArticleEdit;