import MarketResearchHero from '../../static/NewAssets/MarketResearchHero.jpg';
import Tick from '../../static/NewAssets/Tick.png';


function MarketResearch({scrollPercentage}: any) {
  return (
    <>
      <div className="relative snap-start snap-always h-[102vh] bottom-0 flex flex-col justify-center items-center bg-cover bg-no-repeat bg-top px-4 sm:px-8" style={{backgroundImage: `linear-gradient(to right top, #786FB066, #332B7899), url("${MarketResearchHero}")`}}>
        <div className="text-center block tracking-tighter md:mx-40">
          <h1 className="xl:text-9xl sm:text-7xl text-5xl font-robotoslab text-white xl:mb-6 mb-2">
            Market Research
          </h1>
          <h2 className="xl:text-5xl md:text-3xl text-xl font-robotoslab text-white mb-6">
            Gain valuable insights into the property market with our in-depth market research reports.
          </h2>
        </div>
      </div>
      <div className="bg-id-new-pink2 flex flex-col gap-12 rounded-t-3xl relative -mt-5 overflow-hidden pb-4 sm:pb-0">
        <div className="absolute opacity-50 left-1/3">
          <img src={Tick} alt="tick" className={scrollPercentage > 0.13 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <div className="z-10 text-center">
          <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-10 p-10 sm:p-40">
            We're working on it! Check back soon for our Market Research content.
          </h1>
        </div>
      </div>
    </>
  )
}

export default MarketResearch;