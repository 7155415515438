import React, {useState, useEffect, useRef} from 'react';
import {Auth} from 'aws-amplify';
import {signUp} from '../../api/amp-auth';
import {useNavigate} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux'
import { userActions } from '../../redux/user';
import SlidingToggleButton from '../components/HomePage/SlidingToggleButton';
import HouesRGB from "../../static/NewAssets/Houes RGB.png"
import HomeWorkOutlinedIcon from '@mui/icons-material/Home';

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errmsg, setErrMsg] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [captchadone,setCaptchaDone]= useState(false);

  const captchaDone = () => {
    setCaptchaDone(!captchadone)
  }
  
  const captcha = () => {
    return (
    <div>
      <ReCAPTCHA
      onChange={captchaDone}
      size="normal"
      theme="light"
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ?? ''} 
      />
    </div>
    )
  }

  const handleSignup = async(e: any) => {
    if(e) e.preventDefault();



    setErrMsg('');
    if(!isLoading) {
      
      setLoading(true);
      try {
        if(inputValidate()) {
          let user = await signUp(username, password, firstName, lastName);
          //if signup success, proceed to account verification
          console.log(user);
          dispatch(userActions.setCurrentUserName(username));
          navigate('/verify');
        }
        setLoading(false);
      }catch (e) {
        setLoading(false);
        setErrMsg('Error signing up. Please try again.');
      }
    }
  }

  const inputValidate = () => {
    if(password.length < 8 || passwordConfirm.length < 8) {
      setErrMsg('Password should be at least 8 characters.');
      return false;
    }
    if(password !== passwordConfirm) {
      setErrMsg('Passwords do not match.');
      return false;
    }
    if(firstName === '' || lastName === '') {
      setErrMsg('Name fields must not be empty.');
      return false;
    }
    if(username === '') {
      setErrMsg('Email field must not be empty.');
      return false;
    }
    if(!captchadone) {
      setErrMsg('Accomplish Captcha');
      return false;
    }
    return true;
    
  }

  return (
    <div className="flex flex-col md:flex-row w-screen md:h-screen bg-id-new-purple1">
      <div className="p-12 w-full text-white">

        <a href="/" className="text-white font-robotoslabbold text-2xl">
          <HomeWorkOutlinedIcon className="w-10 mb-10 cursor-pointer"/> 
        </a>

        <h1 className="text-3xl font-robotoslabbold pb-8">Why sign up?</h1>

        <h3 className="text-2xl font-robotoslabbold pb-2">Unlock Expert Resources</h3>
        <p className="text-xl font-museosans pb-10">Gain instant access to a suite of eBooks designed to guide you through property investments in Australia. From market trends to investment
          strategies, these resources are your roadmap to success.
        </p>

        <h3 className="text-2xl font-robotoslabbold pb-2">Learn the Basics</h3>
        <p className="text-xl font-museosans pb-10">Enroll in our educational courses that simplify property investing. Perfect for beginners, these courses will give you the confidence to 
          start your investment journey.
        </p>

        <h3 className="text-2xl font-robotoslabbold pb-2">Powerful Tools at Your Fingertips</h3>
        <p className="text-xl font-museosans pb-10">Utilize our suite of calculators and tools to make informed decisions. Whether it's rentvesting or mortgage calculations, we've got you covered.</p>

        <h3 className="text-2xl font-robotoslabbold pb-2">Stay Informed with Market Insights</h3>
        <p className="text-xl font-museosans pb-10">Receive monthly newsletters packed with the latest market insights, trends, and opportunities to keep you ahead of the curve.</p>

        <h3 className="text-2xl font-robotoslabbold pb-2">View Properties for Sale</h3>
        <p className="text-xl font-museosans pb-10">Get exclusive access to view properties for sale, helping you discover potential investment opportunities before anyone else.</p>

        <h3 className="text-2xl font-robotoslabbold pb-2">Research Locations with Key Metrics</h3>
        <p className="text-xl font-museosans pb-10">Use our location research tools to evaluate key metrics and determine the quality of potential properties. Make smarter, data-driven decisions with 
          confidence. 
        </p>

        <h3 className="text-2xl font-robotoslabbold pb-2">Get Professional Advice</h3>
        <p className="text-xl font-museosans pb-16">Have questions? As a member, you can send them directly to our professional property investment advisors. Expert guidance is just a message away.</p>
      </div>
      <div className={`p-12 w-full text-white bg-cover bg-no-repeat`} style={{backgroundImage: `url("${HouesRGB}")`}}>
        <h1 className="text-5xl font-robotoslab pb-4 px-10 pt-16 md:pt-40 text-center">Join our community and start your property investment journey today.</h1>
        <h1 className="text-5xl font-robotoslabbold pb-8 text-center">Sign up now</h1>

        <form className="px-10 md:px-40 pt-6 pb-8 mb-4">
          
          <div className="mb-4">
            <input className="shadow appearance-none border rounded-xl w-full py-5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="username" 
              type="text" 
              placeholder="Email*"
              value={username}
              onChange={(e) => setUsername(e.target.value)}

            />
          </div>

          <div className="mb-4">
            <input className="shadow appearance-none border rounded-xl w-full py-5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="firstName" 
              type="text" 
              placeholder="First Name*"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}

            />
          </div>

          <div className="mb-4">
            <input className="shadow appearance-none border rounded-xl w-full py-5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="lastName" 
              type="text" 
              placeholder="Last Name*"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="mb-2">
            <input className="shadow appearance-none border border-red-500 rounded-xl w-full py-5 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline" 
              id="password" 
              type="password" 
              placeholder="Password*"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-6">
            <input className="shadow appearance-none border border-red-500 rounded-xl w-full py-5 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline" 
              id="passwordConfirm" 
              type="password" 
              placeholder="Confirm Password*"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <p className="text-red-500 text-xs italic">{errmsg}</p>
          </div>

          <div className="flex items-center font-museosans justify-center mb-6">
            <div className="text-lg">
              Already have an account?  
              <a href="/login" className="font-museosanssemibold hover:text-gray-200 ml-1">Sign in</a>
            </div>
          </div>

          <div className="flex items-center font-museosans justify-center mb-6">
            <div className="text-lg">
              By signing up, you agree to our 
              <a href="/terms-of-use" className="font-museosanssemibold hover:text-gray-200 ml-1" target="_blank">Terms of Use</a> and 
              <a href="/privacy-policy" className="font-museosanssemibold hover:text-gray-200 ml-1" target="_blank">Privacy Policy</a>.
            </div>
          </div>
          <div className="flex items-center text-lg font-museosans justify-center mb-6">
            We will never share your information with third parties.
          </div>
          <div className="flex justify-center mb-6">
            {(password.length > 0) && (passwordConfirm.length > 0) ? captcha() : ''}
          </div>

          <div className="flex items-center justify-center">
            {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
              type="button"
              onClick={handleSignup}
              disabled={!captchadone}
              >
              {isLoading ? 'Loading...' : 'Sign up'}
            </button> */}
            <SlidingToggleButton
              buttonText="Sign up"
              isLoading={isLoading}
              func={handleSignup}
              disabled={!captchadone}
            />
          </div>

        </form>
      </div>
    </div>
  );

}

export default SignUp;