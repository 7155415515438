import { useState } from 'react';
import { format } from 'date-fns';
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { propertyTypeActions } from "../../redux/property_type";
import { propertyActions } from "../../redux/property";
import { RootState, AppDispatch } from '../../store';


function InquiryMessageDetail() {

  const { imId } = useParams();

  const inquiryMessageData:any = useSelector((state: RootState) => state.message.inquiryMessageList.find((m: any) => m.id === imId));
  console.log(inquiryMessageData.createdAt);
  
 
  
  return (
    <div className="h-full min-h-screen w-full">
      <div className="md:w-3/4 w-[80%] mx-auto mt-10 mb-0 text-left ">
        <Link to={'/admin/messages/inquiries'} className="block italic font-light">
          {"<< back to list"}
        </Link>
        <h4 className="font-bold text-2xl">General Inquiry </h4>
        <p className="text-sm">Message Details</p>
      </div>
      <div className="md:w-3/4 w-[80%] mx-auto my-5 text-left ">
        <div className="">
          <div className="block text-gray-700 text-lg font-bold bg-slate-200 p-1 rounded-t-md border border-slate-300">
            Sender details
          </div>
          <div className="pl-2 pr-1 py-1 rounded-b-md border border-slate-300">
            <div>
              <span className="mr-2 font-bold">Full Name:</span>
              <span>{`${inquiryMessageData.firstName} ${inquiryMessageData.lastName}`}</span>
            </div>
            <div>
              <span className="mr-2 font-bold">Email:</span>
              <span>{inquiryMessageData.email}</span>
            </div>
            <div>
              <span className="mr-2 font-bold">Phone number:</span>
              <span>{inquiryMessageData.phoneNumber}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-3/4 w-[80%]  mx-auto my-5 text-left ">
        <div className="mb-4">
          <div className="block text-gray-700 font-bold bg-slate-200 p-1 rounded-t-md border border-slate-300">
            Message body
          </div>
          <div>
            <div className="w-full h-fit p-2 min-h-[10rem] border border-slate-300 rounded-b-md">{inquiryMessageData.message}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InquiryMessageDetail;