import { useState } from "react";
import LoanCalc from "../components/Calculators/LoanCalc";
import MortgageCalc from "../components/Calculators/MortgageCalc";

const CALCULATORS = [
  'Loan',
  'Mortgage',
]

function FinanceCalculators() {
  const [currentCalculator, setCurrentCalculator] = useState(CALCULATORS[0])

  return (
    <div className="p-4 md:m-20">
      <h1 className="text-2xl font-bold mb-4">Finance Calculators</h1>
      <p className="mb-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nisl ac ultricies eleifend, nunc nunc
        consequat nunc, id lacinia nisl nunc ac nunc. Sed auctor, nunc id tincidunt tincidunt, nunc nunc tincidunt
        mauris, et lacinia nunc nunc a nunc. Sed vitae nunc auctor, aliquet nunc nec, aliquam nunc. Sed auctor, nunc
        id tincidunt tincidunt, nunc nunc tincidunt mauris, et lacinia nunc nunc a nunc. Sed vitae nunc auctor,
        aliquet nunc nec, aliquam nunc.
      </p>
      <select
        className="mb-4 p-2 rounded border"
        value={currentCalculator}
        onChange={(e) => setCurrentCalculator(e.target.value)}
      >
        {CALCULATORS.map((calculator) => (
          <option key={calculator} value={calculator}>
            {calculator}
          </option>
        ))}
      </select>
      <div className="flex flex-wrap">
        {currentCalculator === 'Loan' && <LoanCalc />}
        {currentCalculator === 'Mortgage' && <MortgageCalc />}
      </div>
    </div>
  );
}

export default FinanceCalculators;