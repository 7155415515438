import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import {unionBy} from 'lodash-es';
const PAGE_LIMIT = 20;

export interface User {
  id: string;
  firstName: string;
  lastName?: string;
  cognitoId?: string;
  email: string;
  phoneNumber?: string;
  address?: string;
  birthdate?: string;
  lastIp?: string;
  ausBusinessNumber?: string;
  accountTier?: string;
  propertyInterests?: any;
  [key: string]: any;
}

interface UserState {
  currentUser?: User | null;
  currentUserName: string;
  currentCognitoUser?: any;
  users: User[];
  isUserFetching: boolean;
}

const fetchCurrentUser = createAsyncThunk(
  'users/id',
  async (_, {rejectWithValue, getState}) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      var dbuser = null;
      if(!!cognitoUser && !!cognitoUser.attributes['custom:db_id']) {
        dbuser = await API.graphql(graphqlOperation(queries.getUser, {id: cognitoUser.attributes['custom:db_id']}));
      }
      return {dbuser, cognitoUser};
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchUserList = createAsyncThunk(
  'users/list',
  async(_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(graphqlOperation(queries.listUsers, {limit: PAGE_LIMIT}));
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
)



export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    currentCognitoUser: null,
    currentUserName: '',
    users: [], 
    isUserFetching: false
  } as UserState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentCognitoUser: (state, action) => {
      state.currentCognitoUser = action.payload;
    },
    setCurrentUserName: (state, action) => {
      state.currentUserName = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    updateUserById: (state, action) => {
      const idx = state.users.findIndex((u) => u.id === action.payload.id);
      state.users[idx] = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.pending, (state) => {
      state.isUserFetching = true;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      //store both cognito user AND api user, if available
      const {dbuser, cognitoUser} = action.payload;
      //@ts-ignore
      state.currentUser = dbuser.data.getUser;
      state.currentCognitoUser = cognitoUser;
      state.isUserFetching = false;
    });
    builder.addCase(fetchCurrentUser.rejected, (state) => {
      state.currentUser = null;
      state.currentCognitoUser = null;
      state.isUserFetching = false;
    });
    //TODO add case for loading and failed
    builder.addCase(fetchUserList.fulfilled, (state,action) => {
      state.users = [];
      state.users = action.payload.listUsers.items;
    });
  }
});


export const userActions = {
  fetchCurrentUser,
  fetchUserList,
  ...userSlice.actions
};

export default userSlice.reducer;