import { Route, Routes } from 'react-router-dom';
import HomeNew from './HomeNew';
import About from './About';
import Contact from './ContactUs';
import FooterNew from './FooterNew';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import PropertySearchHome from './PropertySearchHome';
import Courses from './Courses';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfUse from '../components/TermsOfUse';
import NotFound from '../../common/components/NotFound';
import UserProfile from './UserProfile';

import SMSF from './SMSF';
import NDIS from './NDIS';
import PropertyDetails from './PropertyDetails';
import Blog from './Blog';
import FinanceCalculators from './FinanceCalculators';
import ArticlePage from '../components/Blog/ArticlePage';
import AIAnalyst from './AIAnalyst';
import WhatWeDo from './WhatWeDo';
import ToolsAndCalculators from './ToolsAndCalculators';
import Education from './Education';
import MarketResearch from './MarketResearch';

//@ts-ignore
import {useScrollPercentage} from 'react-scroll-percentage';
import Navigation from '../components/Navigation';

function IndexNew() {
  const currentCognitoUser  = useSelector((state: RootState) => state.user.currentCognitoUser);

  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  });

  // const {
  //   currentUser,
  //   currentCognitoUser
  // } = useSelector((state: RootState) => {
  //   const { currentUser, currentCognitoUser } = state.user;
  //   return {
  //     currentUser, 
  //     currentCognitoUser
  //   };
  // });

  return (
    <div className="relative flex flex-col w-full min-h-screen" ref={ref}>
      <Navigation />

      {/* content WIP FACTOR OUT LATER */}
      <div className='z-0'>
        <Routes>
          <Route path="/" element={<HomeNew isLoggedIn={currentCognitoUser != null} scrollPercentage={percentage}/>} />
          <Route path="/what-we-do" element={<WhatWeDo isLoggedIn={currentCognitoUser != null} scrollPercentage={percentage} />} />
          <Route path="/about" element={<About/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/property/search" element={<PropertySearchHome/>} />
          <Route path="/property/:pId" element={<PropertyDetails />} />
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog/:aId" element={<ArticlePage/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/SMSF" element={<SMSF/>} />
          <Route path="/NDIS" element={<NDIS/>} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/finance-calculators" element={<FinanceCalculators />} />
          <Route path="/ai-analyst" element={<AIAnalyst />} />
          <Route path="/tools-and-calculators" element={<ToolsAndCalculators scrollPercentage={percentage}/>} />
          <Route path="/education" element={<Education />} />
          <Route path="/market-research" element={<MarketResearch />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      
      {/* footer */}
      <FooterNew />
    </div>
  );  
}

export default IndexNew;