import { useState } from "react";
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as mutations from '../../graphql/mutations';
import { RootState, AppDispatch } from '../../store';
import CircularLoader from "../../common/components/CircularLoader";
import { toast } from "react-toastify";
import { ACCOUNT_TIERS } from "../../constants/fields";
import { API, graphqlOperation } from "aws-amplify";
import { userActions } from "../../redux/user";


function UserEdit() {
  const dispatch = useDispatch();
  const { uId } = useParams();

  const userToEdit = useSelector((state: RootState) => state.user.users.find((u:any) => u.id === uId));

  const [user, setUser] = useState<any>(userToEdit);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      await API.graphql(graphqlOperation(mutations.updateUser, {input: {id: user.id, accountTier: user.accountTier}}));
      dispatch(userActions.updateUserById(user));
      toast.success("successfully edited user");
    } catch (e) {
      console.log(e);
      toast.error("Error updating user.")
    }
  }

  const onChangeFormField = (key: string, val: any) => {
    setUser({
      ...user,
      [key]: val
    })
  }

  return (
    

    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/users'} className="block italic font-light">
            {"<< back to list"}
          </Link>
          Edit user privileges
        </div>
        
        <form className="w-1/2 my-10 md:self-center text-left ">
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-gray-700 font-bold mb-2">
              First name
            </label>
            <input
              id="firstName"
              type="text"
              value={user.firstName}
              onChange={(e) => onChangeFormField('firstName', e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled
            />
          </div>    
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-gray-700 font-bold mb-2">
              Last name
            </label>
            <input
              id="lastName"
              type="text"
              value={user.lastName}
              onChange={(e) => onChangeFormField('lastName', e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled
            />
          </div>     

          <div className="mb-4">
            <label htmlFor="ABN" className="block text-gray-700 font-bold mb-2">
              ABN
            </label>
            <input
              id="lastName"
              type="text"
              value={user.ausBusinessNumber}
              onChange={(e) => onChangeFormField('ausBusinessNumber', e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              disabled
            />
          </div>    

          <div className="mb-4">
            <label htmlFor="listAvailability" className="block text-gray-700 font-bold mb-2">
              AccountTier
            </label>
            <select
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="accountTier"
              name="listAvailability"
              value={user.accountTier}
              onChange={(e) => onChangeFormField('accountTier', e.target.value)}
            >
              {
                ACCOUNT_TIERS.map((o, i) => <option value={o.value} key={i}>{o.label}</option>)
              }
            </select>
          </div>
        </form>

        <button onClick={handleSubmit} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4">
          SAVE USER
        </button>
      </div>
    </div>
  );
}

export default UserEdit;