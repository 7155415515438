import React, { useState, useEffect } from 'react';
import { API, graphqlOperation, Storage } from "aws-amplify";
import MDEditor from '@uiw/react-md-editor';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { faqActions } from '../../redux/faq';
import { ThunkDispatch } from '@reduxjs/toolkit';

import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";

import CircularLoader from '../../common/components/CircularLoader';

import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select'

import { createFileLabel } from '../../helpers/parsers';
import { set } from 'lodash-es';
import FaqForm from '../components/FaqForm';

interface PayloadFaq {
  title: string;
  content: string;
  excerpt: string;
  externalUrl: string;
  weight: number;
  page: string;
  [x: string | number | symbol]: unknown;
}

const initialFaq = {
  title: '',
  content: '',
  excerpt: '',
  externalUrl: '',
  weight: 1,
  page: 'NDIS'
}

function FaqAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [newFaq, setNewFaq] = useState<PayloadFaq>(initialFaq);
  const [loading, setLoading] = useState(false);

  const onChangeFormField = (key: string, val: any) => {
    setNewFaq({
      ...newFaq,
      [key]: val
    });
  }

  const createFaq = async () => {
    try {
      setLoading(true);
      let payload: PayloadFaq = {
        ...newFaq,
        weight: parseInt(newFaq.weight.toString())
      };
      if(!validateFaq(payload)) throw new Error('Invalid faq');
      await API.graphql(graphqlOperation(mutations.createFaq, {input: payload}));
      toast.success('Faq created successfully.');
      setNewFaq(initialFaq);
    } catch (e) {
      toast.error('Error creating faq.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const validateFaq = (payload: PayloadFaq) => {
    let flag = true;
    Object.keys(payload).forEach((k, i) => {
      if(flag = true) {
        if(k === 'excerpt' || k === 'externalUrl') return;
        if ((payload[k] instanceof String || typeof payload[k] === 'string') && !payload[k]) {
          flag = false;
        } else if (Number.isNaN(payload[k])) {
          flag = false;
        } else {
          flag = true;
        }
      }
    });
    return flag;
  }

  return (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/faqs'} className="block italic font-light">
            {"<< back to list"}
          </Link>
          Add Faq
        </div>
        <FaqForm 
          faq={newFaq}
          onChangeFormField={onChangeFormField}
        />
        <button onClick={createFaq} 
          className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg 
            hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4"
            disabled={loading}
            >
            SAVE FAQ
          </button>
      </div>
    </div>
  )
}

export default FaqAdd