import {useState, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Table from '../components/Table';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../../store';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import CircularLoader from '../../common/components/CircularLoader';
import { messageActions } from '../../redux/message';
import PropertyMessageDetail from '../components/PropertyMessageDetail';
import { format } from 'date-fns';
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify";



function PropertyMessages() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [loading, setLoading] = useState(false);


  const {
    propertyMessageList,
    currentUser
  } = useSelector((state: RootState) => {
    const {propertyMessageList} = state.message;
    const {currentUser} = state.user;
    return {
      propertyMessageList,
      currentUser
    }
  });

  useEffect( () => {
    getPropertyMessageList();

}, [] )
  
  const getPropertyMessageList = async() => {
    setLoading(true);
    dispatch(messageActions.setPropertyMessageList([]));
    await dispatch(messageActions.fetchPropertyMessageList());
    setLoading(false);
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'user',
      defaultCanSort: true,
      Cell: (item: any) => {
        return <div>{`${item.row.original.user.firstName} ${item.row.original.user.lastName}`}</div>
      }
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      defaultCanSort: true,
      Cell: (item: any) => {
        return (
          <div className="text-center">{format(new Date(item.row.original.createdAt),'yyyy/MM/dd kk:mm:ss')}</div>)
         
    }
    },
    {
      Header: 'For Property',
      accessor: 'property',
      defaultCanSort: true,
      Cell: (item: any) => {
        return <div>{`${item.row.original.property.name}`}</div>
      }
    },
    {
      Header: 'Read by you',
      accessor: 'readByUserIds',
      defaultCanSort: true,
      Cell: (item: any) => {
        return <div>{isPropertyMessageRead(item.row.original) ? "Yes" : "No"}</div>
      }
    },
    {
      Header: '',
      accessor: 'id',
      defaultCanSort: false,
      Cell: (item: any) => {
        return <button className="text-blue-500 underline hover:text-blue-700" onClick={() => markPropertyMessageAsRead(item.row.original)}>
          read
        </button>
      }
    }
  ]

  const markPropertyMessageAsRead = async (propertyMessage: any) => {
    navigate(`/admin/messages/properties/${propertyMessage.id}`);
    if (isPropertyMessageRead(propertyMessage)) {
      return;
    }
    const newReadByUserIds = [
      ...(propertyMessage.readByUserIds ?? []),
      currentUser?.cognitoId
    ];

    //update the local state
    dispatch(messageActions.updatePropertyMessageById({id: propertyMessage.id, readByUserIds: newReadByUserIds}));

    API.graphql(graphqlOperation(mutations.updatePropertyMessage, {input: {id: propertyMessage.id, 
      readByUserIds: 
        newReadByUserIds
    }}));    
  }

  const isPropertyMessageRead = (propertyMessage: any) => {
    return propertyMessage.readByUserIds?.includes(currentUser?.cognitoId);
  }



  const PropertyMessageList = (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">
            Property Message Inquiries
          </h1>
          <p className="self-start py-4">
            These are inquiries regarding properties posted in this website.
          </p>
          <div className="row-auto self-start">
            {/* <input type="text" value={nameSearchString} onChange={(e) => setNameSearchString(e.target.value)}/>
            <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={propertySearchByNameAndState}>search</button>
            <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={clearProperties}>clear</button> */}
          </div>

          <div className="row-auto self-start mb-3">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getPropertyMessageList}>load list</button>
          </div>
          <div className=" overflow-auto">
            {!loading ? (
              <Table columns={columns} data={propertyMessageList} />
            ) : (
              <CircularLoader />
            )}
          </div>

        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Routes>
        <Route path="" element={PropertyMessageList} />
        <Route path=":pmId" element={<PropertyMessageDetail/>} />
      </Routes>
    </div>
  )
}

export default PropertyMessages;