import { Amplify } from "aws-amplify";
import { file } from "bun";

const CLOUDFRONTURL_DEV = 'https://dvzh2qmpskvye.cloudfront.net/public';
const CLOUDFRONTURL_PROD = 'https://d2in3gbkovys0e.cloudfront.net/public';


export const getAmplifyEnv = () => {
  try {
    return Amplify.API._options.aws_user_files_s3_bucket.split('-')[1];
  } catch (e) {
    //use prod by default
    return 'prod';
  }
}

export const createFileLink = (file: any) => {
  const baseFileUrl = getAmplifyEnv() === 'prod' ? CLOUDFRONTURL_PROD : CLOUDFRONTURL_DEV;
  if(file instanceof File) {
    return URL.createObjectURL(file);
  } else if (file instanceof String || typeof file === "string") {
    return `${baseFileUrl}/${file}`;
  } else {
    return '#';
  }
}