import React from "react";

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder: string;
  type: string;
  id: string;
  setState: any;
  className1: string;
  className2?: string;
  value?: any;
}

const InputValue = (props: Props) => {

  return (
    <div className="">
      <label className={`font-bold + ${props.className1}`} htmlFor={props.id}>{props.label}</label><br/>
      <input value={props.value} className={`rounded-md + ${props.className2}`} type={props.type} placeholder={props.placeholder} id={props.id} onChange={props.handleChange} />
    </div>
  );
};
export default InputValue;