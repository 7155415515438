import {useState, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import { toast } from "react-toastify";
import CircularLoader from '../../common/components/CircularLoader';
import {useDispatch, useSelector} from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { faqActions } from '../../redux/faq';
import { set } from 'lodash-es';
import FaqAdd from './FaqAdd';
import FaqEdit from './FaqEdit';


function Faqs() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [faqIdForDeletion, setFaqIdForDeletion] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteFaq = async(faqId: any) => {
    try {
      await API.graphql(graphqlOperation(mutations.deleteFaq, {input: {id: faqId}}));
      setModalVisible(false);
      setFaqIdForDeletion('');
      toast.success('Faq deleted successfully.');
    } catch (e) {
      toast.error('Error deleting faq.');
      console.log(e);
    }
  }

  const faqList = useSelector((state: RootState) => state.faq.faqs);

  useEffect(() => {
    getFaqList();
  }, []);

  const getFaqList = async () => {
    if(!loading) {
      setLoading(true);
      try {
        await dispatch(faqActions.fetchFaqList());
      } catch (e) {
        toast.error('Error fetching faq list.');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }
  
  const confirmDeleteFaq = (faqId: any) => {
    setModalVisible(true);
    setFaqIdForDeletion(faqId);
  }

  const onClickAdd = () => {
    navigate('/admin/faqs/add');
  }

  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({row}: any) => {
        return (
          <Link to={`/admin/faqs/${row.original.id}`}>
            {row.original.title}
          </Link>
        )
      }
    },
    {
      Header: 'Page',
      accessor: 'page',
    },
    { 
      Header: 'Weight',
      accessor: 'weight',
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button onClick={() => confirmDeleteFaq(item.value)} >delete</button>
      }
    }
  ]

  const FaqList = (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">
            Faqs
          </h1>

          <p className="self-start py-4">
            Faqs for display on specific pages.
          </p>

          <div className="row-auto self-start">
            {/* TODO Faq page type checkboxes later here */}

          </div>

          <div className="row-auto self-start">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getFaqList}>load list</button>
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={onClickAdd}>add faq</button>

          </div>

          <div className=" py-2">
            {!loading ? (
              <Table columns={columns} data={faqList} />
            ) : (
              <CircularLoader />
            )}
          </div>

        </div>
      </div>
      <ConfirmModal modalVisible={modalVisible} 
        onOk={() => deleteFaq(faqIdForDeletion)} 
        onCancel={() => setModalVisible(false)}
        message={"Are you sure you want to delete this row? This is irreversible."}
      />
    </div>
  )

  return (
    <div>
      <Routes>
        <Route path="" element={FaqList} />
        <Route path="add" element={<FaqAdd />} />
        <Route path=":fId" element={<FaqEdit />} />
      </Routes>
    </div>
  )

}

export default Faqs;