import { useState, useEffect } from 'react';
import HeroTemplate from '../components/HeroTemplate';
import BlogImg from '../../static/HeroText/blog_header-min.png';
import BlogHeaderDesc from '../components/Blog/BlogHeaderDesc';
import BlogArticles from '../components/Blog/BlogArticles';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { articleActions } from '../../redux/article';
import ArticleBanner from '../components/Blog/ArticleBanner';
import { useNavigate } from 'react-router-dom';

const title = "News and Articles"

function Blog() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    userArticleList,
    tagList,
    currentSelectedTagId,
    prevSelectedTagId
  } = useSelector((state: RootState) => {
    const {userArticleList, tagList, currentSelectedTagId, prevSelectedTagId} = state.article;
    return {
      userArticleList,
      tagList,
      currentSelectedTagId,
      prevSelectedTagId
    };
  });

  const setCurrentSelectedTagId = (id: string) => {
    dispatch(articleActions.setCurrentSelectedTagId(id));
  }

  useEffect(() => {
    dispatch(articleActions.fetchArticleTagList());
  }, []);

  const getArticles = async () => {
    if (userArticleList.length === 0 || currentSelectedTagId !== prevSelectedTagId) setLoading(true);
    await dispatch(articleActions.fetchArticleListUser());
    setLoading(false);
  }

  const getArticlesByTag = async () => {
    if (userArticleList.length === 0 || currentSelectedTagId !== prevSelectedTagId) setLoading(true);
    await dispatch(articleActions.fetchArticlesWithTag({articleTagId: currentSelectedTagId}));
    setLoading(false);
  }

  const tagNavigate = (id: string) => {
    setCurrentSelectedTagId(id);
    navigate('/blog');
  }

  useEffect(() => {
    if (currentSelectedTagId === 'all') {
      getArticles();
    } else {
      getArticlesByTag();
    }
  }, [currentSelectedTagId]);

  return (
    <>
      <ArticleBanner/>
        <BlogArticles 
          tags={[{name: 'All', id: 'all'}, ...tagList]} 
          articles={userArticleList ?? []} 
          {...{currentSelectedTagId, setCurrentSelectedTagId, loading, tagNavigate}}
        />
    </>
  );
}

export default Blog;