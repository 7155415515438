
import { useState } from "react";

import { API, graphqlOperation, Storage } from "aws-amplify";

import * as mutations from '../../graphql/mutations';

import PropertyForm from '../components/PropertyForm';

import { toast } from "react-toastify";
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { propertyTypeActions } from "../../redux/property_type";
import { propertyActions } from "../../redux/property";
import { RootState, AppDispatch } from '../../store';
import { unionBy } from "lodash-es";
import { SerializedProperty } from '../../redux/property';


// WIP Factor out interfaces later

function PropertyEdit() {
  const dispatch = useDispatch();

  const { pId } = useParams();

  //WIP Factor out serialization code later 
  //USE this serializer
  const serializeProperty = (property: any) => {
    return {
      id: property.id,
      pid: property.pid,
      name: property.name,
      price: property.price.toString(),
      address: property.address,
      state: property.state,
      primaryImageUrl: property.primaryImageUrl,
      imageUrl: property.imageUrl,
      brochureUrl: property.brochureUrl,
      description: property.description,
      propertyStatus: property.propertyStatus,
      propertyPropertyTypeId: property.propertyPropertyTypeId,
      propertyAddedById: property.propertyAddedById,
      listAvailability: property.listAvailability,
      bedrooms: property.bedrooms.toString(),
      bathrooms: property.bathrooms.toString(),
      carspaces: property.carspaces.toString(),
      rentalIncome: property.rentalIncome?.toString(),
      minimumRentalYield: property.minimumRentalYield.toString(),
      contractType: property.contractType,
      coordinateX: property.coordinateX.toString(),
      coordinateY: property.coordinateY.toString()
    }
  }

  //WIP Factpr this out too

  const deserializeProperty = (property: any) => {
    return {
      ...property,
      price: parseFloat(property.price),
      primaryImageUrl: createFileLabel(property.primaryImageUrl),
      imageUrl: property.imageUrl.map((img: any) => {
        return createFileLabel(img);
      }),
      brochureUrl: property.brochureUrl.map((pdf: any) => {
        return createFileLabel(pdf);
      }),
      bedrooms: parseInt(property.bedrooms),
      bathrooms: parseInt(property.bathrooms),
      carspaces: parseInt(property.carspaces),
      rentalIncome: parseFloat(property.rentalIncome),
      minimumRentalYield: parseFloat(property.minimumRentalYield),
      coordinateX: parseFloat(property.coordinateX),
      coordinateY: parseFloat(property.coordinateY)
    }
  }

  //WIP clean this code later, use getById instead of straight assignment from selector
  const propertyToEdit = useSelector((state: RootState) => 
    unionBy(state.property.propertyList, state.property.searchedPropertyList, 'id').find((p:any) => p.id === pId)
  );
  
  const {
    propertyTypeList,
    currentUser
  } = useSelector((state: RootState) => {
    const { propertyTypeList } = state.propertyType;
    const {currentUser} = state.user;
    return {
      propertyTypeList,
      currentUser
    };
  })


  const [property, setProperty] = useState<any>(serializeProperty(propertyToEdit));
  const [loading, setLoading] = useState<boolean>(false);

  //WIP clean up formfields, pass setter func and state instead and factor out
  const onChangeFormField = (key: string, val: any) => {
    setProperty({
      ...property,
      [key]: val
    });
  }

  
  //WIP factor!
  const createFileLabel = (file: any) => {
    if(file instanceof File) {
      return file.name;
    } else if (file instanceof String || typeof file === "string") {
      return file;
    } else {
      return '';
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    updateProperty();
  }

  //WIP Api calls for add and edit should also be in reducers
  const updateProperty = async () => {
    try {
      
      console.log(property);
      let payload = deserializeProperty(property);
      if (!validateProperty(payload)) throw "Field Error";
      setLoading(true);
      await uploadFiles();
      //gql
      console.log(payload);
      await API.graphql(graphqlOperation(mutations.updateProperty, {input: payload}));
      dispatch(propertyActions.updatePropertyById({...propertyToEdit, payload}));
      toast.success("Successfully edited property");
      setLoading(false);
      
    } catch (e) {
      console.log(e);
      toast.error("Error Updating Property. Please check fields.");
    }
  }
  const uploadFiles = async () => {
    try {
      const newImages = property.imageUrl.filter((img: any) => img instanceof File);
      const newPdfs = property.brochureUrl.filter((pdf: any) => pdf instanceof File);
      const newPrimaryImage = property.primaryImageUrl instanceof File ? [property.primaryImageUrl] : [];
      const toUpload = [...newImages, ...newPdfs, ...newPrimaryImage] as File[];
      await Promise.all(toUpload.map((file: any) => Storage.put(file.name, file).then(async (result) => {
        console.log(result); //{key: 'myfilename.png'} <-- save this to the db!
      })));
    } catch (e) {
      console.log(e);
      toast.error('Error uploading');
      throw "Upload Error";
    }
  }

  //WIP reuse refactor this
  const validateProperty = (payload: any) => {
    let flag = true;
    Object.keys(payload).forEach((k, i) => {
      if(flag = true) {
        if ((payload[k] instanceof String || typeof payload[k] === 'string') && !payload[k]) {
          flag = false;
        } else if (Number.isNaN(payload[k])) {
          flag = false;
        } else {
          flag = true;
        }
      }
    });
    return flag;
  }

  //WIP Factor
  const onClearFiles = (e: any, key: string) => {
    e.preventDefault();
    setProperty({
      ...property,
      [key]: []
    });
  }

  //WIP Factor
  const onDeleteSpecificFile = (e: any,key: string, index: number) => {
    e.preventDefault();
    let tempFileArr = property[key as keyof SerializedProperty] as any[];
    tempFileArr.splice(index, 1);
    onChangeFormField(key, tempFileArr);
  }

  //TODO add loader here if still loading
  return (
    <div className=" min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/properties'} className="block italic font-light">
            {"<< back to list"}
          </Link>
          Edit Property
        </div>
        <PropertyForm 
          property={property}
          propertyTypeList={propertyTypeList}
          onChangeFormField={onChangeFormField}
          onClearFiles={onClearFiles}
          onDeleteSpecificFile={onDeleteSpecificFile}
        />
        <button onClick={handleSubmit} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4">
              SAVE PROPERTY
        </button>
      </div>
    </div>
  );
}

export default PropertyEdit;