
export const STATES = [
  {
    label: "Western Australia",
    value: "WA"
  },
  {
    label: "Victoria",
    value: "VIC"
  },
  {
    label: "Tasmania",
    value: "TAS"
  },
  {
    label: "South Australia",
    value: "SA"
  },
  {
    label: "Queensland",
    value: "QLD"
  },
  {
    label: "Northern Territory",
    value: "NT"
  },
  {
    label: "New South Wales",
    value: "NSW"
  },
  {
    label: "Australian Capital Territory",
    value: "ACT"
  },
]

export const CONTRACT_TYPES = [
  {
    label: "One Part",
    value: "ONE_PART"
  },
  {
    label: "Two Part",
    value: "TWO_PART"
  }
]

export const PROPERTY_AVAILABILITY = [
  {
    label: "Available",
    value: "AVAILABLE"
  },
  {
    label: "Pending",
    value: "PENDING"
  },
  {
    label: "Sold",
    value: "SOLD"
  },
  {
    label: "Hidden",
    value: "HIDDEN"
  }
]

export const PROPERTY_STATUSES = [
  {
    label: "Registered",
    value: "REGISTERED"
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Not Titled",
    value: "NOT_TITLED"
  }
]

export const ACCOUNT_TIERS = [
  {
    label: "Administrator",
    value: "ADMIN"
  },
  {
    label: "New Account",
    value: "NEW_ACCOUNT"
  },
  {
    label: "Verified",
    value: "VERIFIED_ACCOUNT"
  }
]

export const FAQ_PAGE_TYPES = [
  {
    label: "NDIS",
    value: "NDIS"
  },
  {
    label: "SMSF",
    value: "SMSF"
  },
  {
    label: "Home",
    value: "HOME"
  },
  {
    label: "Other",
    value: "OTHER"
  }
]