
import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {confirmSignUp, resendSignUp} from '../../api/amp-auth';
import {Navigate, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';


function Verify() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [errmsg, setErrMsg] = useState('');
  const [vCode, setVCode] = useState('');

  const {currentUserName} = useSelector((state: any) => {
    const {currentUserName} = state.user;
    return {
      currentUserName
    };
  })

  const handleVerify = async(e: any) => {
    e.preventDefault();
    try {
      //console.log('here');
      //let user = await Auth.currentAuthenticatedUser();
      //console.log(user);
      //@ts-ignore
      let data = await confirmSignUp(currentUserName, vCode);
      //let data = confirmSignUp(user.username, vCode);
      setVCode('');

      navigate('/login');
    } catch (e) {
      console.log(e);
      setErrMsg('Incorrect verification code.');
    }
  }

  const handleResend = async(e: any) => {
    e.preventDefault();
    try {
      let user = await Auth.currentAuthenticatedUser();
      let data = await resendSignUp(user.attributes.email);
      setErrMsg('Verification successfully resent.');
    } catch (e) {
      setErrMsg('Error resending verification code. Please try again.');
    }
  }

  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <h1>
          A verification code was sent to your registered e-mail address. Enter it here.
        </h1>
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Verification Code
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="vcode" 
              type="text" 
              placeholder="Verification Code"
              value={vCode}
              onChange={(e) => setVCode(e.target.value)}
            />
            <p className="text-red-500 text-xs italic">{errmsg}</p>
          </div>

          <div className="flex items-center justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
              type="button"
              onClick={handleVerify}
              disabled={isLoading}
              >
              {isLoading ? 'Loading...' : 'Verify Account'}
            </button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default Verify;