import { createFileLink } from "../../../helpers/api";
import { Link } from "react-router-dom";
import noPicture from "../../../static/noPicture.jpg";
import { parseStatus, parseAvailability, dollarParse } from "../../../helpers/parsers";

{/* route to property data shall be updated once available */}

const PropertySearchCard = ({
  image,
  title,
  price,
  propertyType,
  fundsRequirement,
  rentalYield,
  bedroom,
  bathroom,
  carSpace,
  status,
  availability,
  state,
  id
}:
any) => {
  return (
    <Link to={`/property/${id}`} className='flex justify-center'>
      <div className="relative font-inter w-[100%] md:max-w-xs md:min-w-[20rem] bg-white rounded-lg overflow-hidden shadow-sm  md:hover:shadow-md ">
        <div className=" bg-black  `w-full h-52 md:h-72"></div>
        <img src={image ? createFileLink(image) : noPicture} alt={title} className={`absolute top-0 opacity-[0.9] hover:opacity-100 w-full h-52 md:h-72 object-cover hover:${image.length>0 ? 'scale-[1.05]' : 'scale-0'} hover:duration-200`}/>
        
        <div className="m-4 text-sm md:text-md">
          <span className="block truncate">{propertyType}</span>
          <span className="block truncate font-epilogue text-xl font-semibold text-black">{title}</span>
          <span className="block truncate">Price: {dollarParse.format(price)}</span>
          {/* Funds required to be calculated later */}
          {/* <span className="block truncate">Funds Required: TBD</span> */}
          <span className="block truncate">Minimum Rental Yield: {`${rentalYield}%`}</span>
          <span className="block truncate">Layout: {bedroom} Beds, {bathroom} Bath, {carSpace} Car</span>
          <span className="block truncate">Registration: {parseStatus(status)}</span>
          <span className="block truncate">Availability: {parseAvailability(availability)}</span>
        </div>
      </div>
      
    </Link>
  );
}

export default PropertySearchCard;