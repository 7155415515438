import MDEditor from '@uiw/react-md-editor';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import SingleImagePicker from './SingleImagePicker';

function ArticleForm({onChangeFormField, article, tagList}: any) {
  return (
    <div className="container w-full">
        <div className="my-10">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2 text-xl">
            Title
          </label>
          <input
            id="title"
            type="text"
            value={article.title}
            onChange={(e) => onChangeFormField('title', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="my-10">
          <label htmlFor="urlSlug" className="block text-gray-700 font-bold mb-2 text-xl">
            URL Slug
          </label>
          <input
            id="urlSlug"
            type="text"
            value={article.urlSlug}
            onChange={(e) => onChangeFormField('urlSlug', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="my-10">
          <label htmlFor="excerpt" className="block text-gray-700 font-bold mb-2 text-xl">
            Excerpt
          </label>
          <input
            id="excerpt"
            type="text"
            value={article.excerpt}
            onChange={(e) => onChangeFormField('excerpt', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="my-10">
          <label htmlFor="customAuthorName" className="block text-gray-700 font-bold mb-2 text-xl">
            Custom Author Name(s) (overrides default author)
          </label>
          <input
            id="customAuthorName"
            type="text"
            value={article.customAuthorName}
            onChange={(e) => onChangeFormField('customAuthorName', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="my-10">
          <label htmlFor="displayDate" className="block text-gray-700 font-bold mb-2 text-xl">
            Display date
          </label>
        </div>

        <DatePicker selected={article.displayDate} onChange={(date) => onChangeFormField('displayDate', date)}/>

        <div className="my-10">
          <label htmlFor="tags" className="block text-gray-700 font-bold mb-2 text-xl">
            Tags
          </label>
        </div>

        <Select 
          options={tagList.map((t: any) => {
            return {
              value: t.id, label: t.name
            }
          })} 
          isMulti
          onChange={(vals) => {
            onChangeFormField('tags', vals);
          }}
          value={article.tags}
        />

        <div className="my-10">
          <label htmlFor="coverPhoto" className="block text-gray-700 font-bold mb-2 text-xl">
            Cover Photo
          </label>
        </div>

        <SingleImagePicker obj={article} objKey={'coverPhotoUrl'} onChangeFormField={onChangeFormField} />
        
        <div className="my-10">
          <label htmlFor="listPhoto" className="block text-gray-700 font-bold mb-2 text-xl">
            Listing Photo
          </label>
        </div>

        <SingleImagePicker obj={article} objKey={'listPhotoUrl'} onChangeFormField={onChangeFormField} />

        <label htmlFor="content" className="block text-gray-700 font-bold mb-2 text-xl">
          Content
        </label>
        <MDEditor
          value={article.content}
          onChange={(val: any) => onChangeFormField('content', val)}
          className="text-white"
          style={{backgroundColor: 'black', color: 'white'}}
        />
        {/* TODO: FOR SOME REASON, some styles from tailwind doesn't work, resolve this later on */}

        <label htmlFor="content" className="block text-gray-700 font-bold mb-2">
          Preview
        </label>

        <MDEditor.Markdown source={article.content}
          style={{ 
            backgroundColor: 'white', 
            color: 'black', 
            whiteSpace: 'pre-wrap', 
            display: 'flex', 
            flexDirection: 'column'
          }}
        />
      </div>
  );

}

export default ArticleForm;