
function VisLangBullets({side, bottomOffset, width, animVisible}: any) {
  const rectClass = side === 'right' ? 
    'right-0 rounded-l-full bg-gradient-to-r from-id-new-purple3 via-id-new-purple2 via-60% to-white' :
    'left-0 rounded-r-full bg-gradient-to-l from-white via-id-new-purple2 via-60% to-id-new-purple3';
  const circClass = side === 'right' ? 'left-0' : 'right-0';
  const animClassFadeIn = side === 'right' ? 'animate-fade-left' : 'animate-fade-right';
  const animClassFadeOut = "animate-fade animate-ease-linear animate-reverse";
  return (
    <div className={`invisible sm:visible absolute h-24 ${bottomOffset} ${width} ${rectClass} ${animVisible ? animClassFadeIn : animClassFadeOut}`}>
      <div className={`absolute w-24 h-24 bg-id-new-pink2 rounded-full ${circClass}`} />
    </div>
  )
} 

export default VisLangBullets;