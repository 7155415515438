import stock1 from '../../static/stock1.png';
import { useNavigate } from 'react-router-dom';

const Cta = () => {
  const navigate = useNavigate();

  const onCtaClick = () => {
    navigate("/contact");
  }

  return (
    <div className="md:full md:my-24 my-10 container mx-auto flex justify-center font-epilogue  overflow-hidden">
      <div className="w-[90%] bg-id-blueShade md:rounded-xl">
        <div className="flex flex-col md:h-auto md:w-auto md:flex-row  ">
            <div className="basis-1/2 flex flex-col gap-4 mx-4 my-4 md:gap-8 md:mx-8 md:p-10 md:my-11 md:text-left text-white">
              <div className="text-2xl md:text-[40px] font-bold">Let's work together!</div>
              <div className="text-sm leading-6 md:text-lg">Our exclusive portal offers advisors and their clients a streamlined approach to finding quality investment properties. Sign up now for personalized advice and access to top-notch properties, and start achieving your investment goals with ease.</div>
              {/* hide for now while buttons are not yet working */}
              <button className="mx-auto w-full py-2 px-4 rounded-md text-center bg-id-blue3
              hover:bg-id-blue2 text-sm md:mx-0 md:w-1/4 md:text-lg md:py-4 md:min-w-fit" onClick={onCtaClick}> 
                Contact us
              </button>
            </div>
            <div className="basis-1/2 bg-home-hero-blue align-center justify-center" style={{backgroundImage: `url(${stock1})`}}>
              <div className="h-full w-full backdrop-blur-lg flex">
                <img src={stock1} alt="" className="m-auto block w-full object-cover self-center" />
              </div>
            </div>
        </div>
        </div>  
    </div>
  );
}
export default Cta;