import Accordion from '../../../common/components/Accordion/Accordion';
import {Link} from 'react-router-dom';

interface policylist {
    policy: any,
    link: any,
  }

const policy_list: Array<policylist> = [
    {
        policy: "Terms of Use",
        link: "terms-of-use"
    },
    {
        policy: "Privacy Policy",
        link: "privacy-policy"
    }
  ]



const PolicyList = () => {

  
  return (
    <div>
      <div className="flex flex-row justify-center my-4 w-full text-xs text-gray-200 space-x-3">
       {
        policy_list.map((policy) => (
            <Link className="hover:underline font-robotoslab font-medium" to={policy.link} >{policy.policy}</Link>
        )
        )
        }
      </div>
    </div>
  )
}

export default PolicyList;