import {useState, useEffect} from 'react';
import SidebarItem from '../components/SidebarItem';
import {Route, Routes} from 'react-router-dom';
import {Auth, Amplify} from 'aws-amplify';
import id_logo from '../../static/investdoor_logoname_new.png';
import Properties from './Properties';
import PropertyTypes from './PropertyTypes';
import Users from './Users';
import Dashboard from './Dashboard';
import PropertyMessages from './PropertyMessages';
import InquiryMessages from './InquiryMessages';
import Articles from './Articles';
import Tags from './Tags';
import Faqs from './Faqs';
import PropertyTypeAdd from './PropertyTypeAdd';
import { useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { current } from '@reduxjs/toolkit';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArticleIcon from '@mui/icons-material/Article';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { QuestionMark } from '@mui/icons-material';
import TagIcon from '@mui/icons-material/Tag';
import TocIcon from '@mui/icons-material/Toc';
import {Link} from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';


interface MenuItem {
  path: string,
  label: any,
  icon: any,
}

const menu_items_array: Array<MenuItem> = [
  {
    path: '/admin/dashboard',
    label: 'Dashboard',
    icon: <SpaceDashboardOutlinedIcon/>
  },
  {
    path: '/admin/users',
    label:'Users',
    icon: <GroupOutlinedIcon/>
  },
  {
    path: '/admin/properties',
    label: 'Properties',
    icon: <HomeWorkOutlinedIcon/>
  },
  {
    path: '/admin/property_types',
    label: 'Property Types',
    icon: <RoomPreferencesOutlinedIcon/>
  },
  {
    path: '/admin/messages/properties',
    label: 'Property Inquiries',
    icon: <QuizOutlinedIcon/>
  },
  {
    path: '/admin/messages/inquiries',
    label: 'General Inquiries',
    icon: <QuestionAnswerOutlinedIcon/>
  },
  {
    path: '/admin/articles',
    label: 'Articles',
    icon: <ArticleIcon/>
  },
  {
    path: '/admin/tags',
    label: 'Article Tags',
    icon: <TagIcon/>
  },
  {
    path: '/admin/faqs',
    label: 'FAQs',
    icon: <QuestionMark/>
  }
];

function AdminHome() {
  const [current_path, setCurrentPath] = useState(window.location.pathname);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [open,setOpen] = useState(true);

  const SideBarClick = () => {
    setOpen(!open)
  }

  const currentCognitoUser = useSelector((state: RootState) => state.user.currentCognitoUser);

  const clickItem = (e: any, to: string) => {
    setCurrentPath(to);
  }

  useEffect(() => {
    if(!!currentCognitoUser && !loggedIn) {
      setLoggedIn(true);
      if(currentCognitoUser.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')) {
        setAdmin(true);
      }
    }
  }, [currentCognitoUser]);

  //return nothing, don't display anything to the user if they're not an admin
  if(!loggedIn || !isAdmin) return (<></>);
  
    return (
      <div className="border border-none">

        <div className="flex flex-row ">

          <div  className={`flex flex-col  border bg-white  drop-shadow-sm sticky top-0 md:min-h-screen md:h-max h-9 duration-300 ${open! ? 'w-[17rem] h-screen cursor-w-resize bg-slate-100':' cursor-e-resize w-9  bg-slate-100 md:w-[4rem]' }`}>

            <div className={""}>
              <div>
               <button onClick={SideBarClick} className={` flex mx-auto content-center duration-300 md:visible  invisible ${open! ? 'rotate-180  ':''}`}><KeyboardArrowRightIcon/></button>
               <button onClick={SideBarClick} className={` flex mx-auto absolute content-center duration-300 md:hidden overflow-hidden transformation-all visible ${open! ? ' top-0 ':' top-0 '}`}>{open! ? <CloseIcon fontSize="large"/> : <TocIcon fontSize="large"/> }</button>
              </div>
              <Link to='/admin' >
                <img src={id_logo} alt="oh logo" width="50%" className={`mx-auto my-8 ${open! ? 'visible':'hidden md:visible'}`} />
              </Link>
              <div className="mt-5 pt-5 flex flex-col content-center ">
                {menu_items_array.map((m, i) => 
                  
                  <SidebarItem key={i} icon={m.icon} label={m.label} onClick={clickItem} to={m.path} active={current_path === m.path} openValue={open} setValue={setOpen} onChange={SideBarClick}/>
                )}
              </div>
            </div>
            

            
          </div>  
          <div className="flex flex-col bg-home-hero-blue w-full overflow-auto transition-all duration-500" onClick={()=>setOpen(false)}>
            <Routes>
              <Route path="dashboard" element={<Dashboard />}/>
              <Route path="users/*" element={<Users />}/>
              <Route path="properties/*" element={<Properties />}/>
              <Route path="property_types/*" element={<PropertyTypes />}/>
              <Route path="messages/properties/*" element={<PropertyMessages/>} />
              <Route path="messages/inquiries/*" element={<InquiryMessages/>} />
              <Route path="articles/*" element={<Articles/>} />
              <Route path="tags/*" element={<Tags/>} />
              <Route path="faqs/*" element={<Faqs/>} />
            </Routes>
          </div>
        </div>
      </div>
    );


}

export default AdminHome;