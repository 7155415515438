import React, { useState } from "react";
import {AccordionData} from './types';
import AccordionItem from "./AccordionItem";
import './Accordion.css';

const Accordion = ({ items, openState, toggler }: any) => {
  return (
    <ul className="accordion">
      {
      items.map((item:any,idx:number) => (
        <AccordionItem 
          key={idx} 
          data={item} 
          isOpen={openState} 
          btnOnClick={()=> toggler()}
        />
      ))
      }
    </ul>
  )
}

export default Accordion;