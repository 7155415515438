import NDIS_Banner from '../components/NDIS/NDIS_Banner';
import NDIS_Statement from '../components/NDIS/NDIS_Statement';
import NDIS_TextImg from '../components/NDIS/NDIS_TextImg';
import NDIS_Services from '../components/NDIS/NDIS_Services';
import NDIS_CTA from '../components/NDIS/NDIS_CTA';
import PageFaqs from '../components/PageFaqs';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { faqActions } from '../../redux/faq';
import CircularLoader from '../../common/components/CircularLoader';



function NDIS() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { ndisFaqs } = useSelector((state: RootState) => {
    let ndisFaqs = state.faq.faqsByPage['NDIS'];
    return {
      ndisFaqs
    }
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFaqList();
  }, []);

  const getFaqList = async () => {
    try {
      setLoading(true);
      await dispatch(faqActions.fetchFaqListByPage({page: 'NDIS'}));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <NDIS_Banner/>
      <NDIS_Statement/>
      <NDIS_Services />
      <NDIS_TextImg/>
      <NDIS_CTA/>
      {loading ? <CircularLoader/> : <PageFaqs faqs={ndisFaqs}/> }
    </>

  );
}

export default NDIS;