import { useState } from 'react';
import MDEditor from '@uiw/react-md-editor';


function AIAnalyst() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: 'John Doe',
    age: 30,
    marital_status: 'Single',
    dependents: 0,
    living_arrangement: 'Renting',
    citizenship_type: 'Citizen',
    employment_type: 'Full Time',
    employment_income: 50000,
    commission_bonus: 0,
    total_savings: 60000,
    ppor_value: 0,
    ppor_debt: 0,
    inv_value: 0,
    inv_debt: 0,
    living_expenses: 20000,
    ppor_loan_repayment: 0,
    rental_expense: 0,
    rental_income: 0,
    inv_repayments: 0,
    cc_limit:0,
    credit_default: true,
    repayment_type: 'Principal & Interest',
    property_location: 'Metro',
    property_type: 'House',
    goal: 'Buy a house'
  });
  const [output, setOutput] = useState("");
  const property_type = ['House', 'Apartment', 'Townhouse', 'Unit', 'Land', 'Other'];

  
  const postRequest = async (url: string, data: any) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const runAIEngine = async () => {
    setOutput("");
    setLoading(true);
    const response = await postRequest('http://localhost:8000/ai_engine/', formData);
    if (response.result) {
      setOutput(response.result);
    }
    console.log(response);
    setLoading(false);
  }

  return (
    <div className='m-10'>
      <h1 className='text-2xl font-bold mb-4'>AIAnalyst</h1>
      <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={runAIEngine}>
        Run AI Engine
      </button>
      <div className='flex'>
        <div className='w-1/2'>
          <form className='mt-4'>
            <div className='flex flex-col'>
              <label htmlFor='name'>Name</label>
              <input
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />

              <label htmlFor='age'>Age</label>
              <input
                type='number'
                id='age'
                name='age'
                value={formData.age}
                onChange={(e) => setFormData({ ...formData, age: parseInt(e.target.value) })}
              />

              <label htmlFor='marital_status'>Marital Status</label>
              <input
                type='text'
                id='marital_status'
                name='marital_status'
                value={formData.marital_status}
                onChange={(e) => setFormData({ ...formData, marital_status: e.target.value })}
              />

              <label htmlFor='dependents'>Dependents</label>
              <input
                type='number'
                id='dependents'
                name='dependents'
                value={formData.dependents}
                onChange={(e) => setFormData({ ...formData, dependents: parseInt(e.target.value) })}
              />

              <label htmlFor='living_arrangement'>Living Arrangement</label>
              <input
                type='text'
                id='living_arrangement'
                name='living_arrangement'
                value={formData.living_arrangement}
                onChange={(e) => setFormData({ ...formData, living_arrangement: e.target.value })}
              />

              <label htmlFor='citizenship_type'>Citizenship Type</label>
              <input
                type='text'
                id='citizenship_type'
                name='citizenship_type'
                value={formData.citizenship_type}
                onChange={(e) => setFormData({ ...formData, citizenship_type: e.target.value })}
              />

              <label htmlFor='employment_type'>Employment Type</label>
              <select
                id='employment_type'
                name='employment_type'
                value={formData.employment_type}
                onChange={(e) => setFormData({ ...formData, employment_type: e.target.value })}
              >
                <option value='Full Time'>Full Time</option>
                <option value='Part Time'>Part Time</option>
                <option value='Self Employed'>Self Employed</option>
                <option value='Unemployed'>Unemployed</option> {/* Added option for Unemployed */}
              </select>

              <label htmlFor='employment_income'>Employment Income</label>
              <input
                type='number'
                id='employment_income'
                name='employment_income'
                value={formData.employment_income}
                onChange={(e) => setFormData({ ...formData, employment_income: parseInt(e.target.value) })}
              />

              <label htmlFor='commission_bonus'>Commission Bonus</label>
              <input
                type='number'
                id='commission_bonus'
                name='commission_bonus'
                value={formData.commission_bonus}
                onChange={(e) => setFormData({ ...formData, commission_bonus: parseInt(e.target.value) })}
              />

              <label htmlFor='total_savings'>Total Savings</label>
              <input
                type='number'
                id='total_savings'
                name='total_savings'
                value={formData.total_savings}
                onChange={(e) => setFormData({ ...formData, total_savings: parseInt(e.target.value) })}
              />

              <label htmlFor='ppor_value'>PPOR Value</label>
              <input
                type='number'
                id='ppor_value'
                name='ppor_value'
                value={formData.ppor_value}
                onChange={(e) => setFormData({ ...formData, ppor_value: parseInt(e.target.value) })}
              />

              <label htmlFor='ppor_debt'>PPOR Debt</label>
              <input
                type='number'
                id='ppor_debt'
                name='ppor_debt'
                value={formData.ppor_debt}
                onChange={(e) => setFormData({ ...formData, ppor_debt: parseInt(e.target.value) })}
              />

              <label htmlFor='inv_value'>Investment Value</label>
              <input
                type='number'
                id='inv_value'
                name='inv_value'
                value={formData.inv_value}
                onChange={(e) => setFormData({ ...formData, inv_value: parseInt(e.target.value) })}
              />

              <label htmlFor='inv_debt'>Investment Debt</label>
              <input
                type='number'
                id='inv_debt'
                name='inv_debt'
                value={formData.inv_debt}
                onChange={(e) => setFormData({ ...formData, inv_debt: parseInt(e.target.value) })}
              />

              <label htmlFor='living_expenses'>Living Expenses</label>
              <input
                type='number'
                id='living_expenses'
                name='living_expenses'
                value={formData.living_expenses}
                onChange={(e) => setFormData({ ...formData, living_expenses: parseInt(e.target.value) })}
              />

              <label htmlFor='ppor_loan_repayment'>PPOR Loan Repayment</label>
              <input
                type='number'
                id='ppor_loan_repayment'
                name='ppor_loan_repayment'
                value={formData.ppor_loan_repayment}
                onChange={(e) => setFormData({ ...formData, ppor_loan_repayment: parseInt(e.target.value) })}
              />

              <label htmlFor='rental_expense'>Rental Expenses</label>
              <input
                type='number'
                id='rental_expense'
                name='rental_expense'
                value={formData.rental_expense}
                onChange={(e) => setFormData({ ...formData, rental_expense: parseInt(e.target.value) })}
              />

              <label htmlFor='rental_income'>Rental Income</label>
              <input
                type='number'
                id='rental_income'
                name='rental_income'
                value={formData.rental_income}
                onChange={(e) => setFormData({ ...formData, rental_income: parseInt(e.target.value) })}
              />

              <label htmlFor='inv_repayments'>Investment Repayments</label>
              <input
                type='number'
                id='inv_repayments'
                name='inv_repayments'
                value={formData.inv_repayments}
                onChange={(e) => setFormData({ ...formData, inv_repayments: parseInt(e.target.value) })}
              />

              <label htmlFor='cc_limit'>Credit Card Limit</label>
              <input
                type='number'
                id='cc_limit'
                name='cc_limit'
                value={formData.cc_limit}
                onChange={(e) => setFormData({ ...formData, cc_limit: parseInt(e.target.value) })}
              />

              <label htmlFor='credit_default'>Credit Default</label>
              <input
                type='checkbox'
                id='credit_default'
                name='credit_default'
                checked={formData.credit_default}
                onChange={(e) => setFormData({ ...formData, credit_default: e.target.checked })}
              />

              <label htmlFor='repayment_type'>Repayment Type</label>
              <input
                type='text'
                id='repayment_type'
                name='repayment_type'
                value={formData.repayment_type}
                onChange={(e) => setFormData({ ...formData, repayment_type: e.target.value })}
              />

              <label htmlFor='property_location'>Property Location</label>
              <input
                type='text'
                id='property_location'
                name='property_location'
                value={formData.property_location}
                onChange={(e) => setFormData({ ...formData, property_location: e.target.value })}
              />

              <label htmlFor='property_type'>Property Type</label>
              <select
                id='property_type'
                name='property_type'
                value={formData.property_type}
                onChange={(e) => setFormData({ ...formData, property_type: e.target.value })}
              >
                {property_type.map((type: string) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              <label htmlFor='goal'>Goal</label>
              <input
                type='text'
                id='goal'
                name='goal'
                value={formData.goal}
                onChange={(e) => setFormData({ ...formData, goal: e.target.value })}
              />
            </div>
          </form>
        </div>
        <div className='w-1/2'>
          <p className='mt-4'>Output</p>
          {loading && <p>Loading...</p>}
          <div className="flex gap-12 max-w-screen-xl md:py-16 py-8 md:mx-auto md:px-12 px-6">
            <article className="w-full"> {/* lg:w-3/5 if sidebar */}
              <MDEditor.Markdown source={output} 
                style={{ 
                  backgroundColor: 'white', 
                  color: 'black', 
                  whiteSpace: 'pre-wrap', 
                  display: 'flex', 
                  flexDirection: 'column'
                }}
              />
            </article>

            {/* Sidebar */}
            {/* <SideBar /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIAnalyst;