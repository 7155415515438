

function TagForm({tag, onChangeFormField}: any) {
  return (
    <>
      <div className="container w-full">
        <div className="mt-10">
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2 text-xl">
            Name
          </label>
          <input
            id="name"
            type="text"
            value={tag.name}
            onChange={(e) => onChangeFormField('name', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
      <div className="container w-full">
        <div className="my-10">
          <label htmlFor="slug" className="block text-gray-700 font-bold mb-2 text-xl">
            URL Slug
          </label>
          <input
            id="slug"
            type="text"
            value={tag.urlSlug}
            onChange={(e) => onChangeFormField('urlSlug', e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      </div>
    </>
  )
}

export default TagForm;