

function PropertyTypeForm({propertyType, onChangeFormField, onSubmit}: any) {
  return (
    <form onSubmit={onSubmit} className="md:w-1/2 mx-auto my-10 text-left ">     
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
          Name
        </label>
        <input
          id="name"
          type="text"
          value={propertyType.name}
          onChange={(e) => onChangeFormField('name', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="abbreviation" className="block text-gray-700 font-bold mb-2">
          Abbreviation
        </label>
        <input
          id="abbreviation"
          type="text"
          value={propertyType.abbr}
          onChange={(e) => onChangeFormField('abbr', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700 font-bold mb-2">
          Description
        </label>
        <textarea
          id="description"
          value={propertyType.description}
          onChange={(e) => onChangeFormField('description', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        ></textarea>
      </div>
      <div className="flex items-center justify-center">
        <button
          type="submit"
          className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default PropertyTypeForm;