import * as React from "react";
import logo_tiktok_bw from "../../../static/logo_tiktok_bw.png"
import logo_fb_bw from "../../../static/logo_fb_bw.png"
import logo_instagram_bw from "../../../static/logo_instagram_bw.png"


interface medialist {
    img: any,
    link: any,
  }

const media_list: Array<medialist> = [
    {
        img: logo_fb_bw,
        link: "https://www.facebook.com/61560040730111"
    },
    // {
    //     img: logo_tiktok_bw,
    //     link: "#"
    // },
    {
        img: logo_instagram_bw,
        link: "https://www.instagram.com/investdoor_au/"
    }
  ]


const MediaLogoNew = () => {
  
    return (
    <div className="flex flex-row justify-center my-4 w-full space-x-4">
      {
        media_list.map((icon) => (
          <a href={icon.link} target="_blank" rel="noopener noreferrer">
            <img src={icon.img} alt={icon.link} className="h-5"></img>
          </a>
        ))

      }
    </div>
    
  );

}

export default MediaLogoNew;