import ArticleRow from './ArticleRow';
import CircularLoader from '../../../common/components/CircularLoader';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { articleActions } from '../../../redux/article';


const BlogArticles = ({ articles, tags, currentSelectedTagId, setCurrentSelectedTagId, loading, tagNavigate }: any) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const currentNumberOfArticles = useSelector((state: any) => state.article.currentNumberOfArticles);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;
    
      if (scrollY + windowHeight >= documentHeight - 100) {
        if (!isLoadingMore && currentNumberOfArticles < articles.length) { // Check if not already loading more and more articles are available
          setIsLoadingMore(true); // Set loading state
          // Simulate a delay to demonstrate loading
          setTimeout(() => {
            const newMoreCard = currentNumberOfArticles + 3;
            dispatch(articleActions.setCurrentNumberOfArticles(newMoreCard));
            setIsLoadingMore(false); // Reset loading state
          }, 1000); // You can adjust the delay as needed
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentNumberOfArticles, articles, isLoadingMore, currentSelectedTagId]);

  if (!articles) return <CircularLoader />;

  return (
    <section className="py-8 mx-auto w-full max-w-screen-xl">
      <div className="flex flex-wrap px-5 md:px-12 gap-2">
      <select 
        className="border border-id-blue3 focus:ring-4 
        focus:outline-none focus:ring-id-blue2 font-medium rounded-lg 
        text-sm px-5 py-2.5 text-center mr-2 mb-2 uppercase"
        value={currentSelectedTagId}
        onChange={(e) => setCurrentSelectedTagId(e.target.value)}
        disabled={loading}
      >
        {tags.map((data: any, index: number) => (
          <option 
            key={index} 
            value={data.id}
          >
            {data.name}
          </option>
        ))}
      </select>
      </div>
      {!loading ? (
        articles.slice(0, currentNumberOfArticles).map((data: any) => (
          <ArticleRow data={data} key={data.id} {...{tagNavigate}}/>
        ))
      ) : (
        <CircularLoader />
      )}
      {isLoadingMore && <CircularLoader /> /* Display a loading icon while loading more articles */}
    </section>
  );
}

export default BlogArticles;
