import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import './App.css';
import {Auth, Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import Login from './main/containers/Login';
import SignUp from './main/containers/SignUp';
import Verify from './main/containers/Verify';
import AdminHome from './adminpanel/containers/AdminHome';
import IndexNew from './main/containers/IndexNew';
import { ThunkDispatch } from '@reduxjs/toolkit';
import ForgotPassword from './main/containers/ForgotPassword';
import Index from './main/containers/Index';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from './redux/user'; 
import { useLocation } from "react-router-dom";


import { ToastContainer } from 'react-toastify';
Amplify.configure(awsExports);


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    dispatch(userActions.fetchCurrentUser());
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/*" element={<IndexNew />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp/>} />
          <Route path='/verify' element={<Verify/>} />
          <Route path='/admin/*' element={<AdminHome />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
