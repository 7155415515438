interface HeroTemplateProps {
  text: string;
  description: any;
  image: string;
}

const HeroTemplate = ({ text, description, image }: HeroTemplateProps) => {
  return (
    <div className="w-full bg-id-new-blue2 py-20">
      <div className="mx-auto max-w-screen-xl">
        <div className="flex flex-col lg:flex-row w-full">
          <div className="flex flex-col justify-center lg:basis-1/2 basis-full md:py-16 py-8 md:mx-12 mx-6">
            <div className="text-3xl font-bold text-gray-300 md:text-5xl md:text-left pb-6">
              {text}
            </div>
            <div className="font-inter text-neutral-300 md:text-sm md:text-left md:whitespace-pre-line">
              {description}
            </div>
          </div>
          <div className="flex items-center lg:basis-1/2 basis-full lg:order-last order-first  max-h-96 relative overflow-clip">
            { image && 
              <img className="object-cover w-full h-full" src={image} alt={text} /> 
            }
          </div>
        </div>
      </div>
    </div>
  ) 
}

export default HeroTemplate;
