import { useState } from "react";
import { useSelector } from "react-redux";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { toast } from "react-toastify";
import { updateUser } from "../../graphql/mutations";
import { RootState } from "../../store";
import { userActions } from "../../redux/user";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { changePassword } from "../../api/amp-auth";
import { set } from "lodash-es";


const UserProfile = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [firstName, setFirstName] = useState(currentUser?.firstName ?? "");
  const [lastName, setLastName] = useState(currentUser?.lastName ?? "");
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phoneNumber ?? "");
  const [address, setAddress] = useState(currentUser?.address ?? "");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateProfile = async () => {
    setIsLoading(true);
    try {
      const payload = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: currentUser!.id,
            firstName,
            lastName,
            phoneNumber,
            address,
          },
        })
      );
      //@ts-ignore
      dispatch(userActions.setCurrentUser(payload.data.updateUser));
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update profile.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      console.log('try');
      //use changePassword helper function
      await changePassword(password, newPassword, newPasswordConfirm);
      toast.success("Password changed successfully!");
      setPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
    } catch (error) {
      console.log('catch');
      console.error(error);
      toast.error(`Failed to change password. ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-2xl font-bold mb-4">Edit Profile</h1>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="email">
          Email
        </label>
        <p>{currentUser?.email ?? ""}</p>
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="firstName">
          First Name
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="lastName">
          Last Name
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="text"
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="phoneNumber">
          Phone Number
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="text"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="address">
          Address
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="text"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="password">
          Current Password
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="newPassword">
          New Password
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-2" htmlFor="newPassword">
          Confirm New Password
        </label>
        <input
          className="w-full border border-gray-400 p-2 rounded"
          type="password"
          id="newPasswordConfirm"
          value={newPasswordConfirm}
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
        />
      </div>
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50"
        onClick={handleUpdateProfile}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Update Profile"}
      </button>
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50 ml-4"
        onClick={handleChangePassword}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Change Password"}
      </button>
    </div>
  );
};

export default UserProfile;
