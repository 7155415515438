import React from 'react'
import {Link} from 'react-router-dom'

function SidebarItem(props: any) {
  
  
  return (
    
      <div className="w-30">
        <Link to={props.to} onClick={(e) => props.onClick(e, props.to)}>
          <div className={`flex mx-2 my-2 px-2 py-1 ${!props.openValue ? "md:flex hidden": ""} items-center hover:bg-slate-200 w-30 align-middle duration-200 transformation-all ${props.active ? "md:bg-slate-300  rounded-lg "  :  " duration-250"}`}>
            <span className={`${!props.openValue? 'md:flex hidden duration-300'  : ' visible duration-300 delay-200 '}`}>{props.icon}</span>  
            <span className={`ml-2 ${!props.openValue ? ' hidden':'transition duration-300 delay-150 '}`} >{props.label}</span>
          </div>
        </Link>
      </div>
   
  )
}

export default SidebarItem


