import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify'
import { createFileLink } from '../../helpers/api';
import { createFileLabel } from '../../helpers/parsers';

//devbucket: dvzh2qmpskvye.cloudfront.net/public/KEY
//prodbucket: d2in3gbkovys0e.cloudfront.net/public/KEY

function MultipleImagePicker({property, onChangeFormField, onClearFiles, onDeleteSpecificFile}: any) {
  
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'text/html': ['.html', '.htm'],
    },
    multiple: true,
    onDrop: (acceptedFiles: any) => {
      onChangeFormField('imageUrl', [...property.imageUrl, ...acceptedFiles]);
    },
  });

  const handleUpload = async () => {
    setUploading(true);
    try {
      console.log(images[0]);
      await Promise.all(images.map((file: any) => Storage.put(file.name, file).then(async (result) => {
        console.log(result); //{key: 'myfilename.png'} <-- save this to the db!
      })));
      alert('Images uploaded successfully!');
      setImages([]);
    } catch (error) {
      console.error('Error uploading images', error);
      alert('Error uploading images');
    } finally {
      setUploading(false);
    }
  };

  //later: typeof file === 'File' ? URL.creatObjectURL(file) : someHelperCDNFunc(file)
  //also in output

  return (
    <div>
      <h2>Upload Images</h2>
      <div {...getRootProps({
        className:
        'mt-1 flex justify-center items-center w-full h-32 border-2 border-dashed rounded-lg'
		})}>
        <input {...getInputProps()} />
        <p className="text-gray-400">Drag and drop property images here, or click to select files.</p>
      </div>
      <button onClick={(e) => onClearFiles(e, 'imageUrl')} className="p-2 bg-slate-300">
        Clear all images
      </button>
      <div className="flex flex-wrap flex-col text-left mt-4">
        {property.imageUrl.map((file: any, i: number) => (
          <div
            key={i}
            className="w-1/4 p-2 flex"
          >
            <a
              href={createFileLink(file) as string}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              {createFileLabel(file)}
            </a>
            <div className="inline-block mx-2">
              <button className="bg-gray-200 w-6 h-6" onClick={(e) => onDeleteSpecificFile(e, 'imageUrl', i)}>
                x
              </button>
              <img src={createFileLink(file)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MultipleImagePicker;
