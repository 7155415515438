/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPropertyMessage = /* GraphQL */ `query GetPropertyMessage($id: ID!) {
  getPropertyMessage(id: $id) {
    id
    user {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    property {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      propertyStatus
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    propertyName
    propertyAddress
    userFirstName
    userLastName
    userEmail
    userPhoneNumber
    message
    readByUserIds
    sysGen
    archived
    createdAt
    updatedAt
    userMessagesId
    propertyMessagesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPropertyMessageQueryVariables,
  APITypes.GetPropertyMessageQuery
>;
export const listPropertyMessages = /* GraphQL */ `query ListPropertyMessages(
  $filter: ModelPropertyMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPropertyMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      propertyName
      propertyAddress
      userFirstName
      userLastName
      userEmail
      userPhoneNumber
      message
      readByUserIds
      sysGen
      archived
      createdAt
      updatedAt
      userMessagesId
      propertyMessagesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPropertyMessagesQueryVariables,
  APITypes.ListPropertyMessagesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    cognitoId
    email
    phoneNumber
    address
    birthdate
    lastIp
    ausBusinessNumber
    accountTier
    messages {
      nextToken
      __typename
    }
    propertyInterests {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const searchUsers = /* GraphQL */ `query SearchUsers(
  $filter: SearchableUserFilterInput
  $sort: [SearchableUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableUserAggregationInput]
) {
  searchUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchUsersQueryVariables,
  APITypes.SearchUsersQuery
>;
export const getProperty = /* GraphQL */ `query GetProperty($id: ID!) {
  getProperty(id: $id) {
    id
    pid
    name
    price
    address
    state
    primaryImageUrl
    imageUrl
    brochureUrl
    description
    addedBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    interestedUser {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    propertyStatus
    propertyType {
      id
      name
      abbr
      description
      createdAt
      updatedAt
      __typename
    }
    listAvailability
    bedrooms
    bathrooms
    carspaces
    rentalIncome
    minimumRentalYield
    contractType
    coordinateX
    coordinateY
    archived
    createdAt
    updatedAt
    userPropertyInterestsId
    propertyAddedById
    propertyInterestedUserId
    propertyPropertyTypeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPropertyQueryVariables,
  APITypes.GetPropertyQuery
>;
export const listProperties = /* GraphQL */ `query ListProperties(
  $filter: ModelPropertyFilterInput
  $limit: Int
  $nextToken: String
) {
  listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      propertyStatus
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPropertiesQueryVariables,
  APITypes.ListPropertiesQuery
>;
export const searchProperties = /* GraphQL */ `query SearchProperties(
  $filter: SearchablePropertyFilterInput
  $sort: [SearchablePropertySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePropertyAggregationInput]
) {
  searchProperties(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      propertyStatus
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPropertiesQueryVariables,
  APITypes.SearchPropertiesQuery
>;
export const getPropertyType = /* GraphQL */ `query GetPropertyType($id: ID!) {
  getPropertyType(id: $id) {
    id
    name
    abbr
    description
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPropertyTypeQueryVariables,
  APITypes.GetPropertyTypeQuery
>;
export const listPropertyTypes = /* GraphQL */ `query ListPropertyTypes(
  $filter: ModelPropertyTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPropertyTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      abbr
      description
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPropertyTypesQueryVariables,
  APITypes.ListPropertyTypesQuery
>;
export const getInquiryMessage = /* GraphQL */ `query GetInquiryMessage($id: ID!) {
  getInquiryMessage(id: $id) {
    id
    firstName
    lastName
    email
    phoneNumber
    message
    readByUserIds
    archived
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInquiryMessageQueryVariables,
  APITypes.GetInquiryMessageQuery
>;
export const listInquiryMessages = /* GraphQL */ `query ListInquiryMessages(
  $filter: ModelInquiryMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listInquiryMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      phoneNumber
      message
      readByUserIds
      archived
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInquiryMessagesQueryVariables,
  APITypes.ListInquiryMessagesQuery
>;
export const getArticle = /* GraphQL */ `query GetArticle($id: ID!) {
  getArticle(id: $id) {
    id
    urlSlug
    title
    content
    excerpt
    tags {
      nextToken
      __typename
    }
    createdBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    customAuthorName
    coverPhotoUrl
    listPhotoUrl
    displayDate
    archived
    createdAt
    updatedAt
    articleCreatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArticleQueryVariables,
  APITypes.GetArticleQuery
>;
export const listArticles = /* GraphQL */ `query ListArticles(
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      urlSlug
      title
      content
      excerpt
      customAuthorName
      coverPhotoUrl
      listPhotoUrl
      displayDate
      archived
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArticlesQueryVariables,
  APITypes.ListArticlesQuery
>;
export const searchArticles = /* GraphQL */ `query SearchArticles(
  $filter: SearchableArticleFilterInput
  $sort: [SearchableArticleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableArticleAggregationInput]
) {
  searchArticles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      urlSlug
      title
      content
      excerpt
      customAuthorName
      coverPhotoUrl
      listPhotoUrl
      displayDate
      archived
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchArticlesQueryVariables,
  APITypes.SearchArticlesQuery
>;
export const getArticleTag = /* GraphQL */ `query GetArticleTag($id: ID!) {
  getArticleTag(id: $id) {
    id
    urlSlug
    name
    articles {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArticleTagQueryVariables,
  APITypes.GetArticleTagQuery
>;
export const listArticleTags = /* GraphQL */ `query ListArticleTags(
  $filter: ModelArticleTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticleTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      urlSlug
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArticleTagsQueryVariables,
  APITypes.ListArticleTagsQuery
>;
export const getFaq = /* GraphQL */ `query GetFaq($id: ID!) {
  getFaq(id: $id) {
    id
    title
    content
    excerpt
    externalUrl
    weight
    page
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFaqQueryVariables, APITypes.GetFaqQuery>;
export const listFaqs = /* GraphQL */ `query ListFaqs($filter: ModelFaqFilterInput, $limit: Int, $nextToken: String) {
  listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      content
      excerpt
      externalUrl
      weight
      page
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFaqsQueryVariables, APITypes.ListFaqsQuery>;
export const getArticleArticleTags = /* GraphQL */ `query GetArticleArticleTags($id: ID!) {
  getArticleArticleTags(id: $id) {
    id
    articleId
    articleTagId
    article {
      id
      urlSlug
      title
      content
      excerpt
      customAuthorName
      coverPhotoUrl
      listPhotoUrl
      displayDate
      archived
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    articleTag {
      id
      urlSlug
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArticleArticleTagsQueryVariables,
  APITypes.GetArticleArticleTagsQuery
>;
export const listArticleArticleTags = /* GraphQL */ `query ListArticleArticleTags(
  $filter: ModelArticleArticleTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticleArticleTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      articleId
      articleTagId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArticleArticleTagsQueryVariables,
  APITypes.ListArticleArticleTagsQuery
>;
export const articleArticleTagsByArticleId = /* GraphQL */ `query ArticleArticleTagsByArticleId(
  $articleId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelArticleArticleTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  articleArticleTagsByArticleId(
    articleId: $articleId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      articleId
      articleTagId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArticleArticleTagsByArticleIdQueryVariables,
  APITypes.ArticleArticleTagsByArticleIdQuery
>;
export const articleArticleTagsByArticleTagId = /* GraphQL */ `query ArticleArticleTagsByArticleTagId(
  $articleTagId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelArticleArticleTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  articleArticleTagsByArticleTagId(
    articleTagId: $articleTagId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      articleId
      articleTagId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ArticleArticleTagsByArticleTagIdQueryVariables,
  APITypes.ArticleArticleTagsByArticleTagIdQuery
>;
