
import MultipleImagePicker from "./MultipleImagePicker";
import SingleImagePicker from "./SingleImagePicker";
import PdfFilePicker from "./PdfFilePicker";
import MDEditor from '@uiw/react-md-editor';
import {STATES, CONTRACT_TYPES, PROPERTY_STATUSES, PROPERTY_AVAILABILITY} from "../../constants/fields";

function PropertyForm({property, propertyTypeList, onChangeFormField, onClearFiles, onDeleteSpecificFile}: any) {
  return (
    <form className="w-fit mx-auto mt-10 my-2 text-left ">
      <div className="mb-4">
        <label htmlFor="pid" className="block text-gray-700 font-bold mb-2">
          Property ID
        </label>
        <input
          id="pid"
          type="text"
          value={property.pid}
          onChange={(e) => onChangeFormField('pid', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
          Name
        </label>
        <input
          id="name"
          type="text"
          value={property.name}
          onChange={(e) => onChangeFormField('name', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="price" className="block text-gray-700 font-bold mb-2">
          Price
        </label>
        <input
          id="price"
          type="text"
          value={property.price}
          onChange={(e) => onChangeFormField('price', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="address" className="block text-gray-700 font-bold mb-2">
          Address
        </label>
        <input
          id="address"
          type="text"
          value={property.address}
          onChange={(e) => onChangeFormField('address', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="state" className="block text-gray-700 font-bold mb-2">
          State
        </label>
        <select
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="state"
          name="state"
          value={property.state}
          onChange={(e) => onChangeFormField('state', e.target.value)}
        > 
          {
            STATES.map((o, i) => <option value={o.value} key={i}>{o.label}</option>)
          }
        </select>
      </div>
      <label htmlFor="state" className="block text-gray-700 font-bold mb-2">
          Primary Image
      </label>
      <SingleImagePicker obj={property} objKey={'primaryImageUrl'} onChangeFormField={onChangeFormField} />
      <label htmlFor="state" className="block text-gray-700 font-bold mb-2">
          Other Images
      </label>
      <MultipleImagePicker property={property} onChangeFormField={onChangeFormField} onClearFiles={onClearFiles} onDeleteSpecificFile={onDeleteSpecificFile}/>
      <PdfFilePicker property={property} onChangeFormField={onChangeFormField} onClearFiles={onClearFiles} onDeleteSpecificFile={onDeleteSpecificFile}/>
      {/* <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700 font-bold mb-2">
          Description
        </label>
        <textarea
          id="description"
          value={property.description}
          onChange={(e) => onChangeFormField('description', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        ></textarea>
      </div> */}
      <div className="mb-4">
        <label htmlFor="propertyStatus" className="block text-gray-700 font-bold mb-2">
          Property Status
        </label>
        <select
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="propertyStatus"
          name="propertyStatus"
          value={property.propertyStatus}
          onChange={(e) => onChangeFormField('propertyStatus', e.target.value)}
        >
          {
            PROPERTY_STATUSES.map((o, i) => <option value={o.value} key={i}>{o.label}</option>)
          }
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="propertyPropertyTypeId" className="block text-gray-700 font-bold mb-2">
          Property Type
        </label>
        <select
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="propertyPropertyTypeId"
          name="propertyPropertyTypeId"
          value={property.propertyPropertyTypeId}
          onChange={(e) => onChangeFormField('propertyPropertyTypeId', e.target.value)}
        >
          <option value="">--Choose one--</option>
          {
            propertyTypeList.map((pt: any, i: number) => {
              return (
                <option value={pt.id} key={i}>{pt.name}</option>
              );
            })
          }
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="listAvailability" className="block text-gray-700 font-bold mb-2">
          List Availability
        </label>
        <select
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="listAvailability"
          name="listAvailability"
          value={property.listAvailability}
          onChange={(e) => onChangeFormField('listAvailability', e.target.value)}
        >
          {
            PROPERTY_AVAILABILITY.map((o, i) => <option value={o.value} key={i}>{o.label}</option>)
          }
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="bedrooms" className="block text-gray-700 font-bold mb-2">
          Bedrooms
        </label>
        <input
          id="bedrooms"
          type="text"
          value={property.bedrooms}
          onChange={(e) => onChangeFormField('bedrooms', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="bathrooms" className="block text-gray-700 font-bold mb-2">
          Bathrooms
        </label>
        <input
          id="bathrooms"
          type="text"
          value={property.bathrooms}
          onChange={(e) => onChangeFormField('bathrooms', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="carspaces" className="block text-gray-700 font-bold mb-2">
          Carspaces
        </label>
        <input
          id="carspaces"
          type="text"
          value={property.carspaces}
          onChange={(e) => onChangeFormField('carspaces', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="rentalIncome" className="block text-gray-700 font-bold mb-2">
          Weekly Rental Income
        </label>
        <input
          id="rentalIncome"
          type="text"
          value={property.rentalIncome}
          onChange={(e) => onChangeFormField('rentalIncome', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="minimumRentalYield" className="block text-gray-700 font-bold mb-2">
          Minimum Rental Yield
        </label>
        <input
          id="minimumRentalYield"
          type="text"
          value={property.minimumRentalYield}
          onChange={(e) => onChangeFormField('minimumRentalYield', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="contractType" className="block text-gray-700 font-bold mb-2">
          contractType
        </label>
        <select
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="contractType"
          name="contractType"
          value={property.contractType}
          onChange={(e) => onChangeFormField('contractType', e.target.value)}
        >
          {
            CONTRACT_TYPES.map((o, i) => <option value={o.value} key={i}>{o.label}</option>)
          }
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="coordinateX" className="block text-gray-700 font-bold mb-2">
          X Coordinate / longitude
        </label>
        <input
          id="coordinateX"
          type="text"
          value={property.coordinateX}
          onChange={(e) => onChangeFormField('coordinateX', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="coordinateY" className="block text-gray-700 font-bold mb-2">
          Y Coordinate / latitude
        </label>
        <input
          id="coordinateY"
          type="text"
          value={property.coordinateY}
          onChange={(e) => onChangeFormField('coordinateY', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <label htmlFor="description" className="block text-gray-700 font-bold mb-2 text-xl">
          Description
      </label>
      <MDEditor
        value={property.description}
        onChange={(val: any) => onChangeFormField('description', val)}
        className="text-white"
        style={{backgroundColor: 'black', color: 'white'}}
      />

      <label htmlFor="description-preview" className="block text-gray-700 font-bold mb-2">
        Preview
      </label>

      <MDEditor.Markdown source={property.description}
        style={{ 
          backgroundColor: 'white', 
          color: 'black', 
          whiteSpace: 'pre-wrap', 
          display: 'flex', 
          flexDirection: 'column'
        }}
      />

    </form>
  )
}

export default PropertyForm;