
const NDIS_statement = () => {
    return  (
      <div className="w-full mx-auto text-center bg-id-blueShade opacity-1 text-white  ">
        <div className=" container mx-auto flex flex-col justify-center items-center pt-10 pb-14 px-6 gap-14 md:pt-20 md:pb-20 md:px-14">
          <div className="text-md opacity-70">
          WE UNDERSTAND
          </div>
          <div className="text-md md:text-2xl opacity-80 md:w-[60rem]">
            We understand the importance of inclusive housing and the immense potential within the National Disability Insurance Scheme (NDIS) and Specialist Disability Accommodation (SDA) sectors. 
            <br/><br/>
            With years of experience and a commitment to making a positive impact, we're here to guide you through the exciting world of disability-inclusive property investments.
          </div>
        </div>
      </div>    
    );
  }   
  export default NDIS_statement;