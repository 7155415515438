import {useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {unionBy} from 'lodash-es';
import {Link} from 'react-router-dom';
import PropertyTypeAdd from './PropertyTypeAdd';
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from '../../store';
import { propertyTypeActions } from '../../redux/property_type';
import PropertyTypeEdit from './PropertyTypeEdit';

const PAGE_LIMIT = 10;

function PropertyTypes() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [modalVisible, setModalVisible] = useState(false);
  const [propertyTypeIdForDeletion, setPropertyTypeIdForDeletion] = useState('');

  const {
    propertyTypeList
  } = useSelector((state: RootState) => {
    const { propertyTypeList } = state.propertyType;
    return {
      propertyTypeList
    };
  })

  const deletePropertyType = async (propertyTypeId: any) => {
    try {
      await API.graphql(graphqlOperation(mutations.deletePropertyType, {input: {id: propertyTypeId}}));
      setModalVisible(false);
      setPropertyTypeIdForDeletion('');
      getPropertyTypeList();
      //getPropertyTypeListPaginate();
    } catch (e) {
      console.log(e);
    }
  }

  const confirmDeletePropertyType = async (propertyTypeId: any)  => {
    setModalVisible(true);
    setPropertyTypeIdForDeletion(propertyTypeId);
  }

  const onClickAdd = () => {
    navigate('/admin/property_types/add');
  }

  const getPropertyTypeList = async () => {
    await dispatch(propertyTypeActions.fetchPropertyTypeList());
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      defaultCanSort: true,
      Cell: (item : any) => {
        return <Link to={`/admin/property_types/${item.row.original.id}`}>{item.value}</Link>
      }
    },
    {
      Header: 'Abbr',
      accessor: 'abbr',
      defaultCanSort: true
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button onClick={() => confirmDeletePropertyType(item.value)}>delete</button>
      }
    }
  ];


  const PropertyTypeList =  (
    <div className=" min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col ">

          <h1 className="text-2xl font-bold">
            Property Types
          </h1>

          <p className="self-start py-4">
            Checkout properties by types.
          </p>

          <div className="row-auto self-start mb-3">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getPropertyTypeList}>
              load list
            </button>
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-45 my-2 mr-4" 
            onClick={onClickAdd}>
              add property type
            </button>
          </div>


          <div className=" overflow-auto">
            <Table columns={columns} data={propertyTypeList} />
          </div>

        </div>
      </div>

      <ConfirmModal 
        modalVisible={modalVisible} 
        onOk={() => deletePropertyType(propertyTypeIdForDeletion)} 
        onCancel={() => setModalVisible(false)}
        message={"Are you sure you want to delete this row? This is irreversible."}
      />
    </div>

  );

  return (
    <div>
      <Routes>
        <Route path="" element={PropertyTypeList} />
        <Route path="add" element={<PropertyTypeAdd />} />
        <Route path=":ptypeId" element={<PropertyTypeEdit/>} />
      </Routes>
    </div>
  )
}

export default PropertyTypes;