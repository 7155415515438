
import CheckBox from "../../../common/components/CheckBox";
import InputValue from "../../../common/InputValue";
import { useState } from "react";

const FilterSection2 = ({formVals, propertyTypeList, constantFields, onChangeVal} : any) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  
    setIsChecked(e.target.checked);
    console.log(e.target.value);
    };

  return (
    <div className='flex flex-col text-base'>
      
      <div className="font-bold text-base">Property Type</div>
      {
        propertyTypeList.map((p: any, i: number) => 
          <CheckBox label={p.name} handleChange={() => onChangeVal('propertyTypeIds', p.id, true)} 
          isChecked={formVals.propertyTypeIds.includes(p.id)} key={i}/>
        )
      }
      <div className="font-bold text-base">Status</div>
      {
        constantFields.propertyStatuses.map((s: any, i: number) => 
          <CheckBox label={s.label} handleChange={() => onChangeVal('statuses', s.value, true)} 
          isChecked={formVals.statuses.includes(s.value)} key={i}/>
        )
      }
      <div className="font-bold text-base">State</div>
      {
        constantFields.states.map((s:any, i:number) => 
          <CheckBox label={s.label} handleChange={() => onChangeVal('states', s.value, true)} 
          isChecked={formVals.states.includes(s.value)} key={i}/>
        )
      }
      <div className="font-bold text-base">BEDROOMS</div>
      <div className="flex flex-row flex-auto w-fit " >
        <InputValue className1="text-sm" className2="w-[90%]" label="Min" type="number" 
          placeholder="" id="minBedrooms" handleChange={(e) => onChangeVal('minBedrooms', e.target.value)} 
          setState="" value={formVals.minBedrooms}/>
        <InputValue className1="text-sm" className2="w-[90%]" label="Max" type="number" 
          placeholder="" id="maxBedrooms" handleChange={(e) => onChangeVal('maxBedrooms', e.target.value)} 
          setState="" value={formVals.maxBedrooms}/>
      </div>
      <div className="font-bold text-base">BATHROOMS</div>
      <div className="flex flex-row flex-auto w-fit " >
        <InputValue className1="text-sm" className2="w-[90%]" label="Min" type="number" 
          placeholder="" id="minBathrooms" handleChange={(e) => onChangeVal('minBathrooms', e.target.value)} 
          setState="" value={formVals.minBathrooms} />
        <InputValue className1="text-sm" className2="w-[90%]" label="Max" type="number" 
          placeholder="" id="maxBathrooms" handleChange={(e) => onChangeVal('maxBathrooms', e.target.value)} 
          setState="" value={formVals.maxBathrooms} />
      </div>
      <div className="font-bold text-base">CARSPACES</div>
      <div className="flex flex-row flex-auto w-fit " >
        <InputValue className1="text-sm" className2="w-[90%]" label="Min" type="number" 
          placeholder="" id="minCarspaces" handleChange={(e) => onChangeVal('minCarspaces', e.target.value)} 
          setState="" value={formVals.minCarspaces} />
        <InputValue className1="text-sm" className2="w-[90%]" label="Max" type="number" 
          placeholder="" id="maxCarspaces" handleChange={(e) => onChangeVal('maxCarspaces', e.target.value)} 
          setState="" value={formVals.maxCarspaces} />
      </div>
      
    </div>   
  )
}

export default FilterSection2; 