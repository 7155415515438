import {useState, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { format } from 'date-fns';
import Table from '../components/Table';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../../store';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import CircularLoader from '../../common/components/CircularLoader';
import { messageActions } from '../../redux/message';
import InquiryMessageDetail from '../components/InquiryMessageDetail';
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../graphql/mutations';




function InquiryMessages() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [loading, setLoading] = useState(false);



  const {
    inquiryMessageList,
    currentUser
  } = useSelector((state: RootState) => {
    const {inquiryMessageList} = state.message;
    const {currentUser} = state.user;
    return {
      inquiryMessageList,
      currentUser
    }
  });

  useEffect( () => {
      getInquiryMessageList();
    } , [] )
  

  const getInquiryMessageList = async() => {
    setLoading(true);
    dispatch(messageActions.setInquiryMessageList([]));
    await dispatch(messageActions.fetchInquiryMessageList());
    setLoading(false);
    }


  //item.row.original is the full inquiry itself!
  const columns = [
    {
      Header: 'Name',
      accessor: 'firstName',
      defaultCanSort: true,
      Cell: (item: any) => {
        return <div>{`${item.row.original.firstName} ${item.row.original.lastName}`}</div>
      }
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      defaultCanSort: true,
      Cell: (item: any) => {
          return (
            <div className="text-center">{format(new Date(item.row.original.createdAt),'yyyy/MM/dd kk:mm:ss')}</div>)
           
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      defaultCanSort: true,
      Cell: (item: any) => {
        return (
          <div>{item.row.original.email.length > 0 ? <div className="text-center">{`${item.row.original.email}`}</div> : <div className='italic text-center '>-</div>}</div>)
    }
    },
    {
      Header: 'Phone',
      accessor: 'phoneNumber',
      defaultCanSort: true,
      Cell: (item: any) => {
        return (
          <div>{item.row.original.phoneNumber !== '' ? <div className="text-center">{`${item.row.original.phoneNumber}`}</div> : <div className='italic text-center '>-</div>}</div>)
    }
    },
    {
      Header: 'Read by you',
      accessor: 'readByUserIds',
      defaultCanSort: true,
      Cell: (item: any) => {
        return <div>{isInquiryRead(item.row.original) ? "Yes" : "No"}</div>
      }
    },
    {
      Header: '',
      accessor: 'id',
      defaultCanSort: false,
      Cell: (item: any) => {
        return <button className="text-blue-500 underline hover:text-blue-700" onClick={() => markInquiryAsRead(item.row.original)}>
          read
        </button>
      }
    }
  ]



  const markInquiryAsRead = async (inquiry: any) => {
    navigate(`/admin/messages/inquiries/${inquiry.id}`);
    if (isInquiryRead(inquiry)) {
      return;
    }
    const newReadByUserIds = [
      ...(inquiry.readByUserIds ?? []),
      currentUser?.cognitoId
    ];

    //update the local state
    dispatch(messageActions.updateInquiryMessageById({id: inquiry.id, readByUserIds: newReadByUserIds}));

    API.graphql(graphqlOperation(mutations.updateInquiryMessage, {input: {id: inquiry.id, 
      readByUserIds: 
        newReadByUserIds
    }})); 
  }

  const isInquiryRead = (inquiry: any) => {
    return inquiry.readByUserIds?.includes(currentUser?.cognitoId);
  }
  

  const InquiryMessageList = (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col ">
          <h1 className="text-2xl font-bold">
            General Inquiries
          </h1>
          <p className="self-start">
            These are inquiries sent by non-users.
          </p>
          <div className="row-auto self-start">
            {/* <input type="text" value={nameSearchString} onChange={(e) => setNameSearchString(e.target.value)}/>
            <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={propertySearchByNameAndState}>search</button>
            <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={clearProperties}>clear</button> */}
          </div>

          <div className="row-auto self-start my-2">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getInquiryMessageList}>load list</button>
          </div>
          <div className="overflow-auto ">
            {!loading ? (
              <Table columns={columns} data={inquiryMessageList} />
            ) : (
              <CircularLoader />
              
            )}
          </div>

        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Routes>
        <Route path="" element={InquiryMessageList} />
        <Route path=":imId" element={<InquiryMessageDetail/>} />
      </Routes>
    </div>
  )
}


export default InquiryMessages;