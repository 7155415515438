import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import {unionBy} from 'lodash-es';

const PAGE_LIMIT = 20;

export interface PropertyType {
  id?: String;
  name?: String;
  abbr?: String;
  description?: String;
}

interface PropertyTypeState {
  propertyTypeList: PropertyType[];
  propertyTypeNextToken?: String | null;
}

const fetchPropertyTypeList = createAsyncThunk(
  'property_types/list',
  async (_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql({
        query: queries.listPropertyTypes,
        variables: {limit: PAGE_LIMIT},
        authMode: 'API_KEY'
      });
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

export const propertyTypeSlice = createSlice({
  name: 'propertyType',
  initialState: {
    propertyTypeList: [],
    propertyTypeNextToken: null
  } as PropertyTypeState,
  reducers: {
    setPropertyTypes: (state, action) => {
      state.propertyTypeList = action.payload;
    },
    setPropertyTypesNextToken: (state, action) => {
      state.propertyTypeNextToken = action.payload;
    },
    deletePropertyTypeById: (state, action) => {
      state.propertyTypeList = state.propertyTypeList.filter((p) => p.id !== action.payload);
    },
    updatePropertyTypeById: (state, action) => {
      const idx = state.propertyTypeList.findIndex((p) => p.id === action.payload.id);
      state.propertyTypeList[idx] = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPropertyTypeList.fulfilled, (state, action)=> {
      //state.propertyTypeList = unionBy(state.propertyTypeList, action.payload.listPropertyTypes.items, 'id');
      state.propertyTypeList = action.payload.listPropertyTypes.items;
      state.propertyTypeNextToken = action.payload.listPropertyTypes.nextToken;
    })
  }
});

export const propertyTypeActions = {
  fetchPropertyTypeList,
  ...propertyTypeSlice.actions
};

export default propertyTypeSlice.reducer;