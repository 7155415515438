import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { createFileLink } from "../../../helpers/api";
import moment from 'moment';
import noPicture from "../../../static/noPicture.jpg";

moment.locale('en-au');

const ArticleRow = ({data, tagNavigate} : any) => {

  const handleTagButton = (tagId: string) => {
    // handle tag link
    tagNavigate(tagId);
  }

  return (
    <div key={data.id} className="flex flex-col-reverse md:flex-row px-5 md:px-12 py-8 gap-8">
      <div className="basis-1/2">
        <Link to={`/blog/${data.id}`} className="hover:underline underline-offset-4 text-3xl text-black font-epilogue font-bold">
          { data.title }
        </Link>
        
        <p className="text-lg uppercase py-2">
          { moment(data.displayDate).format('DD MMMM YYYY') }
        </p>
        <div className="text-lg py-4">
          { data.excerpt }
        </div>
        <div className="flex flex-wrap gap-2">
          {data.tags.items.map((item: any, index: number) => {
            const name = item?.articleTag?.name
            if (name) {
              return (
                <Button 
                  key={index} 
                  size="xs" 
                  className="uppercase text-id-blue bg-transparent hover:bg-id-blue hover:text-white border border-id-blue"
                  onClick={() => handleTagButton(item.articleTag.id)}
                >
                  {name}
                </Button>
              )
            }
            return null; // Skip rendering for items with no name
          })}
        </div>
      </div>

      <div className="basis-1/2">
        <Link to={`/blog/${data.id}`}>
          <img 
            src={data.listPhotoUrl === "" ? noPicture : createFileLink(data.listPhotoUrl)}
            alt={data.imgUrl} 
            className="h-80 w-full object-cover rounded-xl" 
          />
        </Link>
      </div>
    </div>
  )
}

export default ArticleRow;