import {useState, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Table from '../components/Table';
import ConfirmModal from '../components/ConfirmModal';
import { toast } from "react-toastify";
import CircularLoader from '../../common/components/CircularLoader';
import ArticleAdd from './ArticleAdd';
import {useDispatch, useSelector} from 'react-redux';
import { articleActions } from '../../redux/article';
import { RootState, AppDispatch } from '../../store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import {Link} from 'react-router-dom';
import ArticleEdit from './ArticleEdit';
import { format } from 'date-fns';
import { set } from 'lodash-es';


function Articles() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [articleIdForDeletion, setArticleIdForDeletion] = useState('');
  const [articleIdForArchive, setArticleIdForArchive] = useState('');
  const [articleArchiveFlag, setArticleArchiveFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articleConnectionsLoading, setArticleConnectionsLoading] = useState(false);

  const articleList = useSelector((state: RootState) => state.article.articleList);

  const articleConnectionsForDeletion = useSelector((state: RootState) =>
    state.article.articleConnectionsForDeletion
  );

  const deleteArticle = async(articleId: any) => {
    try {
      setLoading(true);
      setDeleteModalVisible(false);
      //batch delete
      const articleConnectionMutation: any = articleConnectionsForDeletion.map((tc: any, i: number) => {
        return `mutation${i}: deleteArticleArticleTags(input: {id: "${tc.id}"}) {id}`;
      });

      if(articleConnectionMutation.length > 0) {
        await API.graphql(graphqlOperation(`
          mutation batchMutation {
            ${articleConnectionMutation}
          }
        `));
      }
      //then delete article
      await API.graphql(graphqlOperation(mutations.deleteArticle, {input: {id: articleId}}));
      
      setArticleIdForDeletion('');
      await getArticleList();
      toast.success('Article deleted successfully.');
    } catch (e) {
      toast.error('Error deleting article.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getArticleList();
  }, []);

  const archiveArticle = async(articleId: any) => {
    try {
      setLoading(true);
      setArchiveModalVisible(false);
      await API.graphql(graphqlOperation(mutations.updateArticle, 
        {input: {id: articleId, archived: articleArchiveFlag}}));
      dispatch(articleActions.updateArticleById({id: articleId, archived: articleArchiveFlag}));
      setArticleIdForArchive('');
      setArticleArchiveFlag(false);
      toast.success('Article archived successfully.');
    } catch (e) {
      toast.error('Error archiving article.');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  //TODO make these searchable later
  const getArticleList = async () => {
    if(!loading) {
      setLoading(true);
      try {
        await dispatch(articleActions.fetchArticleList());
        await dispatch(articleActions.fetchArticleTagList());
      } catch (e) {
        toast.error('Error fetching articles. Please try again.');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const getArticleConnectionsForDeletion = async(articleId: any) => {
    setArticleConnectionsLoading(true);
    dispatch(articleActions.clearArticleConnectionsForDeletion());
    await dispatch(articleActions.fetchArticleConnectionsForDeletion({articleId: articleId}));
    setArticleConnectionsLoading(false);
  }

  const confirmDeleteArticle = async(articleId: any) => {
    setDeleteModalVisible(true);
    setArticleIdForDeletion(articleId);
    getArticleConnectionsForDeletion(articleId);
  }

  const confirmArchiveArticle = async(articleId: any, archiveFlag: boolean) => {
    setArchiveModalVisible(true);
    setArticleIdForArchive(articleId);
    setArticleArchiveFlag(archiveFlag);
  }



  const onClickAdd = () => {
    navigate('/admin/articles/add');
  }

  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      defaultCanSort: true,
      Cell: (item : any) => {
        return <Link to={`/admin/articles/${item.row.original.id}`}>{item.value.trim().length === 0 ? '(no title)' : item.value}</Link>
      }
    },
    {
      Header: 'Display Date',
      accessor: 'displayDate',
      Cell: (item: any) => {
        return (
          <div className="text-center">{format(new Date(item.row.original.displayDate),'yyyy/MM/dd kk:mm:ss')}</div>)
         
      }
    },
    {
      Header: 'Archived',
      accessor: 'archived',
      Cell: (item: any) => {
        return (
          <div>{item.value ? 'Yes' : 'No'} 
            <button className="ml-2 p-2 bg-slate-200 rounded-md" onClick={() => confirmArchiveArticle(item.row.original.id, !!!item.value)}>
              {item.value ? 'unarchive' : 'archive'}
            </button>
          </div>
        )
      }      
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (item: any) => {
        return <button onClick={() => confirmDeleteArticle(item.value)} >delete</button>
      }
    }
  ]

  const ArticleList = (
    <div className=" min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">
            Articles
          </h1>

          <p className="self-start py-4">
            What's new? get the latest news in Real Estate only here in InvestDoor!
          </p>
          <div className="row-auto self-start">
            {/* <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={propertySearchByNameAndState}>search</button>
            <button className="shadow font-bold py-2 px-4 rounder bg-blue-200 w-40 my-2 mr-4" onClick={clearProperties}>clear</button> */}
          </div>
          <div className="row-auto self-start">
          {/* article tag checkbox */}

          </div>
          <div className="row-auto self-start">
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={getArticleList}>load list</button>
            <button className="shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4" 
            onClick={onClickAdd}>add article</button>

          </div>

          <div className=" py-2">
            {!loading ? (
              <Table columns={columns} data={articleList} />
            ) : (
              <CircularLoader />
            )}
          </div>

        </div>
      </div>

      <ConfirmModal modalVisible={deleteModalVisible} 
        onOk={() => deleteArticle(articleIdForDeletion)} 
        onCancel={() => setDeleteModalVisible(false)}
        disabled={articleConnectionsLoading}
        message={"Are you sure you want to delete this row? This is irreversible."}
      />
      <ConfirmModal modalVisible={archiveModalVisible}
        onOk={() => archiveArticle(articleIdForArchive)} 
        onCancel={() => setArchiveModalVisible(false)}
        disabled={loading}
        message={`Are you sure you want to ${articleArchiveFlag ? 'archive' : 'unarchive'} this article?`}
      />
    </div>
  );

  return (
    <div>
      <Routes>
        <Route path="" element={ArticleList} />
        <Route path="add" element={<ArticleAdd/>} />
        <Route path=":aId" element={<ArticleEdit />} />
      </Routes>
    </div>
  );

}

export default Articles;