import { FAQ_PAGE_TYPES } from "../../constants/fields";

function FaqForm({onChangeFormField, faq}: any) {
  return (
    <div className="container w-full">
      <div className="my-10">
        <label htmlFor="title" className="block text-gray-700 font-bold mb-2 text-xl">
          Title
        </label>
        <input
          id="title"
          type="text"
          value={faq.title}
          onChange={(e) => onChangeFormField('title', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="my-10">
        <label htmlFor="excerpt" className="block text-gray-700 font-bold mb-2 text-xl">
          Excerpt (Optional)
        </label>
        <input
          id="excerpt"
          type="text"
          value={faq.excerpt}
          onChange={(e) => onChangeFormField('excerpt', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="my-10">
        <label htmlFor="content" className="block text-gray-700 font-bold mb-2 text-xl">
          Content
        </label>
        <textarea
          id="content"
          value={faq.content}
          onChange={(e) => onChangeFormField('content', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
        />
      </div>
      
      

      <div className="my-10">
        <label htmlFor="page" className="block text-gray-700 font-bold mb-2 text-xl">
          Page
        </label>
        <select
          id="page"
          value={faq.page}
          onChange={(e) => onChangeFormField('page', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          {FAQ_PAGE_TYPES.map((pageType: any) => (
            <option key={pageType.value} value={pageType.value}>{pageType.label}</option>
          ))}
        </select>
      </div>

      <div className="my-10">
        <label htmlFor="weight" className="block text-gray-700 font-bold mb-2 text-xl">
          Weight
        </label>
        <input
          id="weight"
          type="text"
          value={faq.weight}
          onChange={(e) => onChangeFormField('weight', e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
    </div>
  )
}

export default FaqForm;