import React, { useRef } from 'react'

const SlidingToggleButton = ({ buttonText, ...props }: any) => {
  const checkboxRef = useRef<HTMLInputElement>(null)

  const handleToggle = () => {
    if (checkboxRef.current && checkboxRef.current.checked) {
      // Set a timeout to reset the checkbox after 2 seconds
      setTimeout(() => {
        props.func();
      }, 500);
      
      setTimeout(() => {
        if (checkboxRef.current) {
          checkboxRef.current.checked = false
        }
      }, 2000)
    }
    console.log(`${buttonText} button is toggled`)
  }

  return (
    <label className='relative flex items-center justify-center md:w-[240px] w-44 h-10 sm:h-14  cursor-pointer'>
      <input
        type='checkbox'
        className='sr-only peer'
        ref={checkboxRef}
        onChange={handleToggle}
        {...props}
      />
      <div className='w-full h-full bg-id-new-blue1 rounded-full transition-all duration-300 peer-checked:bg-id-new-blue2' />
      <div className='absolute left-0 bg-id-new-magenta h-10 w-10 sm:h-14 sm:w-14  rounded-full shadow-md transform transition-transform duration-300 md:peer-checked:-translate-x-[calc(100%-240px)] peer-checked:-translate-x-[calc(100%-176px)]' />
      <div className='absolute font-robotoslab text-white md:text-xl text-sm transition-opacity duration-100 peer-checked:opacity-0 w-full md:pl-14 pl-10 text-center select-none'>
        {buttonText}
      </div>
      {/* <div className='absolute font-robotoslab text-center  text-white text-xl transition-opacity duration-100 opacity-0 peer-checked:opacity-100 w-full pr-14 select-none'>Loading</div> */}
    </label>
  )
}

export default SlidingToggleButton