import MDEditor from '@uiw/react-md-editor';
import { HiOutlineClock, HiOutlineUserCircle } from "react-icons/hi";
import { Button } from 'flowbite-react';
// import SideBar from './SideBar';
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from '../../../store';
import article, { articleActions } from '../../../redux/article';
import HeroTemplate from '../../components/HeroTemplate';
import HeroText from '../HeroText';
import { createFileLink } from '../../../helpers/api';
import NotFound from '../../../common/components/NotFound';
import CircularLoader from '../../../common/components/CircularLoader';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useState, useEffect } from 'react';
import { set } from 'lodash-es';
import noPicture from "../../../static/noPicture.jpg";
import { useNavigate } from 'react-router-dom';

function ArticlePage() {
  const { aId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const [loading, setLoading] = useState(false);

  const articleToDisplay = useSelector((state: RootState) => 
    state.article.userArticleList.find((a:any) => a.id === aId));
  
  const currentSelectedTagId = useSelector((state: RootState) =>
    state.article.currentSelectedTagId
  );

  const fetchArticleById = async () => {
    setLoading(true);
    await dispatch(articleActions.fetchArticleById({id: aId}));
    setLoading(false);
  }

  useEffect(() => {
    if (!articleToDisplay) {
      fetchArticleById();
    } else {
      dispatch(articleActions.setCurrentSelectedTagId(currentSelectedTagId));
    }
    
  }, [articleToDisplay]);

  if (loading) {
    return (
      <div className="flex justify-center">
        <CircularLoader />
      </div>
    )
  }

  if (!articleToDisplay) {
    return (
      <NotFound />
    )
  }

  const tagNavigate = (id: string) => {
    dispatch(articleActions.setCurrentSelectedTagId(id));
    navigate('/blog');
  }

  const desc = (
    <div className="flex flex-col gap-5">
      <div className="text-neutral-300">
        <div className="flex gap-2 items-center">{articleToDisplay.customAuthorName ? (
          <>
            <HiOutlineUserCircle /> {articleToDisplay.customAuthorName}
          </>
          ) : ''
        }</div>
        <div className="flex gap-2 items-center">
          <HiOutlineClock />
          {new Date(articleToDisplay.displayDate).toLocaleDateString('en-au', { year:"numeric", month:"long", day:"numeric"})}
        </div>

      </div>
      <div className="flex flex-wrap gap-2">
        {articleToDisplay.tags.items.map((item: any, index: number) => {
          const name = item?.articleTag?.name
          if (name) {
            return (
              <Button 
                color="blue" 
                key={index} 
                size="xs" 
                className="uppercase"
                onClick={() => tagNavigate(item.articleTag.id)}
              >
                {name}
              </Button>
            )
          }
          return null; // Skip rendering for items with no name
        })}
      </div>
    </div>
  )

  // console.log(articleToDisplay.tags.items)

  return (
    <div>
      <HeroTemplate   
        text={articleToDisplay.title} 
        description={desc} 
        image={articleToDisplay.coverPhotoUrl === "" ? noPicture : createFileLink(articleToDisplay.coverPhotoUrl)}
      />
      <div className="flex gap-12 max-w-screen-xl md:py-16 py-8 md:mx-auto md:px-12 px-6">
        <article className="w-full"> {/* lg:w-3/5 if sidebar */}
          <MDEditor.Markdown source={articleToDisplay.content} 
            style={{ 
              backgroundColor: 'white', 
              color: 'black', 
              whiteSpace: 'pre-wrap', 
              display: 'flex', 
              flexDirection: 'column'
            }}
          />
        </article>

        {/* Sidebar */}
        {/* <SideBar /> */}
      </div>
      
      
    </div>
  )
}

export default ArticlePage;