import fam3 from '../../../static/fam3.jpg';
import StockImage from '../../../static/NewAssets/Stock Image 4.png'
import Tick from '../../../static/NewAssets/Tick.png';
import VisLangBullets from './VisLangBullets';

function CardFour({scrollPercentage}: any) {
  return (
    <>
      <div className="z-30 h-[108vh] flex flex-col sm:py-60 py-10 sm:px-20 px-5 bg-cover bg-no-repeat bg-top sticky -top-8" style={{backgroundImage: `url("${StockImage}")`}}>
        <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white">Make a positive impact</h3>
        <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">NDIS & SDA Investment</h1>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 z-20">
          Investing in NDIS and SDA propertoes isn't just aboput financial returns; it's about making a positive
          impact in the lives of people living with disabilities.
        </p>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-20 z-20">
          By partnering with us, you join a community of socially responsible investors who are dedicated to inclusive and 
          accessible housing solutions.
        </p>
        <VisLangBullets side="left" bottomOffset={"bottom-80"} width={"w-1/6"} animVisible={scrollPercentage > 0.550} />
        <VisLangBullets side="left" bottomOffset={"bottom-56"} width={"w-1/12"} animVisible={scrollPercentage > 0.555} />
        <VisLangBullets side="left" bottomOffset={"bottom-32"} width={"w-1/5"} animVisible={scrollPercentage > 0.560} />
      </div>
      <div className="z-30 bg-id-new-purple2 h-[32rem] flex flex-col justify-center items-center rounded-t-2xl sticky -top-4 overflow-hidden">
        <div className="absolute opacity-60">
          <img src={Tick} alt="tick" className={scrollPercentage > 0.60 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <p className="sm:text-2xl text-xl italic text-white z-30 font-museosanssemilight w-1/2 text-center">
          "Real estate cannot be lost or stolen, nor can it be carried away. Purchased with common sense, 
          paid for in full, and managed with reasonable care, it is about the safest investment in the world."
        </p>
        <h3 className="text-2xl text-white z-30 font-museosanssemibold mt-6">Franklin D. Roosevelt</h3>
      </div>
    </>
  )
}

export default CardFour;