import SMSF_Banner from '../components/SMSF/SMSF_Banner';
import SMSF_Statement from '../components/SMSF/SMSF_Statement';
import SMSF_TextImg from '../components/SMSF/SMSF_TextImg';
import SMSF_Services from '../components/SMSF/SMSF_Services';
import SMSF_CTA from '../components/SMSF/SMSF_CTA';
import PageFaqs from '../components/PageFaqs';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { faqActions } from '../../redux/faq';
import CircularLoader from '../../common/components/CircularLoader';



function SMSF() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { smsfFaqs } = useSelector((state: RootState) => {
    let smsfFaqs = state.faq.faqsByPage['SMSF'];
    return {
      smsfFaqs
    }
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFaqList();
  }, []);

  const getFaqList = async () => {
    try {
      setLoading(true);
      await dispatch(faqActions.fetchFaqListByPage({page: 'SMSF'}));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <SMSF_Banner/>
			<SMSF_Statement/>
			<SMSF_Services />
			<SMSF_TextImg/>
			<SMSF_CTA/>
      {loading ? <CircularLoader/> : <PageFaqs faqs={smsfFaqs}/> }
    </>

  );
}

export default SMSF;