
import NDIS_5 from '../../../static/NDIS_img/NDIS_5.png';

const SMSF_statement = () => {
    return  (
      <div className="w-full    text-white  ">
        <div className=" bg-id-blueShade flex flex-row justify-between mx-auto p-6 md:p-0">
          <div className="  md:m-14 md:pl-24 flex flex-col justify-center items-center md:items-start md:w-3/4">
            <div className="text-lg font-bold mb-5">
              MAKE A POSITIVE IMPACT
            </div>
            <div className="text-md md:text-xl text-center md:text-left md:pr-24">
              Investing in NDIS and SDA properties isn't just about financial returns; it's about making a positive impact in the lives of people living with disabilities. By partnering with us, you join a community of socially responsible investors who are dedicated to inclusive and accessible housing solutions.
            </div>
          </div>
          <div className='hidden md:flex justify-end m-0 p-0 items-center  w-1/4'>
            <img src={NDIS_5} alt="" className=' h-full object-cover object-center'/>
          </div>
        </div>
      </div>    
    );
  }   
  export default SMSF_statement;