import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createFileLink } from '../../helpers/api';

function PdfFilePicker({property, onChangeFormField, onClearFiles, onDeleteSpecificFile}: any) {
  const [pdfFiles, setPdfFiles] = useState<File[]>([]);

  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   const pdfFilesOnly = acceptedFiles.filter(
  //     (file) => file.type === 'application/pdf'
  //   );
  //   setPdfFiles((prevFiles) => [...prevFiles, ...pdfFilesOnly]);
  // }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    multiple: true,
    onDrop: (acceptedFiles: any) => {
      onChangeFormField('brochureUrl', [...property.brochureUrl, ...acceptedFiles]);
    }
  });

  const createPdfLabel = (file: any) => {
    if(file instanceof File) {
      return file.name;
    } else if (file instanceof String || typeof file === "string") {
      return file;
    } else {
      return '';
    }
  }

  return (
    <div className="container mx-auto">
      <h2>Upload Brochures</h2>
      <div
        {...getRootProps({
          className:
            'mt-1 flex justify-center items-center w-full h-32 border-2 border-dashed rounded-lg',
        })}
      >
        <input {...getInputProps()} />
        <p className="text-gray-400">
          Drag and drop property brochures (pdf) here, or click to select files.
        </p>
      </div>
      <button onClick={(e) => onClearFiles(e, 'brochureUrl')} className="p-2 bg-slate-300">
        Clear all brochures
      </button>
      <div className="flex flex-wrap mt-4">
        {property.brochureUrl.map((file: any, i: number) => (
          <div
            key={i}
            className="w-1/4 p-2 flex"
          >
            <a
              href={createFileLink(file)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              {createPdfLabel(file)}
            </a>
            <div className="inline-block mx-2">
              <button className="bg-gray-200 w-6 h-6" onClick={(e) => onDeleteSpecificFile(e, 'imageUrl', i)}>
                x
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PdfFilePicker;
