import React, { useState, useEffect } from 'react';
import { API, graphqlOperation, Storage } from "aws-amplify";
import MDEditor from '@uiw/react-md-editor';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { articleActions } from '../../redux/article';
import { ThunkDispatch } from '@reduxjs/toolkit';

import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";

import CircularLoader from '../../common/components/CircularLoader';

import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import ArticleForm from '../components/ArticleForm';
import { createFileLabel } from '../../helpers/parsers';

interface PayloadArticle {
  title: string;
  urlSlug: string;
  content: string;
  coverPhotoUrl: any;
  listPhotoUrl: any;
  customAuthorName: string;
  excerpt: string;
  displayDate: any;
  tags: any[];
  [x: string | number | symbol]: unknown;
}

const initialArticle = {
  title: '',
  urlSlug: '',
  content: '',
  coverPhotoUrl: '',
  listPhotoUrl: '',
  customAuthorName: '',
  excerpt: '',
  displayDate: new Date(),
  tags: [] as any
}

function ArticleAdd () {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [newArticle, setNewArticle] = useState<PayloadArticle>(initialArticle);
  const [loading, setLoading] = useState(false);

  const tagList  = useSelector((state: RootState) => state.article.tagList);
  const currentUser  = useSelector((state: RootState) => state.user.currentUser);

  // const {
  //   tagList,
  //   currentUser
  // } = useSelector((state: RootState) => {
  //   const { tagList } = state.article;
  //   const { currentUser } = state.user;
  //   return {
  //     tagList,
  //     currentUser
  //   }
  // });

  useEffect(() => {
    if(tagList.length === 0) {
      getTagList();
    }
  }, [])

  const getTagList = async() => {
    setLoading(true);
    await dispatch(articleActions.fetchArticleTagList());
    setLoading(false);
  }


  const onChangeFormField = (key: string, val: any) => {
    // if (key === 'tags') {
    //   setNewArticle({
    //     ...newArticle,
    //     [key]: newArticle.tags.includes(val) ? newArticle.tags.filter((t: any) => t !== val) : [...newArticle.tags, val]
    //   })
    // } else {
      setNewArticle({
        ...newArticle,
        [key]: val
      });
    //}
  }

  const uploadImages = async () => {
    try {
      var toUpload = [];
      if (newArticle.coverPhotoUrl instanceof File) {
        toUpload.push(newArticle.coverPhotoUrl);
      }
      if (newArticle.listPhotoUrl instanceof File) {
        toUpload.push(newArticle.listPhotoUrl);
      }
      await Promise.all(toUpload.map((file: any) => Storage.put(file.name, file).then(async (result) => {console.log(result)})));
    } catch (e) {
      console.log(e);
      toast.error('Error uploading files');
      throw "Upload Error";
    }
  }

  const createArticle = async() => {
    setLoading(true);
    try {
      uploadImages();
      let payload: any = {
        ...newArticle,
        displayDate: newArticle.displayDate.toISOString(),
        articleCreatedById: currentUser!.id,
        listPhotoUrl: createFileLabel(newArticle.listPhotoUrl),
        coverPhotoUrl: createFileLabel(newArticle.coverPhotoUrl)
      };
      //use toDateString to serialize
      // console.log(payload);
      // console.log(new Date(payload.displayDate).toDateString());
      delete payload.tags;

      if (!validateArticle(payload)) throw "Field Error";

      const processedArticle = await API.graphql(graphqlOperation(mutations.createArticle, {
        input: payload
      }));
      //create tag relationships
      console.log(processedArticle);
      //create tag connections here
      console.log(newArticle.tags);
      for await (const tag of newArticle.tags) {
        await API.graphql(graphqlOperation(mutations.createArticleArticleTags, {input: {
          //@ts-ignore
          articleId: processedArticle.data.createArticle.id,
          articleTagId: tag.value //value is id
        }}));
        console.log('wait for it...');
      };
      toast.success("Added new article.");
      setNewArticle(initialArticle);
    } catch (e) {
      console.log(e);
      toast.error("Error adding article.");
    } finally {
      setLoading(false);
    }
  }

  //"condition": { 
    //     "name": {
    //       "ne": "test"
    //     }
    //   }

  const validateArticle = (payload: any) => {
    let flag = true;
    Object.keys(payload).forEach((k, i) => {
      if(flag = true) {
        if ((payload[k] instanceof String || typeof payload[k] === 'string') && !payload[k]) {
          flag = false;
        } else if (Number.isNaN(payload[k])) {
          flag = false;
        } else {
          flag = true;
        }
      }
    });
    return flag;
  }


  return (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/articles'} className="block italic font-light">
            {"<< back to list"}
          </Link>
        </div>
        {/** form here */}
        <ArticleForm {...{onChangeFormField, tagList}} article={newArticle}/>

        <button onClick={createArticle} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4" disabled={loading}>
          SAVE ARTICLE
        </button>
      </div>
    </div>
  );
}

export default ArticleAdd;