import SMSF_1 from '../../../static/SMSF_img/SMSF_1.png';
import SMSF_2 from '../../../static/SMSF_img/SMSF_2.png';
import SMSF_3 from '../../../static/SMSF_img/SMSF_3.png';
import SMSF_4 from '../../../static/SMSF_img/SMSF_4.png';
import SMSF_5 from '../../../static/SMSF_img/SMSF_5.png';


const SMSF_Services = () => {

    return  (
      <div className="bg-id-grey6 w-full">
        <div className="h-fit md:py-14 p-5 container mx-auto flex flex-col justify-center items-center md:pr-8 md:pl-20 gap-2 md:gap-10">
          {/* Service 1 */}
          <div className="relative md:w-[85%] font-intern bg-white md:self-end flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
            <div className="hidden md:flex min-w-[20%]  justify-center items-center object-contain">
              <img src={SMSF_1} alt="SMSF_1" />
            </div>
            <div className=" flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                UNLOCKING YOUR SMSF PROPERTY INVESTMENT POTENTIAL
              </div>
              <div className="md:text-lg text-sm">
                SMSFs offer a powerful means of building wealth for retirement, and property investments have long been considered a stable and lucrative choice. By combining the two, you can harness the potential of real estate to grow your superannuation fund while enjoying the benefits of property ownership.              </div>
            </div>
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>        
          </div>
          {/* Service 2 */}
          <div className="relative md:w-[85%] font-intern bg-white md:self-start flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
          <div className="min-w-[20%] hidden md:flex justify-center items-center object-contain">
              <img src={SMSF_2} alt="service1"/>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                REDUCE RISK THROUGH EDUCATION
              </div>
              <div className="md:text-lg text-sm">
                Our team is well-versed in the complexities of self-managed super funds, and we have a deep understanding of the property market in Australia. We're dedicated to ensuring that you receive tailored guidance and education that aligns with your SMSF goals.              </div>
            </div>
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>          
          </div>
          {/* Service 3 */}
          <div className="relative md:w-[85%] font-intern bg-white md:self-end flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
          <div className="min-w-[20%] hidden md:flex justify-center items-center object-contain">
              <img src={SMSF_3} alt="service1"/>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                CUSTOMISED INVESTMENT SOLUTIONS
            </div>
            <div className="md:text-lg text-sm">
              We understand that SMSF investors have unique objectives. That's why we offer personalised investment solutions to match your preferences. Whether you're looking to diversify your SMSF portfolio with residential, commercial, or industrial properties, we have the knowledge and resources to help you achieve your financial objectives.
            </div>
            </div>
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>           
          </div>
          {/* Service 4 */}
          <div className="relative md:w-[85%] font-intern bg-white md:self-start flex flex-row py-8 px-8 gap-5 shadow-md rounded-md items-center justify-center">
          <div className="hidden md:flex min-w-[20%] justify-center items-center object-contain">
              <img src={SMSF_4} alt="service1"/>
            </div>
            <div className="flex flex-col gap-5">
              <div className="text-id-blue md:text-xs text-md font-semibold md:text-left text-center">
                COLLABORATION OF EXPERTS
              </div>
              <div className="md:text-lg text-sm">
                We collaborate closely with your trusted Financial Planner and a network of experts. This partnership ensures that the selected property aligns with your long-term financial goals, and our collective expertise allows us to conduct meticulous due diligence on potential investments, guaranteeing compliance with regulations and strong investment potential. 
              </div>
            </div> 
            <div className="hidden md:flex bg-white h-4 w-4 absolute top-[1rem] left-[-0.5rem] rotate-45"></div>        
          </div>
        </div>
      </div>    
    );
  }   
  export default SMSF_Services;