import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { validateEmail, validatePhone } from '../../helpers/validators';
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';
import SlidingToggleButton from '../components/HomePage/SlidingToggleButton';
import Fam5 from '../../static/fam5.jpg';
import Tick from '../../static/NewAssets/Tick.png';
import StockImage4 from '../../static/NewAssets/Stock Image 4.png';
import StockImage3 from '../../static/NewAssets/Stock Image 3.png';
import StockImage2 from '../../static/NewAssets/Stock Image 2.png';
import IDLogo from '../../static/NewAssets/InvestDoor Logo.png'
import VisLangBullets from '../components/HomePage/VisLangBullets';
import BookACall from '../components/HomePage/BookACall';
import ContactFormHome from '../components/HomePage/ContactFormHome';

const initialFormVals = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: ""
}

function WhatWeDo({isLoggedIn, scrollPercentage}: any) {
  
  const navigate = useNavigate();

  const [toggleBooking, setToggleBooking] = useState(false);
  const [contactForm, setContactForm] = useState(initialFormVals);
  const [isSending, setIsSending] = useState(false);
  const [errmsg, setErrmsg] = useState('');
  const [captchadone,setCaptchaDone]= useState(false);

  const captchaDone = () => {
    setCaptchaDone(true);
  }

  const captcha = (size:any) => {
    return (
    <div>
      <ReCAPTCHA
      onChange={captchaDone}
      size={size}
      theme="light"
      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY ?? ''} 
      />
    </div>
    )
  }

  const formValidate = () => {
    let flag = true;
    if (!validateEmail(contactForm.email)) {
      setErrmsg('Please enter a valid email address. (e.g. your@email.com)');
      toast('Please enter a valid email address. (e.g. your@email.com)', {type: 'error'});
      flag = false;
    } else if (!validatePhone(contactForm.phoneNumber)) {
      setErrmsg('Please enter a valid phone number with a minimum of 10 digits.');
      toast('Please enter a valid phone number with a minimum of 10 digits.', {type: 'error'});
      flag = false;
    } 
    return flag;
  }  

  const submitMessage = async () => {
    console.log('here');
    setErrmsg('');
    if(formValidate()) {
      setIsSending(true);
      try {
        let payload = contactForm;
        //await API.graphql(graphqlOperation(mutations.createInquiryMessage, {input: payload, authMode:'AWS_IAM'}));
        await API.graphql({
          query: mutations.createInquiryMessage,
          variables: {input: payload},
          authMode: 'API_KEY'
        });
        toast.success('Inquiry successfully sent.')
        console.log('success');
        setContactForm(initialFormVals);
      } catch (e) {
        console.log(e);
        console.log('error');
        toast.error("Error sending your message. Please try again.")
      }
      setIsSending(false);
    }
  }

  const setFormVals = (key: string, val: string) => {
    setContactForm({
      ...contactForm,
      [key]: val
    });
  }

  const scrollToBooking = () => {
    document.getElementById('book-a-call')?.scrollIntoView({behavior: 'smooth'});
  }

  const scrollToContact = () => {
    document.getElementById('contact-us')?.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <div className={"w-full my-0 opacity-1 relative snap-proximity snap-y scroll-smooth"}>
      {/* HERO */}
      <div className="relative snap-start sm:py-48 py-10 px-5 sm:px-20 snap-always lg:h-[102vh] bottom-0 
        flex flex-col bg-cover bg-no-repeat bg-top" 
        style={{backgroundImage: `linear-gradient(to right top, #786FB044, #332B7866), url("${Fam5}")`}}>

        <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">What we do</h1>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8">
          InvestDoor is more than just a property investment company - we're your trusted partner 
          on the path to financial freedom. We understand the Australian dream of property ownership,
          and we're passionate about empowering individuals to achieve it.
        </p>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-20">
          Our team is comprised of experienced property investors, advisors, and market experts. We leverage
          our combined knowledge and insights to create a comprehensive suite of resources that cater to both
          beginner and seasoned investoors.
        </p>
        <SlidingToggleButton buttonText={'Contact Us'} func={scrollToContact}/>
      </div>
      {/* OUR SERVICES */}
      <div className="bg-id-new-purple1 flex flex-col gap-12 rounded-t-3xl relative -mt-5 overflow-hidden pb-4 sm:pb-0">
        <div className="absolute opacity-50 left-1/3">
          <img src={Tick} alt="tick" className={scrollPercentage > 0.13 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <div>
          <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-10 px-10 sm:px-32">What we offer</h1>
        </div>
        <div className="flex flex-col sm:flex-row z-10 px-10 sm:px-32">
          <div>
            <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">Property Investment Strategies</h1>
            <p className="text-white text-2xl font-museosanssemilight z-10 sm:w-5/6">
              Learn proven strategies for navigating the Australian property market, making informed decisions,
              and building a profitable portfolio.
            </p>
          </div>
          <div>
            <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">Expert Guidance</h1>
            <p className="text-white text-2xl font-museosanssemilight z-10 sm:w-5/6">
              Connect with a network of qualified professionals who can provide personalised advice and support throughout
              your investment journey.
            </p>
          </div>
          
        </div>
        <div className="flex flex-col sm:flex-row z-10 px-10 sm:px-32">
          <div>
            <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">In-Depth Market Analysis</h1>
            <p className="text-white text-2xl font-museosanssemilight z-10 sm:w-5/6">
              Gain valuable insights into current market trends, property types, and regional outlooks to identify the best 
              investment opportunities.
            </p>
          </div>
          <div>
            <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">Educational Resources</h1>
            <p className="text-white text-2xl font-museosanssemilight z-10 sm:w-5/6">
              Explore a library of informative articles, guides, and webinars to deepen your understanding 
              of property investment and market dynamics.
            </p>
          </div>
          
        </div>
        
        <h1 className="text-2xl sm:text-4xl font-bold text-white z-10 font-museosanssemilight text-center sm:mt-10">
          A <span className="font-museosanssemibold">simple</span> and <span className="font-museosanssemibold">trusted</span> investment process.
        </h1>
      </div>
      {/* WHY INVESTDOOR */}  
      <div className={"w-full h-[108vh] sm:-mt-16 my-0 bg-cover opacity-1 rounded-t-3xl sm:sticky -top-12"}>
      
        <div className="relative h-[108vh] bottom-0 flex flex-col sm:py-40 py-10 sm:px-20 px-5 bg-cover bg-no-repeat bg-top" style={{backgroundImage: `url("${StockImage4}")`}}>
          <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">Why Choose InvestDoor?</h1>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">We simplify the complex</h1>
          <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-2">
            We translate complex property investment concepts into clear, actionable steps you can implement with confidence.
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">We prioritise your success</h1>
          <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-2">
            Your financial goals are our priority. We provide the tools and resources you need to achieve sustainable property investment success.
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white mt-5">We empower informed decisions</h1>
          <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-2">
            Backed by data and expert insights, we help you make data-driven decisions that align with your financial goals and tisk tolerance.
          </p>
        </div>
      </div>
      {/* CTA 1 */}
      <div className="relative bg-id-new-purple3 h-[32rem] flex flex-col justify-center items-center rounded-t-2xl -top-4 overflow-hidden">
        <div className="absolute opacity-60">
          <img src={IDLogo} alt="tick" className={scrollPercentage > 0.25 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <h1 className="sm:text-4xl text-xl font-museosanssemibold text-white mt-5 z-10 pb-6 text-center">Let's turn your property dreams into reality</h1>
        <p className="sm:text-2xl text-xl text-white font-museosanssemilight w-1/2 text-center z-10 pb-12">
          Join us on your property investment journey and unlock a world of exciting possibilities.
        </p>
        <SlidingToggleButton buttonText={'Book a meeting'} func={scrollToBooking}/>
      </div>
      {/* SMSF */}
      <div className={"w-full overflow-hidden my-0 bg-cover opacity-1 rounded-t-3xl z-10 sm:sticky -top-5 -mt-12"}>
        <div className="relative h-[110vh] bottom-0 flex flex-col py-20 sm:py-80 px-5 sm:px-20 bg-cover bg-no-repeat bg-top" style={{backgroundImage: `linear-gradient(to right top, #786FB044, #332B7866), url("${StockImage3}")`}}>
          <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white">Secure your financial future</h3>
          <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">SMSF Investing</h1>
          <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-32">
            Investing in your SMSF isn't just about securing your retirement; it's about taking control
            of your financial destiny and shaping a future that's as divers and dynamic as the property investments
            you choose.
          </p>
          <div className="self-center">
            <h1 className="text-white font-museosans sm:text-5xl text-2xl">
              <span className="font-museosanssemibold">Secure</span> your financial future
            </h1>
          </div>
        </div>
      </div>

      {/* SMSF 2 */}
      <div className="relative z-30 bg-id-new-purple1 flex flex-col rounded-t-2xl -top-4 overflow-hidden">
        <div className="absolute opacity-40 left-1/3 top-10">
          <img src={IDLogo} alt="tick" className={scrollPercentage > 0.39 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <div className="z-30 p-6 sm:p-20">
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Unlocking your SMSF property investment portal
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            SMSFs offer a powerful means of building wealth for retirement, and property investments have long been considered a stable and 
            lucrative choice. By combining the two, you can harness the potential of real estate to grow your superannuation fund 
            while enjoying the benefits of property ownership.
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Reduce risk through education
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            Our team is well-versed in the complexities of self-managed super funds, and we have a deep understanding of the property market 
            in Australia. We're dedicated to ensuring that you receive tailred guidance and education that aligns with your SMSF goals.
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Customised investment solutions
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            We understand that SMSF investors have unique objectives. That's why we offer personalised investment solutions to match your preferences. 
            Whether you're looking to diversify your SMSF portfolio with residential, commercial, or industrial properties, we have the knowledge 
            and resources to help you achieve your financial objectives.
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Collaboration of experts
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            We collaborate closely with trusted Financial Planners and a network of experts. This partnership ensures that the selected property 
            aligns with your long-term financial goals, and our collective expertise allows us to conduct meticulous due diligence 
            on potential investments, guaranteeing compliance with regulations and strong investment potential.
          </p>
        </div>
      </div>

      {/* CTA 2 */}
      <div className="relative z-30 bg-id-new-purple3 h-[32rem] flex flex-col justify-center items-center rounded-t-2xl -top-4 overflow-hidden">
        <div className="absolute opacity-60">
          <img src={IDLogo} alt="tick" className={scrollPercentage > 0.45 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <h1 className="sm:text-4xl text-xl font-museosanssemibold text-white mt-5 z-20 pb-6">Secure your financial future</h1>
        <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight w-1/2 text-center pb-12">
          Investing in SMSF properties is not just about building wealth; it's about securing your financial future with a 
          tangible and appreciating asset.
        </p>
        <SlidingToggleButton buttonText={'Book a meeting'} func={scrollToBooking}/>
      </div>

      {/* NDIS SDA */}
      <div className="z-30 flex flex-col py-16 sm:py-60  sm:px-20 px-5 bg-cover bg-no-repeat bg-top sm:sticky -top-8 -mt-14" style={{backgroundImage: `linear-gradient(to right top, #786FB044, #332B7866), url("${StockImage2}")`}}>
        <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white">Make a positive impact</h3>
        <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">NDIS & SDA Investment</h1>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 z-20">
          Investing in disability is not just about providing financial support; it's about investing in the infinite potential of 
          human resilience and the belief that everyone, regardless of their abilities, can contribute meaningfully to our communities and society.
        </p>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 z-20">
          We understand the importance of inclusive housing and the immense potential within the National Disability Insurance System (NDIS) 
          and Specialist Disability Accommodation (SDA) sectors. 
        </p>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-20 z-20">
          With years of experience and a commitment to making a positive impact, we're here to guide you through the exciting world of disability-inclusive 
          property investments.
        </p>
        <div className="mt-20">
          <SlidingToggleButton buttonText={'Contact Us'} func={scrollToContact}/>
        </div>
      </div>

      {/* NDIS SDA 2 */}

      <div className="relative z-30 bg-id-new-purple1 flex flex-col rounded-t-2xl -top-4 overflow-hidden">
        <div className="absolute opacity-40 left-1/3 top-10">
          <img src={IDLogo} alt="tick" className={scrollPercentage > 0.60 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div>
        <div className="z-30 p-6 sm:p-20">
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Why invest in NDIS and SDA properties
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            Investing in NDIS and SDA properties offers a unique opportunity to make a difference in people's lives while securing 
            your financial future. The NDIS aims to support Australians living with disabilities by providing access to quality 
            accommodation. By becoming an SDA property investor, you contribute to this mission while benefitting from the stability 
            and growth potential of the property market.
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Our expertise
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            Our team of experts is well-versed in the intricate requirements of these sectors. We stay up-to-date with NDIS 
            regulations, property standards, and market trends to provide you with the most informed guidance. 
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Tailored investment solutions
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            We recognise that each investor's needs and goals are unique. That's why we offer tailored investment solutions to suit your preferences. 
            Whether you're interested in purchasing an existing SDA property or developing new SDA-compliant dwellings, we have the expertise 
            and resources to guide you through the process. 
          </p>
          <h1 className="sm:text-4xl text-xl font-robotoslabbold text-white my-8 z-20">
            Support and compliance
          </h1>
          <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight sm:w-1/2 mb-8">
            Navigating the NDIS and SDA landscape can be complex. We're here to help you understand and navigate the regulatory 
            framework, ensuring that your investments comply with all relevant standards. With our support, you can confidently invest in properties 
            that meet the needs of NDIS participants.
          </p>
        </div>
      </div>

      {/* CTA 3 */}
      <div className="relative z-30 bg-id-new-purple3 flex flex-col justify-center items-center rounded-t-2xl -top-4 overflow-hidden pb-12">
        {/* <div className="absolute opacity-60">
          <img src={IDLogo} alt="tick" className={scrollPercentage > 0.60 ? "animate-fade-right animate-duration-[1000ms]" : "animate-fade animate-ease-linear animate-reverse"} />
        </div> */}
        <h1 className="sm:text-4xl text-xl font-museosanssemibold text-white mt-5 z-20 pb-6">Make a positive impact</h1>
        <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight w-1/2 text-center">
          Investing in NDIS and SDA properties isn't just about financial returns; it's about making a positive impact in the lives 
          of people living with disabliities. 
        </p>
        <p className="sm:text-2xl text-xl text-white z-30 font-museosanssemilight w-1/2 text-center mt-4 pb-12">
          By partnering with us, you join a community of socially responsible investors who are dedicated to inclusive 
          and accessible housing solutions.
        </p>
        <SlidingToggleButton buttonText={'Book a meeting'} func={scrollToBooking}/>
      </div>


      {/* same as home*/}
      <BookACall scrollPercentage={scrollPercentage}/>
      <ContactFormHome scrollPercentage={scrollPercentage} {...{contactForm, setFormVals, isSending, submitMessage, errmsg}}/>
    </div>
  )
}

export default WhatWeDo;

