const HeroText = ({
                    text,
                    description,
                    image,
                  }:
                  {
                    text:any,
                    description: any,
                    image: any,
                  }
                  ) => {
    return (
      <div className="w-full bg-home-hero-blue">
        <div className="py-10 px-2 md:container md:mx-auto">   
              <div className="md:flex md:flex-row">
                <div className="flex flex-col justify-center  md:basis-1/2">
                  {/*Header */}
                  <div className="text-3xl font-bold text-id-blue text-center px-auto md:text-5xl md:text-left">
                    {text}
                  </div>
                  {/*Article */}
                  <div className="font-inter mt-3 text-neutral-600 text-center text-sm md:text-left  md:mt-10 md:text-lg md:whitespace-pre-line">
                    {description}
                  </div>
                </div>
                  {/*Image*/}
                <div className="hidden md:mx-auto md:my-auto md:flex md:basis-1/2 md: ">
                  {image && <img className="max-h-full w-auto" src={image} alt={text}/>}
                </div>
              </div>

        </div>
      </div>    
    );
}

export default HeroText;
