import {Modal, Button} from 'flowbite-react';

function ConfirmModal (props: any) {
  const {modalVisible, onOk, onCancel, message, disabled=false} = props;

  return (
    <Modal
      show={modalVisible}
      size="md"
      popup={true}
    >
      <Modal.Header onClick={(e: any) => {
        e.target.nodeName === "svg" ?
        console.log('x clicked') : console.log('outside');
      }}/>
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {message}
          </h3>
          <div className="flex justify-center gap-4">
            <Button
              color="info"
              onClick={onOk}
              disabled={disabled}
            >
              {disabled ? "Please wait..." : "Yes, I'm sure"}
            </Button>
            <Button
              color="gray"
              onClick={onCancel}
            >
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmModal;