import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql-custom/queries-custom';
import {reject, unionBy} from 'lodash-es';

const PAGE_LIMIT = 20;
const SEARCH_PAGE_LIMIT = 200;

export interface SerializedProperty {
  pid: string;
  name: string;
  price: string;
  address: string;
  state: string;
  primaryImageUrl: any;
  imageUrl: any[];
  brochureUrl: any[];
  description: string;
  propertyStatus: string;
  propertyPropertyTypeId: string;
  propertyAddedById: string;
  listAvailability: string;
  bedrooms: string;
  bathrooms: string;
  carspaces: string;
  rentalIncome: string;
  minimumRentalYield: string;
  contractType: string;
  coordinateX: string;
  coordinateY: string;
}

export interface PayloadProperty {
  pid: string;
  name: string;
  price: number;
  address: string;
  state: string;
  primaryImageUrl: any;
  imageUrl: any[];
  brochureUrl: any[];
  description: string;
  propertyStatus: string;
  propertyPropertyTypeId: string;
  propertyAddedById: string;
  listAvailability: string;
  bedrooms: number;
  bathrooms: number;
  carspaces: number;
  rentalIncome: number;
  minimumRentalYield: number;
  contractType: string;
  coordinateX: number;
  coordinateY: number;
  [x: string | number | symbol]: unknown;
}

interface PropertyState {
  propertyList: PayloadProperty[];
  adminSearchedPropertyList: PayloadProperty[];
  searchedPropertyList: PayloadProperty[];
  propertyNextToken?: String | null;
  adminSearchPropertyNextToken?: String | null;
  searchPropertyNextToken: String | null;
}

const fetchPropertyList = createAsyncThunk(
  'properties/list',
  async (_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(graphqlOperation(queries.listProperties, {limit: PAGE_LIMIT}));
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchPropertyById = createAsyncThunk(
  'properties/get',
  async (_: any, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(
        {
          query: customQueries.getPropertyCustom, 
          variables: {id: _.id}, 
          authMode: 'API_KEY'
        }
      );
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);
  
const adminSearchProperties = createAsyncThunk(
  'admin/properties/search',
  async(_: any, {rejectWithValue, getState}) => 
    searchProperties(_, rejectWithValue)  
);

const userSearchProperties = createAsyncThunk(
  'properties/search',
  async(_: any, {rejectWithValue, getState}) => 
    searchProperties(_, rejectWithValue)
);

const searchProperties = async (_: any, rejectWithValue: any) => {
  //TODO make helper function to generate state array search
  let andQuery: any = [ {or: [{listAvailability: {matchPhrase: "AVAILABLE"}}, {listAvailability: {matchPhrase: "SOLD"}}]} ];
  let searchFilterObj = {};

  if (!!_.name) {
    andQuery.push({ name: { matchPhrasePrefix: _.name } });
  }
  
  //states
  if (!!_.states && _.states.length > 0) {
    let stateQuery:any = [];
    _.states.forEach((ausState: string) => {
      stateQuery.push({ state: { matchPhrase: ausState } });
    });
    andQuery.push({or: stateQuery});
  }

  //propertyTypeId
  if (!!_.propertyTypeIds &&_.propertyTypeIds.length > 0) {
    let propTypeQuery:any = [];
    _.propertyTypeIds.forEach((ptId: string) => {
      propTypeQuery.push({ propertyPropertyTypeId: {matchPhrase: ptId} });
    });
    andQuery.push({or: propTypeQuery});
  }

  //propertyStatus
  if (!!_.statuses && _.statuses.length > 0) {
    let statusQuery:any = [];
    _.statuses.forEach((status: string) => {
      statusQuery.push({ propertyStatus: {matchPhrase: status} });
    });
    andQuery.push({or: statusQuery});
  }

  //contracttype
  if (!!_.contractTypes && _.contractTypes.length > 0) {
    let contractQuery:any = [];
    _.contractTypes.forEach((contractType: string) => {
      contractQuery.push({ contractType: {matchPhrase: contractType} });
    });
    andQuery.push({or: contractQuery});
  }

  // and: [
  //   { price: { ge: minPrice } },
  //   { price: { le: maxPrice } }
  // ]
  //TODO validate min max input in component, not here. should pass numerical values, to evaluate zeroes
  //price, rentalIncome, *minRentalYield, bedrooms, bathrooms, carspaces
  if (!!_.minPrice || !!_.maxPrice) {
    andQuery.push(
      {
        and: [
          { price: { gte: _.minPrice } },
          { price: { lte: _.maxPrice } },
        ]
      }
    )
  }

  if (!!_.minRentalIncome || !!_.maxRentalIncome) {
    andQuery.push(
      {
        and: [
          { rentalIncome: { gte: _.minRentalIncome } },
          { rentalIncome: { lte: _.maxRentalIncome } },
        ]
      }
    )
  }

  if (!!_.minRentalYield) {
    andQuery.push({ minimumRentalYield: { gte: _.minRentalYield }})
  }

  if (!!_.minBedrooms || !!_.maxBedrooms) {
    andQuery.push({
      and: [
        { bedrooms: { gte: _.minBedrooms } },
        { bedrooms: { lte: _.maxBedrooms } },
      ]
    })
  }

  if (!!_.minBathrooms || !!_.maxBathrooms) {
    andQuery.push(
      {
        and: [
          { bathrooms: { gte: _.minBathrooms } },
          { bathrooms: { lte: _.maxBathrooms } },
        ]
      }
    )
  }

  if (!!_.minCarspaces || !!_.maxCarspaces) {
    andQuery.push(
      {
        and: [
          { carspaces: { gte: _.minCarspaces } },
          { carspaces: { lte: _.maxCarspaces } },
        ]
      }
    )
  }

  if(andQuery.length > 0) {
    searchFilterObj = {
      archived : { ne: true },
      and: andQuery
    };
  }

  try {
    // let raw_payload = await API.graphql(graphqlOperation(queries.searchProperties, {
    //   filter: searchFilterObj,
    //   limit: SEARCH_PAGE_LIMIT
    // }));
    let raw_payload = await API.graphql({
      query: customQueries.searchPropertiesCustom,
      variables: {
        filter: searchFilterObj,
        limit: SEARCH_PAGE_LIMIT
      },
      authMode: 'API_KEY'
    });
    console.log(raw_payload);
    //@ts-ignore
    return raw_payload.data;
  } catch (e) {
    console.log(e);
    return rejectWithValue(e);
  }
} 


export const propertySlice = createSlice({
  name: 'property',
  initialState: {
    propertyList: [],
    adminSearchedPropertyList: [],
    searchedPropertyList: [],
    propertyNextToken: null,
    adminSearchPropertyNextToken: null,
    searchPropertyNextToken: null,
  } as PropertyState,
  reducers: {
    setProperties: (state, action) => {
      state.propertyList = action.payload;
    },
    setPropertyNextToken: (state, action) => {
      state.propertyNextToken = action.payload;
    },
    setSearchedProperties: (state, action) => {
      state.searchedPropertyList = action.payload;
    },
    setSearchedPropertyNextToken: (state, action) => {
      state.searchPropertyNextToken = action.payload;
    },
    deletePropertyById: (state, action) => {
      state.propertyList = state.propertyList.filter((p) => p.id !== action.payload);
    },
    updatePropertyById: (state, action) => {
      const idx = state.propertyList.findIndex((p) => p.id === action.payload.id);
      state.propertyList[idx] = {...state.propertyList[idx], ...action.payload};
    },
    updateSearchedPropertyById: (state, action) => {
      const idx = state.searchedPropertyList.findIndex((p) => p.id === action.payload.id);
      state.searchedPropertyList[idx] = {...state.searchedPropertyList[idx], ...action.payload};
    }
  },
  extraReducers: (builder) => {
    //TODO add case for loading and failed
    builder.addCase(fetchPropertyList.fulfilled, (state, action) => {
      state.propertyList = unionBy(state.propertyList, action.payload.listProperties.items, 'id');
      state.propertyNextToken = action.payload.listProperties.nextToken;
    });
    //TODO add case for loading and failed for this too
    builder.addCase(adminSearchProperties.fulfilled, (state, action) => {
      //clear first
      state.adminSearchedPropertyList = [];
      state.adminSearchedPropertyList = action.payload.searchProperties.items;
      state.searchPropertyNextToken = action.payload.searchProperties.nextToken;
    });
    builder.addCase(userSearchProperties.fulfilled, (state, action) => {
      //clear first
      state.searchedPropertyList = [];
      state.searchedPropertyList = action.payload.searchProperties.items;
      state.searchPropertyNextToken = action.payload.searchProperties.nextToken;
    });
    builder.addCase(fetchPropertyById.fulfilled, (state, action) => {
      state.searchedPropertyList = unionBy(state.searchedPropertyList, [action.payload.getProperty], 'id');
    });
  }
});

export const propertyActions = {
  fetchPropertyList,
  adminSearchProperties,
  userSearchProperties,
  fetchPropertyById,
  ...propertySlice.actions
};

export default propertySlice.reducer;