import fam2 from '../../../static/fam2.jpg';
import StockImage from '../../../static/NewAssets/Stock Image 3.png'

function CardTwo({scrollPercentage}: any) {
  return (
    <div className={"w-full h-[110vh] overflow-hidden my-0 bg-cover opacity-1 rounded-t-3xl z-10 sticky -top-5"}>
      <div className="relative h-[110vh] bottom-0 flex flex-col sm:py-40 py-10 sm:px-20 px-5 bg-cover bg-no-repeat bg-top" style={{backgroundImage: `url("${StockImage}")`}}>
        <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white">Secure your financial future</h3>
        <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">SMSF Investing</h1>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-20">
          Investing in your SMSF isn't just about securing your retirement; it's about taking control
          of your financial destiny and shaping a future that's as divers and dynamic as the property investments
          you choose.
        </p>
      </div>
    </div>
  )
}

export default CardTwo;