import React, {useRef, useState, useEffect} from "react";
import {AccordionData} from './types';

const AccordionItem = ({ data, isOpen, btnOnClick }: {
  data:AccordionData;
  isOpen: boolean;
  btnOnClick: () => void;
}) => {

  const contentRef=useRef<HTMLDivElement>(null);
  const [height, setHeight]=useState(0);
  
  useEffect(()=> {
    if (isOpen) {
      const contentEL = contentRef.current as HTMLDivElement;
      setHeight(contentEL.scrollHeight)
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <li className={`accordion-item ${isOpen ? 'active':''}`}>
      <h2 className="accordion-item-title py-2 px-4">
          <button className="accordion-item-btn font-epilogue font-bold text-lg" onClick={btnOnClick}>{data.title}</button>
      </h2>
      <div className="accordion-item-container" style={{height}}>
        <div ref={contentRef} className="accordion-item-content py-2">{data.content}</div>
      </div>
    </li>
  )
}

export default AccordionItem;