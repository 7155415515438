export const listArticleArticleTagsCustom = /* GraphQL */ `query ListArticleArticleTags(
  $filter: ModelArticleArticleTagsFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticleArticleTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      articleId
      articleTagId
      article {
        id
        urlSlug
        title
        content
        excerpt
        customAuthorName
        tags {
          items {
            id
            articleId
            articleTagId
            articleTag {
              id
              urlSlug
              name
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        createdBy {
          id
          firstName
          lastName
          cognitoId
          email
          phoneNumber
          address
          birthdate
          lastIp
          ausBusinessNumber
          accountTier
          createdAt
          updatedAt
          __typename
        }
        coverPhotoUrl
        listPhotoUrl
        displayDate
        createdAt
        updatedAt
        articleCreatedById
        __typename
      }
      articleTag {
        id
        urlSlug
        name
        articles {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
`;

export const listArticlesCustom = /* GraphQL */ `query ListArticles(
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      archived
      urlSlug
      title
      content
      excerpt
      customAuthorName
      tags {
        items {
          id
          articleId
          articleTagId
          articleTag {
            id
            urlSlug
            name
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      createdBy {
        id
        firstName
        lastName
        cognitoId
        email
        phoneNumber
        address
        birthdate
        lastIp
        ausBusinessNumber
        accountTier
        createdAt
        updatedAt
        __typename
      }
      coverPhotoUrl
      listPhotoUrl
      displayDate
      createdAt
      updatedAt
      articleCreatedById
      __typename
    }
    nextToken
    __typename
  }
}
`;

export const searchPropertiesCustom = /* GraphQL */ `query SearchProperties(
  $filter: SearchablePropertyFilterInput
  $sort: [SearchablePropertySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePropertyAggregationInput]
) {
  searchProperties(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      pid
      name
      price
      address
      state
      primaryImageUrl
      imageUrl
      brochureUrl
      description
      addedBy {
        id
        firstName
        lastName
        cognitoId
        email
        phoneNumber
        address
        birthdate
        lastIp
        ausBusinessNumber
        accountTier
        createdAt
        updatedAt
        __typename
      }
      interestedUser {
        id
        firstName
        lastName
        cognitoId
        email
        phoneNumber
        address
        birthdate
        lastIp
        ausBusinessNumber
        accountTier
        createdAt
        updatedAt
        __typename
      }
      propertyStatus
      propertyType {
        id
        name
        abbr
        description
        createdAt
        updatedAt
        __typename
      }
      listAvailability
      bedrooms
      bathrooms
      carspaces
      rentalIncome
      minimumRentalYield
      contractType
      coordinateX
      coordinateY
      archived
      createdAt
      updatedAt
      userPropertyInterestsId
      propertyAddedById
      propertyInterestedUserId
      propertyPropertyTypeId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
`

export const getArticleCustom = /* GraphQL */ `query GetArticle($id: ID!) {
  getArticle(id: $id) {
    id
    urlSlug
    title
    content
    excerpt
    customAuthorName
    tags {
      items {
        id
        articleId
        articleTagId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      __typename
    }
    coverPhotoUrl
    listPhotoUrl
    displayDate
    createdAt
    updatedAt
    archived
    articleCreatedById
    __typename
  }
}
`

export const getPropertyCustom = /* GraphQL */ `query GetProperty($id: ID!) {
  getProperty(id: $id) {
    id
    pid
    name
    price
    address
    state
    imageUrl
    brochureUrl
    description
    addedBy {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    interestedUser {
      id
      firstName
      lastName
      cognitoId
      email
      phoneNumber
      address
      birthdate
      lastIp
      ausBusinessNumber
      accountTier
      createdAt
      updatedAt
      __typename
    }
    propertyStatus
    propertyType {
      id
      name
      abbr
      description
      createdAt
      updatedAt
      __typename
    }
    listAvailability
    bedrooms
    bathrooms
    carspaces
    rentalIncome
    minimumRentalYield
    contractType
    coordinateX
    coordinateY
    archived
    createdAt
    updatedAt
    userPropertyInterestsId
    propertyAddedById
    propertyInterestedUserId
    propertyPropertyTypeId
    __typename
  }
}
`

export const listPropertyMessagesCustom = /* GraphQL */ `query ListPropertyMessages(
  $filter: ModelPropertyMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPropertyMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      sysGen
      readByUserIds
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        cognitoId
        email
        phoneNumber
        address
        birthdate
        lastIp
        ausBusinessNumber
        accountTier
        createdAt
        updatedAt
        __typename
      }
      property {
        id
        pid
        name
        price
        address
        state
        primaryImageUrl
        imageUrl
        brochureUrl
        description
        propertyStatus
        listAvailability
        bedrooms
        bathrooms
        carspaces
        rentalIncome
        minimumRentalYield
        contractType
        coordinateX
        coordinateY
        createdAt
        updatedAt
        userPropertyInterestsId
        propertyAddedById
        propertyInterestedUserId
        propertyPropertyTypeId
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
`
