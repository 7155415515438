import React, { useState } from 'react'
import id_logo from '../../static/NewAssets/idlogo-whitetext.png'

import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Auth } from 'aws-amplify'
import { userActions } from '../../redux/user'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { Popover, Drawer } from 'flowbite-react'
import { HiPhone } from "react-icons/hi2";

interface MenuItem {
  path: any,
  label: string,
}

const menu_items_array: Array<MenuItem> = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'What we do',
    path: '/what-we-do',
  },
  {
    label: 'Resources',
    path: [      
      {
        path: '/tools-and-calculators',
        label: 'Tools and Calculators',
      },
      {
        path: '/education',
        label: 'Education',
      },
      {
        path: '/market-research',
        label: 'Market Research',
      } 
      // {
      //   path: '/finance-calculators',
      //   label: 'Finance Calculators',
      // }
    ],
  },
  {
    label: 'Classroom',
    path: [
      {
        path: '/blog',
        label: 'Blog',
      },
      {
        path: '/blog/:aId',
        label: 'Article',
      },
    ],
  },
  {
    label: 'Articles',
    path: '/blog',
    
  },
  {
    label: 'Contact Us',
    path: '/contact',
  }
]

const resourcesItem = menu_items_array.find(item => item.label === 'Resources');
// const whatWeDoItem = menu_items_array.find(item => item.label === 'What we do');

const Navigation = () => {
  const dispatch = useDispatch()
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleCloseDrawer = () => setIsDrawerOpen(false)

  const currentUser  = useSelector((state: RootState) => state.user.currentUser)
  const currentCognitoUser  = useSelector((state: RootState) => state.user.currentCognitoUser)

  const handleLogout = async () => {
    try {
      setIsDrawerOpen(false)
      await Auth.signOut()
      dispatch(userActions.setCurrentUser(null))
      dispatch(userActions.setCurrentCognitoUser(null))
    } catch (error) {
      console.error('Error signing out', error)
    }
  }

  const resourceDropDown = (
    <div className=''>
      {resourcesItem?.path.map((item: { path: string, label: string }, index: number) => (
        <Link 
          key={index} 
          to={item.path}
          className='text-gray-600 text-left outline-none'
        >
          <div className='w-full hover:text-id-new-purple3 hover:bg-gray-200 py-3 px-4'>
            {item.label}
          </div>
        </Link>
      ))}
    </div>
  )

  // const whatWeDoDropdown = (
  //   <div className=''>
  //     {whatWeDoItem?.path.map((item: { path: string, label: string }, index: number) => (
  //       <Link 
  //         key={index} 
  //         to={item.path}
  //         className='text-gray-600 text-left outline-none'
  //       >
  //         <div className='w-full hover:text-id-new-purple3 hover:bg-gray-200 py-3 px-4'>
  //           {item.label}
  //         </div>
  //       </Link>
  //     ))}
  //   </div>
  // )

  const profileDropDown = (
    <div className='flex flex-col  text-gray-600 text-left outline-none'>
      {currentUser &&
        <div className='py-3 px-4 font-robotoslabbold'>
          Welcome, {`${currentUser.firstName} ${currentUser.lastName}`}!
        </div>
      }
      {/* <Link to={'/user-profile'}>
        <div className='w-full hover:text-id-new-purple3 hover:bg-gray-200 py-3 px-4'>
          Profile
        </div>
      </Link> */}
      <button 
        onClick={handleLogout} 
        className='w-full hover:text-id-new-purple3 hover:bg-gray-200 text-left py-3 px-4'
      >
        Log Out
      </button>
    </div>
  )

  return (
    <header className='fixed top-0 w-full h-fit flex justify-between z-10'>
      {/* logo */}
      <div className='px-6 py-4'>
        <Link to={'/'}>
          <img src={id_logo} alt='Investdoor' className='max-h-[3em] ' />
        </Link>
      </div>

      {/* mobile */}
      <div className='xl:hidden flex px-6'>
        <button 
          onClick={() => setIsDrawerOpen(true)}
          className='text-white md:text-5xl text-2xl'
        >
          &#9776;
        </button>
      </div>
      <Drawer 
        open={isDrawerOpen} 
        onClose={handleCloseDrawer}
        position='right'
      >
        <Drawer.Items className='font-robotoslab'>
          <button 
            onClick={() => setIsDrawerOpen(!isDrawerOpen)} 
            className='text-4xl leading-none absolute right-0 top-0 px-5 py-3 hover:text-id-new-magenta'
          >&times;</button>
          <div className='flex flex-col gap-3 px-3 py-3'>
            {currentUser &&
              <div className='py-3 font-robotoslabbold'>Welcome, {`${currentUser.firstName} ${currentUser.lastName}`}!</div>
            }
            
            {!currentCognitoUser
              ? <Link to={'/signup'} className='hover:text-id-new-purple2 hover:font-robotoslabbold'>Sign Up</Link>
              : <Link to={'/property/search'} className='hover:text-id-new-purple2 hover:font-robotoslabbold'>Property Portal</Link>
            }
            {!currentCognitoUser 
              ? <Link to={'/login'} className='hover:text-id-new-purple2 hover:font-robotoslabbold'>Log In</Link>
              : <>
                  {/* <Link to={'/user-profile'}>
                    <div className=' hover:text-id-new-purple2 hover:font-robotoslabbold'>
                      Profile
                    </div>
                  </Link> */}
                  <button 
                    onClick={handleLogout} 
                    className='hover:text-id-new-purple2 hover:font-robotoslabbold text-left'
                  >
                    Log Out
                  </button>
                </>
            }
            <hr className='my-3' />
            {menu_items_array.map((item, index) => (
              <div key={index}>
                {Array.isArray(item.path)
                  ? <>
                      {item.label}
                      {item.path.map((subItem, subIndex) => (
                        <Link 
                          to={subItem.path} 
                          key={subIndex} 
                        >
                          <div className='ml-3 mt-2 hover:text-id-new-purple2 hover:font-robotoslabbold'>{subItem.label}</div>
                        </Link>
                      ))}
                    </>
                  : <Link 
                      to={item.path} 
                      className='hover:text-id-new-purple2 hover:font-robotoslabbold'
                    >
                      {item.label}
                    </Link>
                }
              </div>
            ))}
          </div>
        </Drawer.Items>
      </Drawer>
      
      {/* desktop */}
      <nav className='xl:flex hidden text-white font-robotoslab font-bold text-sm max-h-20 overflow-hidden'>
        <div className='flex gap-9 px-9 py-7 pr-24 bg-id-new-purple1/80 rounded-l-full'>
          <Popover
            content={resourceDropDown}
            aria-labelledby='area-popover'
            trigger='hover'
          >
            <div className="cursor-pointer">Resources</div>
          </Popover>
          <Link to={'/what-we-do'}>What we do</Link>
          <Link to={'/blog'}>Articles</Link>
          <Link to={'/contact'}>Contact Us</Link>
        </div>
        <div className='flex gap-9 px-9 py-7 pr-24 -ml-16 bg-id-new-purple2 rounded-l-full'>
        {!currentCognitoUser
          ? <Link to={'/signup'}>Sign Up</Link>
          : <Link to={'/property/search'}>Property Portal</Link>
        }
        </div>
        <div className='flex gap-9 px-9 py-7 pr-24 -ml-16 bg-id-new-purple3 rounded-l-full'>
          {!currentCognitoUser 
            ? <Link to={'/login'}>Log In</Link>
            : <Popover
                content={profileDropDown}
                aria-labelledby='area-popover'
                trigger='hover'
              >
                <AccountBoxIcon className='mb-1 ml-2 hidden md:flex' fontSize='medium'/>
              </Popover>
          }
        </div>
        <div className='flex items-center gap-3 px-9 py-7 -ml-16 bg-id-new-pink1 rounded-l-full'>
          <HiPhone />
          <div>03 7076 1018</div>
        </div>
      </nav>
    </header>
  )
}

export default Navigation;