import {PROPERTY_AVAILABILITY, PROPERTY_STATUSES} from '../constants/fields';

export const createFileLabel = (file: any) => {
  if(file instanceof File) {
    return file.name;
  } else if (file instanceof String || typeof file === "string") {
    return file;
  } else {
    return '';
  }
}

export const dollarParse = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const parseAvailability = (rawval: string) => {
  return PROPERTY_AVAILABILITY.find(p => p.value === rawval)?.label;
}

export const parseStatus = (rawval: string) => {
  return PROPERTY_STATUSES.find(s => s.value === rawval)?.label;
}