import React, { useState, useEffect } from 'react';
import { API, graphqlOperation, Storage } from "aws-amplify";

import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { propertyTypeActions } from '../../redux/property_type';
import { ThunkDispatch } from '@reduxjs/toolkit';

import * as mutations from '../../graphql/mutations';
import { toast } from "react-toastify";

import PropertyForm from '../components/PropertyForm';
import CircularLoader from '../../common/components/CircularLoader';

import { Link, useNavigate } from 'react-router-dom';

import { SerializedProperty, PayloadProperty } from '../../redux/property';




const initialProperty = {
  pid: '',
  name: '',
  price: '',
  address: '',
  state: 'WA',
  primaryImageUrl: '' as any,
  imageUrl: [],
  brochureUrl: [],
  description: '',
  propertyStatus: 'REGISTERED',
  propertyPropertyTypeId: '',
  propertyAddedById: '',
  listAvailability: 'PENDING',
  bedrooms: '',
  bathrooms: '',
  carspaces: '',
  rentalIncome: '',
  minimumRentalYield: '',
  contractType: 'ONE_PART',
  coordinateX: '',
  coordinateY: ''
}


function PropertyAdd () {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [newProperty, setNewProperty] = useState(initialProperty);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    propertyTypeList,
    currentUser
  } = useSelector((state: RootState) => {
    const { propertyTypeList } = state.propertyType;
    const {currentUser} = state.user;
    return {
      propertyTypeList,
      currentUser
    };
  })

  useEffect(() => {
    //on load, check if selector has property type data
    if(propertyTypeList.length === 0) {
      getPropertyTypeList();
    }
  }, []);

  const getPropertyTypeList = async () => {
    setLoading(true);
    await dispatch(propertyTypeActions.fetchPropertyTypeList());
    setLoading(false);
  }

  //WIP Factor
  const onChangeFormField = (key: string, val: any) => {
    setNewProperty({
      ...newProperty,
      [key]: val
    });
  }

  //WIP Factor
  const onClearFiles = (e:any, key: string) => {
    e.preventDefault();
    setNewProperty({
      ...newProperty,
      [key]: []
    });
  }

  //WIP Factor
  const onDeleteSpecificFile = (e: any, key: string, index: number) => {
    e.preventDefault();
    let tempFileArr = newProperty[key as keyof SerializedProperty] as any[];
    tempFileArr.splice(index, 1);
    onChangeFormField(key, tempFileArr);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createProperty();
  }

  //WIP Factor this out
  const createFileLabel = (file: any) => {
    if(file instanceof File) {
      return file.name;
    } else if (file instanceof String || typeof file === "string") {
      return file;
    } else {
      return '';
    }
  }

  const uploadFiles = async () => {
    try {
      const newImages = newProperty.imageUrl.filter((img: any) => img instanceof File);
      const newPdfs = newProperty.brochureUrl.filter((pdf: any) => pdf instanceof File);
      const newPrimaryImage = newProperty.primaryImageUrl instanceof File ? [newProperty.primaryImageUrl] : [];
      const toUpload = [...newImages, ...newPdfs, ...newPrimaryImage] as File[];
      await Promise.all(toUpload.map((file: any) => Storage.put(file.name, file).then(async (result) => {
        console.log(result); //{key: 'myfilename.png'} <-- save this to the db!
      })));
    } catch (e) {
      console.log(e);
      toast.error('Error uploading');
      throw "Upload Error";
    }
  }
  
  //WIP Api calls should be in reducer too
  const createProperty = async () => {
    setLoading(true);
    try {
      console.log(newProperty);

      uploadFiles();
      let payload: PayloadProperty = {
        pid: newProperty.pid,
        name: newProperty.name,
        price: parseFloat(newProperty.price),
        address: newProperty.address,
        state: newProperty.state,
        primaryImageUrl: createFileLabel(newProperty.primaryImageUrl),
        imageUrl: newProperty.imageUrl.map((img) => {
          return createFileLabel(img);
        }),
        brochureUrl: newProperty.brochureUrl.map((pdf) => {
          return createFileLabel(pdf);
        }),
        description: newProperty.description,
        propertyStatus: newProperty.propertyStatus,
        propertyPropertyTypeId: newProperty.propertyPropertyTypeId,
        propertyAddedById: currentUser!.id,
        listAvailability: newProperty.listAvailability,
        bedrooms: parseInt(newProperty.bedrooms),
        bathrooms: parseInt(newProperty.bathrooms),
        carspaces: parseInt((newProperty.carspaces)),
        rentalIncome: parseFloat(newProperty.rentalIncome),
        minimumRentalYield: parseFloat(newProperty.minimumRentalYield),
        contractType: newProperty.contractType,
        coordinateX: parseFloat(newProperty.coordinateX),
        coordinateY: parseFloat(newProperty.coordinateY)
      }

      if(!validateProperty(payload)) throw "Field Error";
      
      await API.graphql(graphqlOperation(mutations.createProperty, {input: payload}));
      toast.success("Added new Property");
      setLoading(false);
      setNewProperty(initialProperty);
    } catch (e) {
      console.log(e);
      toast.error("Error adding Property. Please check fields.");
    } finally {
      setLoading(false);
    }
  }

  //WIP reuse refactor this
  const validateProperty = (payload: PayloadProperty) => {
    let flag = true;
    Object.keys(payload).forEach((k, i) => {
      if(flag = true) {
        if ((payload[k] instanceof String || typeof payload[k] === 'string') && !payload[k]) {
          flag = false;
        } else if (Number.isNaN(payload[k])) {
          flag = false;
        } else {
          flag = true;
        }
      }
    });
    return flag;
  }

  if (loading) {
    return (
      <CircularLoader />
    );
  }

  return (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/properties'} className="block italic font-light">
            {"<< back to list"}
          </Link>
          Add Property
        </div>
        <PropertyForm 
          {...{propertyTypeList, onChangeFormField, onClearFiles, onDeleteSpecificFile}}
          property={newProperty}
        />
          <button onClick={handleSubmit} 
            className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300
             hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-4 mr-4"
             disabled={loading}
             >
            SAVE PROPERTY
          </button>
        </div>
    </div>
  );
}


export default PropertyAdd;