import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { createFileLink } from "../../../helpers/api";

function PropertyCarousel({images}: any) {
  
  const slideItems = (images.length > 3) ? 3 : 1;

  const settings = {
    className: "center",
    // dotsClass: "carouselDots",
    centerMode: true,
    accessibility: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: slideItems,
    speed: 500,
    dots: true,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          arrows: false,
        }
      }
    ]
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalSrc, setModalSrc] = useState('');
  // const modalRef = useRef<HTMLDivElement>(null);

  const showModal = (src: string) => {
    setModalVisible(true);
    setModalSrc(src);
  };

  const closeModal = () => {
    setModalVisible(false);
  };


  return (
    <div className="bg-[#F1F4FD] h-auto w-full overflow-hidden">
      <section className="py-8 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">

        {/* if Property has image logic */}
        { (images.length > 0) ?
        <Slider { ...settings }>
          { 
            images.map((item: string, i: number) => (
              <div key={i}>
                <div className="bg-white rounded-lg overflow-hidden mx-4 text-xl leading-10">
                  <img 
                    className="h-72 lg:h-72 w-full object-cover" 
                    src={ createFileLink(item) } 
                    alt={ item }
                    onClick={() =>
                      showModal( createFileLink(item) )
                    }
                  />
                </div>
              </div>
            ))
          }
        </Slider> : <div><span className="text-slate-500 uppercase">No image available</span></div> }
      </section> 

      {/* Image Modal */}
      { modalVisible && (
        <div 
          className="fixed top-0 left-0 z-50 w-screen h-screen bg-black/70 flex justify-center items-center"
          onClick={closeModal}
          >
          {/* Close button */}
          <button
            className="fixed z-50 top-6 right-8 text-white text-5xl font-bold bg-transparent border-none"
            onClick={closeModal}
          >
            &times;
          </button>

          {/* A big image will be displayed here */}
          <img className="max-w-[1024px] max-h-[640px] object-cover" src={modalSrc} alt={modalSrc} />
        </div>
      )}
    </div>
  );
}

export default PropertyCarousel