import { useState } from "react";

import { API, graphqlOperation, Storage } from "aws-amplify";

import * as mutations from '../../graphql/mutations';

import ArticleForm from '../components/PropertyForm';

import { toast } from "react-toastify";
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { articleActions } from "../../redux/article";
import { RootState, AppDispatch } from '../../store';
import { unionBy } from "lodash-es";
import TagForm from "../components/TagForm";

function TagEdit() {
  const dispatch = useDispatch();
  const {tId} = useParams();

  const tagToEdit = useSelector((state: RootState) => state.article.tagList.find((t: any) => t.id === tId));

  const [tag, setTag] = useState<any>(tagToEdit);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFormField = (key: string, val: any) => {
    setTag({
      ...tag,
      [key]: val
    });
  }

  const updateTag = async () => {
    setLoading(true);
    try {
      let payload = {
        id: tag.id,
        name: tag.name,
        urlSlug: tag.urlSlug
      };

      await API.graphql(graphqlOperation(mutations.updateArticleTag, {input: payload}));
      dispatch(articleActions.updateTagById(tag));
      toast.success("Successfully edited tag.");
      
    } catch (e) {
      console.log(e);
      toast.error("Error updating tag, please check fields.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen h-full w-full md:p-8 py-14 px-3">
      <div className="flex flex-col">
        <div className="text-left font-bold">
          <Link to={'/admin/tags'} className="block italic font-light">
            {"<< back to list"}
          </Link>
        </div>
        <TagForm tag={tag} onChangeFormField={onChangeFormField} />

        <button onClick={updateTag} className="self-center shadow font-bold py-2 px-4 rounded-sm duration-300 hover:rounded-lg hover:duration-300 hover:bg-id-blue hover:text-id-grey2 bg-blue-200 w-40 my-2 mr-4">
          SAVE TAG
        </button>
      </div>
    </div>
  );
}

export default TagEdit;