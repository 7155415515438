import fam1 from '../../../static/fam1.jpg';
import StockImage from '../../../static/NewAssets/Stock Image 2.png'
import SlidingToggleButton from './SlidingToggleButton';
import VisLangBullets from './VisLangBullets';

function CardOne({scrollPercentage, scrollToContact}: any) {
  return (
    <div className={"w-full h-[104vh] -mt-5 my-0 bg-cover opacity-1 rounded-t-3xl z-10 sticky -top-12 snap-start snap-always"}>
      <div className="relative h-[104vh] bottom-0 flex flex-col sm:py-48 py-10 sm:px-20 px-5 bg-cover bg-no-repeat bg-top" style={{backgroundImage: `linear-gradient(to left top, #786FB033, #332B7844), url("${StockImage}")`}}>
        <h3 className="sm:text-4xl text-xl font-robotoslabthin text-white">Secure your financial future</h3>
        <h1 className="sm:text-6xl text-2xl font-robotoslabbold text-white mt-5">Investdoor. The next step<br/> in wealth creation.</h1>
        <p className="text-white sm:text-2xl text-xl font-museosans md:w-1/3 mt-8 pb-20">
          InvestDoor is an Australian-based real estate investment 
          consulting group providing education, guidance and knowledge to their clients.
        </p>
        <SlidingToggleButton buttonText={'Contact Us'} func={scrollToContact}/>
        
      </div>
      <VisLangBullets side="right" bottomOffset={"bottom-80"} width={"w-1/5"} animVisible={scrollPercentage > 0.260} />
    </div>
  )
}

export default CardOne;