import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql-custom/queries-custom';  
import {reject, unionBy} from 'lodash-es';

const PAGE_LIMIT = 100;
const SEARCH_PAGE_LIMIT = 200;

const fetchArticleList = createAsyncThunk(
  'articles/list',
  async(_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql(graphqlOperation(queries.listArticles, {limit: PAGE_LIMIT}));
      //@ts-ignore 
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchArticleListUser = createAsyncThunk(
  'articles/list/user',
  async(_, {rejectWithValue, getState}) => {
    try {
      // let raw_payload = await API.graphql(graphqlOperation(queries.listArticles, 
        
      // ));
      let raw_payload = await API.graphql({
        query: customQueries.listArticlesCustom,
        variables: {limit: PAGE_LIMIT,
          filter: 
          {
            displayDate : { le: new Date().toISOString() },
            archived : { ne: true }
          }
        },
        authMode: 'API_KEY'
      });
      console.log(raw_payload);
      //@ts-ignore 
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchArticleById = createAsyncThunk(
  'articles/id',
  async(_: any, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql({
        query: customQueries.getArticleCustom,
        variables: {id: _.id},
        authMode: 'API_KEY'
      });
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log('error in fetchArticleById');
      console.log(e);
      return rejectWithValue(e);
    }
  }
)

const fetchArticleTagList = createAsyncThunk(
  'articles/tags/list',
  async(_, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql({
        query: queries.listArticleTags,
        variables: {
          limit: PAGE_LIMIT
        },
        authMode: 'API_KEY'
      });
      //@ts-ignore 
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchTagsLinkedToArticle = createAsyncThunk(
  'articles/related-tags/list',
  async(_: any, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql({
        query: customQueries.listArticleArticleTagsCustom,
        variables: {
          limit: PAGE_LIMIT,
          filter: 
          {
            articleId: {eq: _.articleId}
          }
        },
        authMode: 'API_KEY'
      });
      
      return {
        //@ts-ignore
        articleTags: raw_payload.data.listArticleArticleTags.items.map((item) => item.articleTag),
        //@ts-ignore
        articleTagConnections: raw_payload.data.listArticleArticleTags.items
      };
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchArticleConnectionsForDeletion = createAsyncThunk(
  'articles/connections/for-deletion/list/tags',
  async(_: any, {rejectWithValue, getState}) => {
    try {
      let raw_payload = await API.graphql({
        query: customQueries.listArticleArticleTagsCustom,
        variables: {
          limit: PAGE_LIMIT,
          filter: 
          {
            articleId: {eq: _.articleId}
          }
        },
        authMode: 'API_KEY'
      });
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchArticlesWithTag = createAsyncThunk(
  'articles/filtered-by-tag/list',
  async(_: any, {rejectWithValue, getState}) => {
    try {
      // let raw_payload = await API.graphql(graphqlOperation(queries.listArticleArticleTags, 
      //   {filter: {articleTagId: {eq: _.articleTagId}}, limit: PAGE_LIMIT}));
      let raw_payload = await API.graphql({
        query: customQueries.listArticleArticleTagsCustom,
        variables: {
          limit: PAGE_LIMIT,
          filter: 
          {
            articleTagId: {eq: _.articleTagId},
          }
        },
        authMode: 'API_KEY'
      });
      //@ts-ignore
      return raw_payload.data.listArticleArticleTags.items
        .map((item: any) => item.article)
        .filter((item: any) => item.displayDate <= new Date().toISOString());
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

const fetchTagConnectionsForDeletion = createAsyncThunk(
  'articles/connections/for-deletion/list',
  async(_: any, {rejectWithValue, getState}) => {
    try {
      // let raw_payload = await API.graphql(graphqlOperation(queries.listArticleArticleTags, 
      //   {filter: {articleTagId: {eq: _.articleTagId}}, limit: PAGE_LIMIT}));
      let raw_payload = await API.graphql({
        query: customQueries.listArticleArticleTagsCustom,
        variables: {
          limit: PAGE_LIMIT,
          filter: 
          {
            articleTagId: {eq: _.articleTagId},
          }
        },
        authMode: 'API_KEY'
      });
      //@ts-ignore
      return raw_payload.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue(e);
    }
  }
);

interface ArticleState {
  articleList: any[];
  userArticleList: any[];
  searchedArticleList: any[];
  adminSearchedArticleList: any[];
  tagList: any[];
  articleNextToken: String | null;
  userArticleNextToken: String | null;
  tagNextToken: String | null;
  searchedArticleNextToken?: String | null;
  adminSearchedArticleNextToken?: String | null;
  tagSearchNextToken: String | null;
  currentArticleTags: any[];
  currentArticleTagConnections: any[];
  currentTagArticles: any[];
  tagConnectionsForDeletion: any[];
  articleConnectionsForDeletion: any[];
  currentNumberOfArticles: number;
  currentSelectedTagId: string;
  prevSelectedTagId: string;
} 

export const articleSlice = createSlice({
  name: 'article',
  initialState: {
    articleList: [],
    userArticleList: [],
    searchedArticleList: [],
    adminSearchedArticleList: [],
    tagList: [],
    articleNextToken: null,
    userArticleNextToken: null,
    tagNextToken: null,
    searchedArticleNextToken: null,
    adminSearchedArticleNextToken: null,
    tagSearchNextToken: null,
    currentArticleTags: [],
    currentArticleTagConnections: [],
    currentTagArticles: [],
    tagConnectionsForDeletion: [],
    articleConnectionsForDeletion: [],
    currentNumberOfArticles: 4,
    currentSelectedTagId: 'all',
    prevSelectedTagId: 'all'
  } as ArticleState, 
  reducers: {
    setArticleList: (state, action) => {
      state.articleList = action.payload;
    },
    setTagList: (state, action) => {
      state.tagList = action.payload;
    },
    updateArticleById: (state, action) => {
      const idx = state.articleList.findIndex((a) => a.id === action.payload.id);
      state.articleList[idx] = {...state.articleList[idx], ...action.payload};
    },
    updateTagById: (state, action) => {
      const idx = state.tagList.findIndex((t) => t.id === action.payload.id);
      state.tagList[idx] = action.payload;
    },
    setCurrentNumberOfArticles: (state, action) => {
      state.currentNumberOfArticles = action.payload;
    },
    setCurrentSelectedTagId: (state, action) => {
      state.prevSelectedTagId = state.currentSelectedTagId;
      state.currentSelectedTagId = action.payload;
    },
    clearTagConnectionsForDeletion: (state) => {
      state.tagConnectionsForDeletion = [];
    },
    clearArticleConnectionsForDeletion: (state) => {
      state.articleConnectionsForDeletion = [];
    }
  },
  extraReducers: (builder) => {
    //TODO add cases for pending and rejected
    builder.addCase(fetchArticleList.fulfilled, (state, action) => {
      state.articleList = action.payload.listArticles.items;
      state.articleNextToken = action.payload.listArticles.nextToken;
    });

    builder.addCase(fetchArticleListUser.fulfilled, (state, action) => {
      
      state.userArticleList = action.payload.listArticles.items.slice()
        //@ts-ignore
        .sort((a: any,b: any) => new Date(b.displayDate) - new Date(a.displayDate));
      state.userArticleNextToken = action.payload.listArticles.nextToken;
    });

    builder.addCase(fetchArticleTagList.fulfilled, (state, action) => {
      //state.tagList = unionBy(state.tagList, action.payload.listArticleTags.items, 'id');
      state.tagList = action.payload.listArticleTags.items;
      state.tagNextToken = action.payload.listArticleTags.nextToken;
    });

    builder.addCase(fetchTagsLinkedToArticle.fulfilled, (state, action) => {
      state.currentArticleTags = action.payload.articleTags;
      state.currentArticleTagConnections = action.payload.articleTagConnections;
    });

    builder.addCase(fetchArticlesWithTag.fulfilled, (state, action) => {
      state.userArticleList = action.payload.slice()
        //@ts-ignore
        .sort((a: any,b: any) => new Date(b.displayDate) - new Date(a.displayDate));;
    });

    builder.addCase(fetchArticleById.fulfilled, (state, action) => {
      state.userArticleList = unionBy(state.userArticleList, [action.payload.getArticle], 'id');
    });

    builder.addCase(fetchTagConnectionsForDeletion.fulfilled, (state, action) => {
      state.tagConnectionsForDeletion = action.payload.listArticleArticleTags.items;
    });

    builder.addCase(fetchArticleConnectionsForDeletion.fulfilled, (state, action) => {
      state.articleConnectionsForDeletion = action.payload.listArticleArticleTags.items;
    });
  }
});

export const articleActions = {
  fetchArticleList,
  fetchArticleTagList,
  fetchTagsLinkedToArticle,
  fetchArticlesWithTag,
  fetchArticleListUser,
  fetchArticleById,
  fetchTagConnectionsForDeletion,
  fetchArticleConnectionsForDeletion,
  ...articleSlice.actions
};

export default articleSlice.reducer;

